<template>
  <div style="height: 420px !important">
    <canvas ref="graph" id="myChart" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";

export default {
  name: "WoAnalysis",
  props: ["session", "sport", "thresholds", "x1marker", "x2marker"],

  data: function () {
    return { chartdata: [], elapsed_time: [] };
  },

  methods: {
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm");
    },
    handle(point, event) {
      const item = event[0];
      if (item) {
        this.$emit("hover-analysis-chart", {
          index: item.index,
          time: this.chartdata.labels[item.index],
          power: this.chartdata.datasets[0].data[item.index],
          cadence: this.chartdata.datasets[1].data[item.index],
          hr: this.chartdata.datasets[2].data[item.index],
          speed: this.chartdata.datasets[3].data[item.index],
          elapsed_time: this.elapsed_time[item.index],
        });
      }
    },
  },

  mounted: function () {
    let ctx = this.$refs.graph.getContext("2d");

    let labels = [];
    let data_power = [];
    let data_hr = [];
    let data_speed = [];
    let data_cadence = [];
    let data_altitude = [];
    let data_distance = [];

    const _self = this;
    const ftp = this.thresholds[this.thresholds.length - 1].ftp;
    const runThreshold = this.thresholds[this.thresholds.length - 1].threshold_run;
    const maxHr = this.thresholds[this.thresholds.length - 1].maxHr;
    const lit = 0.75;
    const hit = 1.05;

    const hrZone = (ctx) => {
      if (ctx.p0.parsed.y > 0.85 * maxHr) {
        return "#920606";
      }
    };

    const skipped = (ctx, value) => (ctx.p0.skip || ctx.p1.skip || ctx.p1.parsed.y == 0 ? value : undefined);

    const down = (ctx) => {
      if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= ftp * lit) {
        return "rgb(50, 170, 59)";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 > ftp * hit) {
        return "red";
      } else {
        return "orange";
      }
    };

    const run_threshold = (ctx) => {
      if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 > runThreshold * 1.2) {
        return "rgb(50, 170, 59)";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 < runThreshold * 0.96) {
        return "red";
      } else {
        return "orange";
      }
    };

    const records = this.session.laps.flatMap((x) => x.records);
    const length = records.length;

    records.forEach(function (record, index) {
      // WENN 500 geändert wird auch im Workout Analysis CHart ändern

      if (record.enhanced_speed !== undefined) {
        record.speed = record.enhanced_speed;
      }

      if (index % Math.max(1, Math.round(length / 750)) == 0 && index > 1 && records[index + 1]) {
        /* TODO: Werte ggfs. glätten?
        record.speed =
          (records[index - 1].speed +
            records[index].speed +
            records[index + 1].speed) /
          3;*/

        labels.push(_self.formattedTime(record.timer_time) + "h");

        if (record.enhanced_power !== undefined) {
          record.power = record.enhanced_power;
        }
        if (record.power != undefined) {
          data_power.push(record.power || 0);
        }
        if (record.heart_rate != undefined) {
          data_hr.push(Math.round(record.heart_rate) || 0);
        }

        if (record.distance) {
          data_distance.push(Math.round(record.distance * 1000));
        }

        if (record.cadence != undefined) {
          data_cadence.push(Math.round(record.cadence || 0));
        }

        if (record.enhanced_altitude !== undefined) {
          record.altitude = record.enhanced_altitude;
        }
        if (record.speed != undefined) {
          if (_self.sport == "Laufen") {
            // grade adjustment
            // das sind Werte fuer Laufen - was gilt beim Radfahren?
            // erstmal fuer beides so verwenden
            const UPHILL_ADJUSTMENT_PER_PERCENT = 0.033;
            const DOWNHILL_ADJUSTMENT_PER_PERCENT = 0.017;

            if (record.distance && records[index - 1] && record.altitude) {
              const distance_diff = record.distance - records[index - 1].distance;
              const rise = record.altitude - records[index - 1].altitude;
              if (Math.abs(distance_diff) > 0.025) {
                // todo:0.005
                const slope = Math.min((rise / (distance_diff || 1)) * 100, 12);
                let adjusted_speed = record.speed;
                if (slope < 0) {
                  adjusted_speed = adjusted_speed + adjusted_speed * (DOWNHILL_ADJUSTMENT_PER_PERCENT * slope);
                } else if (slope > 0) {
                  adjusted_speed = adjusted_speed + adjusted_speed * (UPHILL_ADJUSTMENT_PER_PERCENT * slope);
                }

                record.speed = adjusted_speed;
              }
            }

            if (record.speed < 7) {
              // TODO: anders lösen?
              record.speed = 0;
            }
          }

          if (_self.sport != "Laufen") {
            if (data_speed[data_speed.length - 2]) {
              data_speed.push(
                Math.round(
                  ((record.speed + data_speed[data_speed.length - 1] + data_speed[data_speed.length - 2]) / 3) * 10
                ) / 10
              );
            } else if (data_speed[data_speed.length - 1]) {
              data_speed.push((Math.round((record.speed + data_speed[data_speed.length - 1]) / 2) * 10) / 10);
            } else {
              data_speed.push(Math.round(record.speed * 10) / 10);
            }
          } else if (_self.sport == "Laufen") {
            data_speed.push(Math.round(3600 / record.speed));
          }
        }
        if (record.altitude != undefined || record.enhanced_altitude != undefined) {
          data_altitude.push(Math.round((record.altitude || record.enhanced_altitude) * 1000));
        }

        _self.elapsed_time.push(record.elapsed_time);
      }
    });

    const data = {
      labels: labels,
      datasets: [],
    };

    data.datasets.push({
      yAxisID: "y1",

      label: "Leistung",
      data: data_power,
      borderColor: "green",
      backgroundColor: "green",
      fill: false,

      segment: {
        borderColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.45)") || down(ctx, "rgb(192,75,75)"),
        backgroundColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.45)") || down(ctx, "rgb(192,75,75)"),
        borderDash: (ctx) => skipped(ctx, [1, 1]),
      },
      borderWidth: 1,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
    });
    data.datasets.push({
      yAxisID: "y4",
      beginAtZero: false,
      fill: "false",

      label: "Geschwindigkeit",
      data: data_speed,
      borderColor: "rgb(50, 170, 59)",
      backgroundColor: "rgb(50, 170, 59)",
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
      borderWidth: 1,
    });
    if (this.sport == "Laufen" && data.datasets.length > 0) {
      data.datasets[data.datasets.length - 1].segment = {
        borderColor: (ctx) => skipped(ctx, "rgba(0,0,0,1)") || run_threshold(ctx, "rgb(192,75,75)"),
        backgroundColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.45)") || run_threshold(ctx, "rgb(192,75,75)"),
        borderDash: (ctx) => skipped(ctx, [1, 1]),
      };
    }

    if (this.sport == "Radfahren") {
      data.datasets[data.datasets.length - 1].backgroundColor = "#222";
      data.datasets[data.datasets.length - 1].borderColor = "#222";
    }

    data.datasets.push({
      label: "HR",
      fill: false,
      data: data_hr,
      borderColor: "#f25454",
      backgroundColor: "#f25454",
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
      borderWidth: 1,
      yAxisID: "y3",
      beginAtZero: false,
    });

    data.datasets[data.datasets.length - 1].segment = {
      borderColor: (ctx) => hrZone(ctx, "#f25454"),
      backgroundColor: (ctx) => hrZone(ctx, "#f25454"),
    };

    data.datasets.push({
      yAxisID: "y2",
      fill: false,
      label: "Kadenz",
      data: data_cadence,
      borderColor: "rgb(70, 70, 200)",
      backgroundColor: "rgb(70, 70, 200)",
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
      borderWidth: 1,
      beginAtZero: false,
    });

    data.datasets.push({
      yAxisID: "y5",
      fill: false,
      beginAtZero: false,

      label: "Höhe",
      data: data_altitude,
      borderColor: "rgba(100,100,100,1)",
      backgroundColor: "rgba(100,100,100,0.8)",
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
      borderWidth: 1,
    });

    data.datasets.push({
      yAxisID: "y6",
      fill: false,
      beginAtZero: false,
      hidden: true,
      label: "Distanz",
      data: data_distance,
      borderColor: "rgba(100,100,100,1)",
      backgroundColor: "rgba(100,100,100,0.8)",
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "black",
      borderWidth: 1,
    });

    const zoom = function (e) {
      const index1 = e.chart.scales.x.ticks[0].value;
      const index2 = e.chart.scales.x.ticks[e.chart.scales.x.ticks.length - 1].value;

      _self.$emit("click-analysis-chart", {
        index: index1,
        time: _self.chartdata.labels[index1],
        power: _self.chartdata.datasets[0].data[index1],

        cadence: _self.chartdata.datasets[1].data[index1],

        hr: _self.chartdata.datasets[2].data[index1],
        speed: _self.chartdata.datasets[3].data[index1],

        elapsed_time: _self.elapsed_time[index1],
      });

      _self.$emit("click-analysis-chart", {
        index: index2,
        time: _self.chartdata.labels[index2],
        power: _self.chartdata.datasets[0].data[index2],

        cadence: _self.chartdata.datasets[1].data[index2],

        hr: _self.chartdata.datasets[2].data[index2],
        speed: _self.chartdata.datasets[3].data[index2],

        elapsed_time: _self.elapsed_time[index2],
      });
    };

    const config = {
      type: "line",
      data: data,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y1;
              let yAxis2 = chart.scales.y2;
              let yAxis3 = chart.scales.y3;
              let yAxis4 = chart.scales.y4;
              let yAxis5 = chart.scales.y5;
              let yAxis6 = chart.scales.y6;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }

              if (yAxis2) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis2.top);
                ctx.lineTo(x, yAxis2.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis3) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis3.top);
                ctx.lineTo(x, yAxis3.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis4) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis4.top);
                ctx.lineTo(x, yAxis4.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }

              if (yAxis5) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis5.top);
                ctx.lineTo(x, yAxis5.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
              if (yAxis6) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis6.top);
                ctx.lineTo(x, yAxis6.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
                ctx.restore();
              }
            }
          },
        },
      ],
      options: {
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        maintainAspectRatio: false,

        responsive: true,

        layout: {
          padding: {
            right: 0,
          },
        },

        plugins: {
          tooltip: {
            backgroundColor: "rgba(0,0,0,1)",
            callbacks: {
              beforeBody: function (context) {
                // Check if we are on the first dataset
                if (context[0].datasetIndex === 0) {
                  // Access the distance from the last dataset
                  const distanceIndex = context[0].chart.data.datasets.length - 1;
                  const distanceDataset = context[0].chart.data.datasets[distanceIndex];
                  const distanceValue = distanceDataset.data[context[0].dataIndex];
                  if (distanceValue) {
                    // Return the distance value to be shown at the top of the tooltip
                    return "Distanz: " + distanceValue + " m";
                  }
                }
                return null;
              },
              label: function (context) {
                let label = "";
                if (context.dataset.yAxisID == "y4" && _self.sport == "Laufen") {
                  label = moment.utc(context.raw * 1000).format("mm:ss") + "m/km";
                } else {
                  label = context.dataset.label + ": " + context.formattedValue;
                }
                return label;
              },
            },
          },
          zoom: {
            zoom: {
              onZoomComplete: zoom,

              drag: {
                enabled: true,
                backgroundColor: "rgba(210,210,210,1)",
              },

              mode: "x",
            },
          },

          legend: {
            display: false,
          },
        },

        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              maxTicksLimit: 8,
            },
          },

          y1: {
            display: true,
            offset: false,

            grid: {
              offset: true,
              color: "#999",
              drawBorder: true,
              display: true,
              borderDash: [2, 4],
            },
            title: {
              display: true,
              text: "Watt",
            },

            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: _self.sport == "Radfahren" ? 3 : 1,
          },

          y4: {
            reverse: _self.sport == "Laufen" ? true : false,

            display: true,
            offset: true,

            ticks: {
              callback: function (value) {
                if (_self.sport == "Laufen") {
                  return moment.utc(value * 1000).format("mm:ss");
                } else {
                  return value;
                }
              },
            },

            grid: {
              offset: true,
              color: "#999",
              drawBorder: true,
              display: true,
              borderDash: [2, 4],
            },
            title: {
              display: true,
              drawBorder: false,
              text: "Geschwindigkeit",
            },
            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 3,
          },
          y3: {
            display: true,
            offset: false,

            grid: {
              offset: true,

              color: "#999",
              drawBorder: true,
              display: true,
              borderDash: [2, 4],
            },
            title: {
              display: true,
              text: "bpm",
            },

            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 3,
          },

          y2: {
            display: true,
            offset: true,

            grid: {
              offset: true,

              color: "#999",
              drawBorder: true,
              display: true,
              borderDash: [2, 4],
            },
            title: {
              display: true,
              text: "",
            },
            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 2,
          },
          y5: {
            title: {
              display: true,

              text: "m",
            },
            offset: true,

            display: true,
            grid: {
              offset: true,
              color: "#999",
              drawBorder: true,
              display: true,
              borderDash: [2, 4],
            },
            type: "linear",
            position: "right",
            stack: "s1",
            stackWeight: 1,
          },
          y6: {
            title: {
              display: false,

              text: "m",
            },
            position: "right",
            display: false,
            type: "linear",
          },
        },
        onClick: function (evt) {
          evt.chart.resetZoom();
        },
        onHover: this.handle,
      },
    };

    this.chartdata = JSON.parse(JSON.stringify(data)); // copy!

    let removeValFrom = [];

    if (data_power.length == 0 || data_power.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y1;
      removeValFrom.push(0);
    }

    if (data_speed.length == 0 || data_speed.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y4;
      removeValFrom.push(1);
    }

    if (data_hr.length == 0 || data_hr.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y3;
      //config.options.plugins.annotation.annotations = [];
      removeValFrom.push(2);
    }

    if (data_cadence.length == 0 || data_cadence.reduce((pv, cv) => pv + cv, 0) == 0) {
      delete config.options.scales.y2;
      removeValFrom.push(3);
    }

    if (data_altitude.length == 0) {
      delete config.options.scales.y5;
      removeValFrom.push(4);
    }

    config.data.datasets = config.data.datasets.filter(function (value, index) {
      return removeValFrom.indexOf(index) == -1;
    });
    Chart.defaults.color = "#222";
    new Chart(ctx, config);
  },
};
</script>
