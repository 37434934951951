var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"15px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 d-none d-lg-block"}),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"filter-button",style:(_vm.active_btn == 'Alle'
            ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
            : ''),on:{"click":function($event){return _vm.showAlle()}}},[_vm._v(" Alle ")])]),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"filter-button",style:(_vm.active_btn == 'Laufen'
            ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
            : ''),on:{"click":function($event){return _vm.showLaufen()}}},[_vm._v(" Laufen ")])]),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"filter-button",style:(_vm.active_btn == 'Radfahren'
            ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
            : ''),on:{"click":function($event){return _vm.showRadfahren()}}},[_vm._v(" Radfahren ")])]),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"filter-button",style:(_vm.active_btn == 'Schwimmen'
            ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
            : ''),on:{"click":function($event){return _vm.showSchwimmen()}}},[_vm._v(" Schwimmen ")])]),_c('div',{staticClass:"col-lg-2"},[_c('button',{staticClass:"filter-button",style:(_vm.active_btn == 'Kraft'
            ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
            : ''),on:{"click":function($event){return _vm.showKraft()}}},[_vm._v(" Athletik ")])])]),_c('div',{staticClass:"row top-spacer-25"},[_c('div',{staticClass:"col-lg-4"}),_c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"slidecontainer"},[_vm._v(" Dauer mindestens "+_vm._s(_vm.formattedTime(_vm.durationMin))+" "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.durationMin),expression:"durationMin",modifiers:{"number":true}}],staticClass:"slider",attrs:{"type":"range","min":_vm.durationMinStart,"max":_vm.durationMaxStart,"id":"durationMin"},domProps:{"value":(_vm.durationMin)},on:{"change":function($event){return _vm.durationChange()},"__r":function($event){_vm.durationMin=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"slidecontainer"},[_vm._v(" Dauer höchstens "+_vm._s(_vm.formattedTime(_vm.durationMax))+" "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.durationMax),expression:"durationMax",modifiers:{"number":true}}],staticClass:"slider",attrs:{"type":"range","min":_vm.durationMinStart,"max":_vm.durationMaxStart,"id":"durationMax"},domProps:{"value":(_vm.durationMax)},on:{"change":function($event){return _vm.durationChange()},"__r":function($event){_vm.durationMax=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}})])])]),_c('div',{staticClass:"row top-spacer-50"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.workoutsListTimeFiltered),function(workout){return _c('div',{key:workout.id,staticClass:"col-lg-2",on:{"click":function($event){return _vm.addWorkoutToCalendar(workout)}}},[_c('div',{staticClass:"workout-box"},[_c('div',{staticClass:"sport"},[_c('span',{staticClass:"sport-inner",style:(workout.durationIs
                    ? 'font-weight:bold;margin-right:-20px'
                    : '')},[_vm._v(_vm._s(workout.name)+" ")])]),_c('div',{staticClass:"row work",staticStyle:{"background":"#cacaca","padding":"7px 5px"}},[_c('div',{staticClass:"col-lg-12"},[(
                    !workout.workoutFile || workout.workoutFile.length == 0
                  )?_c('span',[_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":150,"height":18}},[_c('rect',{staticStyle:{"fill":"rgba(94, 110, 255, 0.97)"},attrs:{"width":100 + '%',"height":50}})])]):_vm._e(),_vm._l((workout.workoutFile),function(value){return _c('span',{key:value.id},[(value.type == 'SteadyState')?_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":_vm.computeWidth(value.Duration, workout.workoutFile),"height":_vm.workoutBuilderHeight(workout.workoutFile)}},[_c('rect',{style:('fill:' + _vm.colorForPower(value.Power)),attrs:{"id":value.id,"width":100 + '%',"height":value.Power / 4}})]):_vm._e(),(value.type == 'Ramp')?_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":_vm.computeWidth(value.Duration, workout.workoutFile),"height":_vm.workoutBuilderHeight(workout.workoutFile)}},_vm._l((20),function(index){return _c('rect',{key:index,style:('fill:' +
                        _vm.colorForPower((value.PowerLow + value.PowerHigh) / 2)),attrs:{"id":value.id + '-' + index,"width":100 / 20 + '%',"x":(100 / 20) * index - 5 + '%',"height":_vm.getRampRectHeight(
                          20,
                          value.PowerLow / 4,
                          value.PowerHigh / 4,
                          index
                        )}})}),0):_vm._e(),(value.type == 'FreeRide')?_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":value.Duration / 15,"height":_vm.workoutBuilderHeight(workout.workoutFile)}},[_c('rect',{staticStyle:{"fill":"rgba(94, 110, 255, 0.5)"},attrs:{"id":value.id,"width":value.Duration / 15,"height":80}})]):_vm._e(),(value.type == 'IntervalsT')?_c('span',_vm._l((_vm.list.slice(0, value.Repeat)),function(index){return _c('span',{key:index},[_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":_vm.computeWidth(value.OnDuration, workout.workoutFile),"height":_vm.workoutBuilderHeight(workout.workoutFile)}},[_c('rect',{style:('fill:' + _vm.colorForPower(value.OnPower)),attrs:{"id":value.id,"width":'100%',"height":value.OnPower / 4}})]),_c('svg',{staticClass:"workoutBuilderBlock",attrs:{"width":_vm.computeWidth(value.OffDuration, workout.workoutFile),"height":_vm.workoutBuilderHeight(workout.workoutFile)}},[_c('rect',{style:('fill:' + _vm.colorForPower(value.OffPower)),attrs:{"id":value.id,"width":'100%',"height":value.OffPower / 4}})])])}),0):_vm._e()])})],2)]),_c('div',{style:(workout.durationIs ? 'font-weight:bold' : '')},[_c('div',{staticStyle:{"margin-top":"5px"}},[(workout.sport == 'Kraft')?_c('span',[_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("@/assets/images/strength.png")}})]):_vm._e(),(workout.sport == 'Laufen')?_c('span',[_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("@/assets/images/run.png")}})]):_vm._e(),(workout.sport == 'Schwimmen')?_c('span',[_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("@/assets/images/swim.png")}})]):_vm._e(),(workout.sport == 'Radfahren')?_c('span',[_c('img',{staticStyle:{"width":"25px"},attrs:{"src":require("@/assets/images/bike.png")}})]):_vm._e()]),(
                  !workout.durationIs &&
                  workout.workoutFile &&
                  workout.workoutFile.length > 0
                )?_c('span',[_vm._v(_vm._s(_vm.formattedTime(_vm.totalWorkoutLength(workout)))+" ")]):_vm._e(),(
                  !workout.durationIs &&
                  workout.workoutFile &&
                  workout.workoutFile.length == 0
                )?_c('span',[_vm._v(_vm._s(_vm.formattedTime( _vm.totalWorkoutLength(workout) || workout.durationShould )))]):_vm._e(),(workout.durationIs)?_c('span',[_vm._v(_vm._s(_vm.formattedTime(workout.durationIs)))]):_vm._e(),(workout.paceAvg && workout.sport == 'Laufen')?_c('span',[_vm._v(" @ "+_vm._s(_vm.$moment() .startOf("day") .seconds(Math.round(workout.paceAvg * 60)) .format("mm:ss"))+"min/km ")]):_vm._e(),(workout.paceAvg && workout.sport == 'Radfahren')?_c('span',[_vm._v(" @ "+_vm._s(Math.round((60 / workout.paceAvg) * 10) / 10)+"km/h ")]):_vm._e(),(workout.heartrateAvg)?_c('span',[_vm._v(_vm._s(workout.heartrateAvg)+"bpm")]):_vm._e(),(workout.distance)?_c('span',[_c('br'),_vm._v(_vm._s(Math.round(workout.distance * 10) / 10)+"km")]):_vm._e(),(workout.powerInWatts)?_c('span',[_vm._v(" | "+_vm._s(Math.round(workout.powerInWatts * 10) / 10)+"w")]):_vm._e(),(
                  workout.sport == 'Radfahren' || workout.sport == 'Laufen'
                )?_c('span',[(
                    workout.sport == 'Radfahren' &&
                    !workout.durationIs &&
                    workout.workoutFile &&
                    workout.workoutFile.length > 0
                  )?_c('span',[_vm._v(" Ø "+_vm._s(_vm.computeAvgWatt(workout.workoutFile))+"w")]):_vm._e(),(
                    workout.sport == 'Laufen' &&
                    !workout.durationIs &&
                    workout.workoutFile &&
                    workout.workoutFile.length > 0
                  )?_c('span',[_vm._v("Ø "+_vm._s(_vm.computeAvgRunSpeed(workout.workoutFile))+"m/km")]):_vm._e()]):_vm._e(),_c('br')])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }