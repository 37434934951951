

<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";
//import moment from 'moment-timezone'
import axios from "axios";

export default {
  props: ["done", "labels", "hrdone", "chart_watts_should"],
  // TODO: SCHWELLE LAUFEN + SCHWIMMEN auf 2. Y ACHSE. Höher ist besser
  data() {
    return {
      chartInterval: undefined,
      workoutDone: this.done,
      chartData: {
        labels: this.labels,
      },
      options: {
        datasets: {
          line: {
            pointRadius: 0, // disable for all `'line'` datasets
          },
        },

        animation: false,

        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },

        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },
        tooltips: {
          enabled: false,
        },

        scales: {
          y: {
            min: 0,
            position: "right",

            display: false,
            grid: {
              display: false,
            },
          },

          yAxes: {
            min: 0,
            stack: "s1",
            offset: true,
            display: true,

            grid: {
              display: true,
            },
            stackWeight: 2,
            position: "left",

            ticks: {
              display: true,
              beginAtZero: false,
            },
          },

          yAxesHr: {
            min: 0,
            max: 205,
            stack: "s1",
            offset: true,
            display: false,

            grid: {
              display: false,
            },
            stackWeight: 2,
            position: "right",

            ticks: {
              font: {
                size: 15,
              },
              display: false,
              beginAtZero: true,
            },
          },

          xAxes: {
            display: false,

            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            drawTime: "beforeDatasetsDraw",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  beforeDestroy() {
    clearInterval(this.chartInterval);
  },

  methods: {
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },
  },

  async mounted() {
    let ctx = this.$refs.graph.getContext("2d");
    console.log("LINE CHART MOUNTED");

    this.chartData.labels = this.labels;

    const thresholds = await (
      await axios.get(this.$host + "/user/thresholds")
    ).data;
    // const maxHr = thresholds[thresholds.length - 1].maxHr;
    const ftp = thresholds[thresholds.length - 1].ftp;

    const lit = 0.75;
    const hit = 1.03;

    const skipped = (ctx, value) =>
      ctx.p0.skip || ctx.p1.skip || ctx.p1.parsed.y == 0 ? value : undefined;
    const down = (ctx) => {
      if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= ftp * lit) {
        return "rgb(0, 180, 0)";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 > ftp * hit) {
        return "rgb(220, 0, 0)";
      } else {
        return "#FFBF00";
      }
    };

    const maxHr = thresholds[thresholds.length - 1].maxHr;

    const skippedhr = (ctx, value) =>
      ctx.p0.skip || ctx.p1.skip || ctx.p1.parsed.y == 0 ? value : undefined;
    const downhr = (ctx) => {
      if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= maxHr * 0.75) {
        return " #FF5733";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= maxHr * 0.82) {
        return " #C70039";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= maxHr * 0.88) {
        return "#900C3F";
      } else {
        return "#581845";
      }
    };
    this.options.plugins = {
      annotation: {
        drawTime: "afterDraw",
        annotations: {
          line1: {
            type: "line",

            borderColor: "black",
            borderWidth: 1,
            xScaleID: "xAxes",
            yScaleID: "yAxes",
            content: "FTP",
            borderDash: [2, 2],
          },
          positionbox: {
            type: "box",
            xMin: 0,
            xMax: 0,

            backgroundColor: "rgba(80, 80, 80, 0.1)",
            borderColor: "rgba(0,0,0,0)",
            borderWidth: 1,
            xScaleID: "xAxes",
            yScaleID: "yAxes",
            content: "position",
          },
          positionline: {
            type: "line",

            borderColor: "#777",
            borderWidth: 1,
            xScaleID: "xAxes",
            yScaleID: "yAxes",
            content: "position",
          },
        },
      },
      legend: {
        display: false,
      },
    };

    this.options.plugins.annotation.annotations.line1.yMin = ftp;
    this.options.plugins.annotation.annotations.line1.yMax = ftp;

    this.options.plugins.annotation.annotations.positionline.xMin =
      this.workoutDone.length - 1;
    this.options.plugins.annotation.annotations.positionline.xMax =
      this.workoutDone.length - 1;
    this.chartData.datasets = [
      {
        data: this.workoutDone,
        fill: false,
        borderColor: "#000",
        backgroundColor: "#000",
        borderWidth: 3,
        yAxisID: "yAxes",
        segment: {
          borderColor: (ctx) =>
            skipped(ctx, "rgb(0,0,0,0.45)") || down(ctx, "rgb(192,75,75)"),
        },
      },
      {
        data: this.hrdone,
        fill: false,
        borderColor: "#FF2442",
        backgroundColor: "#FF2442",
        borderWidth: 1.5,
        yAxisID: "yAxesHr",
        segment: {
          borderColor: (ctx) =>
            skippedhr(ctx, "rgb(0,0,0,0.45)") || downhr(ctx, "rgb(192,75,75)"),
        },
      },
      {
        data: this.chart_watts_should,
        fill: true,
        backgroundColor: "rgba(5,92,157,0.4)",
        yAxisID: "yAxes",

        borderWidth: 1.3,
      },
    ];

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,
    };
    let chart = new Chart(ctx, config);
    const _self = this;
    this.chartInterval = setInterval(function () {
      _self.options.plugins.annotation.annotations.positionline.xMin =
        _self.workoutDone.length - 1;
      _self.options.plugins.annotation.annotations.positionline.xMax =
        _self.workoutDone.length - 1;

      _self.options.plugins.annotation.annotations.positionbox.xMax =
        _self.workoutDone.length - 1;

      chart.update();
    }, 3000);
  },
};
</script>