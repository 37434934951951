<template>
  <div>
    <div v-if="selectedCalendarWorkout != undefined && isMenuOpen" class="overlay" @click="hideOverlay()"></div>
    <div v-if="selectedCalendarWorkout != undefined && isMenuOpen" class="overlay-box" @keydown.esc="hideOverlay()">
      <div style="text-align: right; z-index: 9999999999999999; position: sticky; top: 55px; right: 25px">
        <button
          style="
            min-width: 26px;
            background-color: rgba(0, 0, 0, 0.6);
            border: 0;
            padding: 2px;
            transform: translate3d(0, 0, 0);
          "
          class="closer"
          type="submit"
          @click="hideOverlay()"
        >
          X
        </button>
      </div>
      <div class="row">
        <div class="col-lg-12" style="margin: 0; border-bottom: 0">
          <div class="clearfix" style="padding: 10px">
            <h3 class="top-spacer-25">{{ $t("Einheit bearbeiten") }}</h3>
          </div>

          <div style="padding: 30px; padding-top: 10px">
            <new-workout
              :key="selectedCalendarWorkout._id"
              v-bind:thresholds="thresholds"
              v-bind:workout="selectedCalendarWorkout"
              @closed="hideOverlay"
              @wasSaved="hideOverlay"
            ></new-workout>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="submenu col-xl-2 d-none d-xl-block">
        <div class="width-wrap sticky-top">
          <div class="row top-spacer-25">
            <div class="col-lg-12">
              <a href="/#/training/dashboard">
                <div class="menu-entry active_submenu">
                  {{ $t("Dashboard") }}
                </div></a
              >
            </div>
            <div class="col-lg-12">
              <a href="/#/training/kalender">
                <div class="menu-entry">{{ $t("Kalender") }}</div></a
              >
            </div>

            <div class="col-lg-12">
              <a href="/#/training/liste">
                <div class="menu-entry">{{ $t("Trainingspläne") }}</div>
              </a>
            </div>

            <div class="col-lg-12">
              <a href="/#/training/FragDenCoach">
                <div class="menu-entry">{{ $t("Wissen") }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-10">
        <div class="md-block xs-none top-spacer-15"></div>

        <div class="row">
          <vue-pull-refresh :on-refresh="onRefresh" :config="pullDownConfig" style="overflow-x: hidden">
            <div class="col-lg-12 dashboard-top">
              <div class="row">
                <div class="col-xl-6">
                  <div class="white_wrapper_tighter" v-if="information.length > 0" style="margin-bottom: 25px">
                    <h4>{{ $t("HINWEIS") }}</h4>
                    {{ information
                    }}<!-- Ggfs. wichtige Nachricht vom Server geladen-->
                  </div>

                  <div class="white_wrapper_tighter" v-if="false">
                    <h4>{{ $t("Dein Training") }}</h4>

                    Wie fühlst du dich?

                    <div class="row">
                      <div class="col-3">{{ $t("Müde / schlecht") }}</div>
                      <div class="col-6">
                        <input type="range" class="form-range" min="1" max="10" step="1" id="customRange2" />
                      </div>
                      <div class="col-3">{{ $t("Sehr gut / erholt") }}</div>
                    </div>

                    <div v-if="!loaded" class="spinner-border text-primary top-spacer-15" role="status"></div>
                  </div>

                  <div class="white_wrapper_tighter" v-if="user && !user.video_introduction_done && false">
                    <h4>
                      {{ $t("Video Einführung in den Pushing Limits Club") }}
                    </h4>

                    <iframe
                      class="top-spacer-15"
                      width="100%"
                      height="300px"
                      src="https://www.youtube-nocookie.com/embed/rFhKaSupJ5Q"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>

                    <br /><br />
                    <button @click="video_introduction_done" style="padding: 5px 20px">
                      {{ $t("Nicht mehr anzeigen") }}
                    </button>
                  </div>

                  <div v-if="user && !user.video_introduction_done && false"><br /><br /></div>

                  <div class="white_wrapper_tighter">
                    <h4>{{ $t("Nächste Einheiten") }}</h4>

                    <div v-if="!loaded" class="spinner-border text-primary top-spacer-15" role="status"></div>

                    <div class="col-lg-12 centered" v-if="nextWorkouts.length == 0 && thresholds && loaded">
                      {{
                        $t(
                          `Noch keine Einheiten geplant. Öffne den Kalender und lege dir Einheiten in deinen Plan oder verwende direkt einen unserer Trainingspläne!`
                        )
                      }}
                    </div>
                    <div class="row" v-if="nextWorkouts.length > 0 && thresholds">
                      <div v-for="value in nextWorkouts" :key="value._id" class="col-md-4">
                        <div class="d-md-none top-spacer-10"></div>
                        <div class="row">
                          <div class="col-4 d-md-none">
                            <strong style="display: block" class="top-spacer-15">{{
                              $moment(value.date).format("DD.MM.YYYY")
                            }}</strong>

                            <div
                              style="padding: 10px"
                              v-if="weather_prediction[[$moment(value.date).format('YYYY-MM-DD')]]"
                            >
                              <img
                                width="32px"
                                height="32px"
                                :src="`https://assets.pushinglimits.club/weather/${
                                  weather_prediction[$moment(value.date).format('YYYY-MM-DD')].icon
                                }@2x.png`"
                              />
                              <small> {{ weather_prediction[[$moment(value.date).format("YYYY-MM-DD")]].text }}</small
                              ><br /><small v-if="weather_prediction[$moment(value.date).format('YYYY-MM-DD')].wind"
                                >༄
                                {{
                                  Math.round(weather_prediction[$moment(value.date).format("YYYY-MM-DD")].wind)
                                }}km/h</small
                              >
                            </div>
                            <div
                              class="d-block d-md-none"
                              style="padding: 10px"
                              v-if="!weather_prediction[[$moment(value.date).format('YYYY-MM-DD')]]"
                            >
                              <small>{{ $t("Keine Wetterdaten") }}</small>
                            </div>
                          </div>
                          <div class="col-8 col-md-12">
                            <strong style="display: block" class="top-spacer-25 d-none d-md-block">{{
                              $moment(value.date).format("DD.MM.YYYY")
                            }}</strong>
                            <div class="d-block d-md-none top-spacer-10">
                              <strong>{{ $moment(value.date).locale("de").format("dddd") }}</strong>
                            </div>

                            <div class="d-none d-md-block">
                              {{ $moment(value.date).locale("de").format("dddd") }}
                            </div>

                            <workout-entry
                              class="top-spacer-5"
                              @closed="hideOverlay"
                              @wasSaved="hideOverlay"
                              @wasClicked="showOverlay"
                              v-bind:workoutsOfDay="{ workouts: [value] }"
                              v-bind:thresholds="thresholds"
                              :key="key + 'entry'"
                            >
                            </workout-entry>
                            <div
                              class="d-none d-md-block"
                              style="padding: 10px"
                              v-if="weather_prediction[[$moment(value.date).format('YYYY-MM-DD')]]"
                            >
                              <small>
                                <img
                                  width="32px"
                                  height="32px"
                                  :src="`https://assets.pushinglimits.club/weather/${
                                    weather_prediction[$moment(value.date).format('YYYY-MM-DD')].icon
                                  }@2x.png`"
                                />
                                {{ weather_prediction[[$moment(value.date).format("YYYY-MM-DD")]].text }}</small
                              >
                              <br /><small v-if="weather_prediction[$moment(value.date).format('YYYY-MM-DD')].wind"
                                >༄
                                {{
                                  Math.round(weather_prediction[$moment(value.date).format("YYYY-MM-DD")].wind)
                                }}km/h</small
                              >
                            </div>
                            <div
                              class="d-none d-md-block"
                              style="padding: 10px"
                              v-if="!weather_prediction[[$moment(value.date).format('YYYY-MM-DD')]]"
                            >
                              <small>{{ $t("Keine Wetterdaten") }}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="white_wrapper_tighter top-spacer-25" style="padding: 25px">
                    <div v-if="findNewFtp() > 0 && thresholds && findNewFtp() != thresholds.ftp">
                      <div class="col-lg-12">
                        <div style="border-radius: 10px; margin-bottom: 50px">
                          <h4>{{ $t("Neue FTP erkannt:") }} {{ findNewFtp() }}w</h4>
                          <button v-if="!ftpUpdated" @click="setNewFtp(findNewFtp())" style="padding: 2px 5px">
                            {{ $t("Speichern") }}
                          </button>
                          <br class="d-block d-md-none" />
                          &nbsp;<br class="d-block d-md-none" />
                          <button
                            v-if="!ftpUpdated"
                            @click="unsetNewFtp(findNewFtpWorkout())"
                            style="padding: 2px 5px"
                            class="delete"
                          >
                            {{ $t("Verwerfen") }}
                          </button>
                          <span v-if="ftpUpdated" style="color: green"
                            ><h4>{{ $t("Neue FTP wurde gesetzt!") }}</h4></span
                          >
                        </div>
                      </div>
                    </div>
                    <h4>{{ $t("Letzte Einheiten") }}</h4>

                    <div
                      v-if="previousWorkouts.length == 0 && !thresholds"
                      class="spinner-border text-primary top-spacer-15"
                      role="status"
                    ></div>

                    <div class="col-lg-12 centered" v-if="previousWorkouts.length == 0 && thresholds">
                      {{ $t(`Noch keine Einheiten absolviert. Auf geht's, lass dein Training beginnen!`) }}
                      <br /><br />
                      {{
                        $t(
                          "TIPP: Verbinde dich im Profil mit deiner Sportuhr, damit deine Workouts automatisch synchronisiert werden."
                        )
                      }}
                    </div>
                    <div class="row" v-if="previousWorkouts && previousWorkouts.length >= 0 && thresholds">
                      <div v-for="value in previousWorkouts" :key="value._id" class="col-md-4 top-spacer-10">
                        <div class="row">
                          <div class="col-4 d-md-none">
                            <strong style="display: block" class="top-spacer-15">{{
                              $moment(value.date).format("DD.MM.YYYY")
                            }}</strong>
                          </div>
                          <div class="col-8 col-md-12">
                            <workout-entry
                              class="top-spacer-5"
                              @closed="hideOverlay"
                              @wasSaved="hideOverlay"
                              @wasClicked="showOverlay"
                              v-bind:workoutsOfDay="{ workouts: [value] }"
                              v-bind:thresholds="thresholds"
                              :key="key + 'entry2'"
                            >
                            </workout-entry>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="wettkampfWorkouts && wettkampfWorkouts.length > 0 && thresholds"
                    class="white_wrapper_tighter top-spacer-25"
                    style="padding: 25px"
                  >
                    <h4>{{ $t("Wettkämpfe") }}</h4>

                    <div class="row" v-if="wettkampfWorkouts && wettkampfWorkouts.length >= 0">
                      <div v-for="value in wettkampfWorkouts" :key="value._id" class="col-md-4 top-spacer-10">
                        <div class="row">
                          <div class="col-4 d-md-none">
                            <strong style="display: block" class="top-spacer-15"
                              >{{ $moment(value.date).format("DD.MM.YYYY") }}
                              <br />
                              Noch
                              {{ $moment(value.date).diff($moment().startOf("d"), "d") }}
                              {{ $t("Tage") }}
                            </strong>
                          </div>
                          <div class="col-8 col-md-12">
                            <strong style="display: block" class="d-none d-md-block top-spacer-15"
                              >{{ $moment(value.date).format("DD.MM.YYYY") }}<br />
                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') > 1">
                                Noch
                                {{ $moment(value.date).diff($moment().startOf("d"), "d") }}
                                {{ $t("Tage") }}
                              </div>

                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') == 0">
                                {{ $t("Heute") }}
                              </div>

                              <div v-if="$moment(value.date).diff($moment().startOf('d'), 'd') == 1">
                                {{ $t("Morgen") }}
                              </div>
                            </strong>

                            <workout-entry
                              class="top-spacer-5"
                              @closed="hideOverlay"
                              @wasSaved="hideOverlay"
                              @wasClicked="showOverlay"
                              v-bind:workoutsOfDay="{ workouts: [value] }"
                              v-bind:thresholds="thresholds"
                              :key="key + 'entry_wettkaempfe'"
                            >
                            </workout-entry>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <threshold-run-chart :done="workouts42d" :key="'thresh-run' + workouts42d.length" />
                  </div>
                  <div>
                    <threshold-bike-chart :done="workouts42d" :key="'thresh-bike' + workouts42d.length" />
                  </div>

                  <div class="white_wrapper_tighter top-spacer-25 d-none d-md-block">
                    <h4>{{ $t("Mal was probieren?") }}</h4>
                    <div class="row">
                      <div class="col-6 top-spacer-25" v-for="recipe in recipes" :key="recipe._id">
                        <a v-bind:href="'/#/esszimmer/rezept/' + recipe._id">
                          <h2
                            style="
                              font-size: 20px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 100%;
                            "
                            class="centered"
                          >
                            {{ recipe.ueberschrift }}
                          </h2>
                          <div class="recipe-parent" v-if="recipe.imageIds[0]">
                            <!-- -small hinters bild-->
                            <img
                              class="recipe-img"
                              style="width: 100%; border-radius: 10px"
                              :src="`https://assets.pushinglimits.club/${recipe.imageIds[0]}-small`"
                            />
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-12 centered top-spacer-25">
                        <button @click="getRandomRecipes" style="width: 100%">
                          {{ $t("Andere Rezepte") }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="white_wrapper_tighter top-spacer-25 d-none d-md-block" style="margin-bottom: 20px">
                    <h4>{{ $t("Bestleistungen") }}</h4>

                    <div v-if="workoutAnalysisBest.length == 0">
                      {{ $t("Es liegen noch keine Bestleistungen vor") }}
                    </div>

                    <div v-if="workoutAnalysisBest.length">
                      <div v-for="(session, index) in workoutAnalysisBest" :key="index + 'bp'">
                        <div class="row">
                          <div class="col-12">
                            <br />
                            <strong> {{ $moment(session.workout.date).format("DD.MM.YYYY") }} </strong><br />
                            <a type="submit" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                              <h4>{{ session.workout.name }}</h4></a
                            >
                          </div>

                          <div class="col-lg-12">
                            <div class="row justify-content-center" v-if="session.newBestPowersYear">
                              <div
                                class="col-4 col-md-2"
                                v-for="bestPower in session.newBestPowersYear"
                                :key="bestPower + 'bestPower'"
                                style="float: left; text-align: center"
                              >
                                <div style="padding: 15px">
                                  <i class="fa fa-trophy" aria-hidden="true"></i><br />
                                  {{ $moment.utc(bestPower.split("_")[1] * 1000).format("H:mm:ss") }}
                                  <br />
                                  {{ Math.round(session[bestPower]) }}w
                                </div>
                              </div>
                            </div>

                            <div class="row justify-content-center" v-if="session.newBestRunningTimesYear">
                              <div
                                class="col-4 col-md-2"
                                v-for="bestTime in session.newBestRunningTimesYear"
                                :key="bestTime + 'bestTime'"
                                style="float: left"
                              >
                                <div style="padding: 15px">
                                  <i class="fa fa-trophy" aria-hidden="true"></i><br />
                                  {{ bestTime.split("_")[4] }}m <br />
                                  {{
                                    $moment
                                      .utc(session["best_running_distance_time_" + bestTime.split("_")[4]] * 1000)
                                      .format("H:mm:ss")
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="workoutAnalysisBest.length == 0">
                          <h4 class="top-spacer-50">
                            {{ $t("Es liegen keine Daten vor") }}
                          </h4>
                        </div>
                        <hr />
                      </div>

                      <div class="col-12 top-spacer-25">
                        <a href="/#/rekordleistungen">
                          <button style="width: 100%">
                            {{ $t("Bestleistungen anzeigen") }}
                          </button></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6" v-if="warnPremiumExpiration">
                  <div class="white_wrapper_tighter">
                    <div
                      class="col-12 top-spacer-10"
                      v-if="warnPremiumExpiration"
                      style="text-align: center; color: #fff"
                    >
                      <div
                        class="col-12"
                        style="background-size: 200% auto; padding: 20px; background: #000; border-radius: 10px"
                      >
                        {{
                          $t(
                            "Hinweis: Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du über die App im Profil ein Abo abschließen."
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6" v-if="!warnPremiumExpiration">
                  <div class="top-spacer-25 d-block d-lg-none"></div>

                  <div class="row">
                    <div class="col-lg-12">
                      <div>
                        <div class="white_wrapper_tighter">
                          <h4>{{ $t("Bleib im grünen Bereich!") }}</h4>

                          <!-- TODO: Erweitern auf 2 Wochen in Zukunft -->

                          <stress-chart-form-ramp
                            v-bind:done="workouts"
                            style="height: 200px"
                            :key="key + 'belastung'"
                            v-bind:fromDay="$moment().add(-14, 'd').startOf('day')"
                            v-bind:until="$moment()"
                            v-bind:showCurrentVal="true"
                            v-if="!(workouts42d.length == 0 && !thresholds)"
                          />
                          <br />
                          <br />
                          <br />
                          <div class="d-block d-md-none">
                            <br />
                            <br />
                          </div>
                          <small
                            >{{
                              $t(
                                `Für einen langsamen und stetigen Aufbau solltest du dich hauptsächlich im unteren grünen Bereich bewegen. Benötigt Daten von 6 Wochen.`
                              )
                            }}
                          </small>
                        </div>
                        <div class="top-spacer-25">
                          <div v-if="loaded && (!workouts || workouts.length == 0)">
                            <div class="white_wrapper_tighter">
                              <h4>
                                {{ $t("Keine Daten der letzten zwei Wochen verfügbar") }}
                                :-(
                              </h4>
                              {{
                                $t(
                                  `Ist aber kein Problem - lade einfach Trainingseinheiten hoch, verbinde dich in deinem Profil mit Garmin, Polar oder Wahoo oder fang an zu trainieren!`
                                )
                              }}
                            </div>
                          </div>
                          <stress-chart-fitness
                            v-bind:todayText="'Heute'"
                            v-bind:done="workouts42d"
                            :key="workouts42d.length + 'fitness' + workouts.length"
                            v-bind:fromDay="$moment().add(-28, 'd').startOf('day')"
                            v-bind:until="$moment()"
                            v-if="workouts && workouts.length"
                          />
                        </div>
                      </div>

                      <div class="white_wrapper_tighter top-spacer-25">
                        <!-- foodlog -->
                        <div class="row">
                          <div class="col-12">
                            <h4>{{ $t("Dein Foodlog") }}</h4>
                          </div>

                          <div class="col-4" v-show="foodlogLength">
                            <strong>Verteilung <br class="d-block d-md-none" />7 Tage</strong>

                            <div class="chart-container top-spacer-10">
                              <canvas ref="foodChart7d" width="100%"></canvas>
                            </div>
                          </div>

                          <div class="col-4" v-show="foodlogLength">
                            <strong>Verteilung <br class="d-block d-md-none" />30 Tage</strong>

                            <div class="chart-container top-spacer-10">
                              <canvas ref="foodChart30d" width="100%"></canvas>
                            </div>
                          </div>
                          <div class="col-4" v-show="foodlogLength">
                            <strong>Verteilung <br class="d-block d-md-none" />Jahr</strong>

                            <div class="chart-container top-spacer-10">
                              <canvas ref="foodChart365d" width="100%"></canvas>
                            </div>
                          </div>
                          <div class="col-lg-12" v-if="!foodlogLength">
                            {{ $t("Noch keine Daten im Foodlog") }}<br />
                          </div>
                          <div class="col-lg-12 top-spacer-25">
                            <a href="/#/esszimmer/foodlog">
                              <button>{{ $t("Foodlog ansehen") }}</button>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="white_wrapper_tighter top-spacer-25">
                        <div class="col-lg-12" v-if="!workouts42d || workouts42d.length == 0">
                          Keine Daten für Trainingsdauer, -intensität und -belastung oder Distanzen. Lade zum Start
                          einige Trainingseinheiten hoch und hier erscheinen Auswertungen :-)
                        </div>

                        <workout-analysis-pss-chart
                          class="top-spacer-50"
                          style="height: 180px"
                          v-bind:done="workouts42d"
                          v-bind:from="$moment().add(-42, 'd').startOf('day')"
                          v-bind:until="$moment()"
                          :key="key + 'pss'"
                          v-if="workouts42d && workouts42d.length"
                        />

                        <workout-analysis-intensity-chart
                          class="top-spacer-50"
                          style="height: 180px"
                          v-bind:done="workouts42d"
                          v-bind:from="$moment().add(-42, 'd').startOf('day')"
                          v-bind:until="$moment()"
                          :key="key + 'intensity'"
                          v-if="workouts42d && workouts42d.length"
                        />

                        <workout-analysis-load-chart
                          class="top-spacer-50"
                          style="height: 180px"
                          v-bind:done="workouts42d"
                          v-bind:from="$moment().add(-42, 'd').startOf('day')"
                          v-bind:until="$moment()"
                          :key="key + 'load'"
                          v-if="workouts42d && workouts42d.length"
                        />

                        <workout-analysis-distance-chart
                          class="top-spacer-50"
                          style="height: 180px"
                          v-bind:done="workouts42d"
                          v-bind:from="$moment().add(-42, 'd').startOf('day')"
                          v-bind:until="$moment()"
                          :key="key + 'distance'"
                          v-if="workouts42d && workouts42d.length"
                        />
                      </div>

                      <div class="white_wrapper_tighter top-spacer-25 d-block d-md-none">
                        <h4>{{ $t("Mal was probieren?") }}</h4>
                        <div class="row">
                          <div class="col-12 col-sm-6 top-spacer-25" v-for="recipe in recipes" :key="recipe._id">
                            <a v-bind:href="'/#/esszimmer/rezept/' + recipe._id">
                              <h2
                                style="
                                  font-size: 20px;
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  max-width: 100%;
                                "
                                class="centered"
                              >
                                {{ recipe.ueberschrift }}
                              </h2>
                              <div class="recipe-parent" v-if="recipe.imageIds[0]">
                                <!-- -small hinters bild-->
                                <img
                                  class="recipe-img"
                                  style="width: 100%; border-radius: 10px"
                                  :src="`https://assets.pushinglimits.club/${recipe.imageIds[0]}-small`"
                                />
                              </div>
                            </a>
                          </div>
                          <div class="col-lg-12 centered top-spacer-25">
                            <button @click="getRandomRecipes" style="width: 100%">
                              {{ $t("Andere Rezepte") }}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="white_wrapper_tighter top-spacer-25">
                        {{ $t("Möchtest du deine Workout-Vorlagen anpassen?") }}<br /><br />
                        <a href="/#/training/vorlagen">
                          <button style="width: 100%">
                            {{ $t("Workout-Vorlagen bearbeiten") }}
                          </button></a
                        >
                      </div>

                      <div class="white_wrapper_tighter top-spacer-25 d-block d-md-none" style="margin-bottom: 20px">
                        <h4>{{ $t("Bestleistungen") }}</h4>

                        <div v-if="workoutAnalysisBest.length == 0">
                          {{ $t("Es liegen noch keine Bestleistungen vor") }}
                        </div>

                        <div v-if="workoutAnalysisBest.length">
                          <div v-for="(session, index) in workoutAnalysisBest" :key="index + 'bp'">
                            <div class="row">
                              <div class="col-12">
                                <br />
                                <strong> {{ $moment(session.workout.date).format("DD.MM.YYYY") }} </strong><br />
                                <a type="submit" v-bind:href="'/#/auswertung/workout/details/' + session.workout._id">
                                  <h4>{{ session.workout.name }}</h4></a
                                >
                              </div>

                              <div class="col-lg-12">
                                <div class="row justify-content-center" v-if="session.newBestPowersYear">
                                  <div
                                    class="col-4 col-md-2"
                                    v-for="bestPower in session.newBestPowersYear"
                                    :key="bestPower + 'bestPower'"
                                    style="float: left; text-align: center"
                                  >
                                    <div style="padding: 15px">
                                      <i class="fa fa-trophy" aria-hidden="true"></i><br />
                                      {{ $moment.utc(bestPower.split("_")[1] * 1000).format("H:mm:ss") }}
                                      <br />
                                      {{ Math.round(session[bestPower]) }}w
                                    </div>
                                  </div>
                                </div>

                                <div class="row justify-content-center" v-if="session.newBestRunningTimesYear">
                                  <div
                                    class="col-4 col-md-2"
                                    v-for="bestTime in session.newBestRunningTimesYear"
                                    :key="bestTime + 'bestTime'"
                                    style="float: left"
                                  >
                                    <div style="padding: 15px">
                                      <i class="fa fa-trophy" aria-hidden="true"></i><br />
                                      {{ bestTime.split("_")[4] }}m <br />
                                      {{
                                        $moment
                                          .utc(session["best_running_distance_time_" + bestTime.split("_")[4]] * 1000)
                                          .format("H:mm:ss")
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-if="workoutAnalysisBest.length == 0">
                              <h4 class="top-spacer-50">
                                {{ $t("Es liegen keine Daten vor") }}
                              </h4>
                            </div>
                            <hr />
                          </div>

                          <div class="col-12 top-spacer-25">
                            <a href="/#/rekordleistungen">
                              <button style="width: 100%">
                                {{ $t("Bestleistungen anzeigen") }}
                              </button></a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-pull-refresh>
        </div>

        <div class="row top-spacer-15">
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
    <div class="row top-spacer-100"></div>
  </div>
</template>

<script>
import WorkoutEntry from "@/components/calendar/WorkoutEntryNoHighlight";
import NewWorkout from "@/components/workout/NewWorkout";
import VuePullRefresh from "vue-pull-refresh";
import axios from "axios";
import StressChartFitness from "@/components/chart/StressChartFitness.vue";

import WorkoutAnalysisLoadChart from "@/components/chart/WorkoutAnalysisLoadChart.vue";
import WorkoutAnalysisIntensityChart from "@/components/chart/WorkoutAnalysisIntensityChart.vue";
import WorkoutAnalysisDistanceChart from "@/components/chart/WorkoutAnalysisDistanceChart.vue";
import WorkoutAnalysisPssChart from "@/components/chart/WorkoutAnalysisPssChart.vue";
import StressChartFormRamp from "@/components/chart/StressChartFormRamp.vue";

import ThresholdRunChart from "@/components/chart/ThresholdRunChart.vue";
import ThresholdBikeChart from "@/components/chart/ThresholdBikeChart.vue";

import Chart from "chart.js/auto";

export default {
  metaInfo: {
    title: "Dashboard",
  },
  name: "Dashboard",
  components: {
    VuePullRefresh,
    WorkoutAnalysisLoadChart,
    WorkoutAnalysisIntensityChart,
    WorkoutAnalysisDistanceChart,
    WorkoutAnalysisPssChart,
    NewWorkout,
    StressChartFormRamp,
    WorkoutEntry,
    StressChartFitness,
    ThresholdRunChart,
    ThresholdBikeChart,
  },
  data: function () {
    return {
      wettkampfWorkouts: [],
      weather_prediction: [],

      pullDownConfig: {
        errorLabel: "Es ist ein Fehler aufgetreten",
        startLabel: "Lade...",
        readyLabel: "Neu laden...",
        loadingLabel: "Lade...",
      },

      warnPremiumExpiration: false,
      information: "", // Nachricht vom Server
      workouts: [],
      workouts42d: [],
      key: 0,
      thresholdsChanged: false,
      nextWorkouts: [],
      previousWorkouts: [],
      runThreshold: 0,
      swimThreshold: 0,
      ftp: 0,
      runThresholdStart: 0,
      swimThresholdStart: 0,
      ftpStart: 0,
      thresholds: undefined,
      editThresholdId: "",
      showThresholdDetails: false,
      edit: {},
      trainingplans: [],
      showTrainingplans: false,
      switchPlanTo: {},

      foodlogLength: 0,

      workout_run_count: 0,
      workout_bike_count: 0,
      workout_strength_count: 0,
      workout_activity_count: 0,
      workout_swim_count: 0,
      workout_run_duration: 0,
      workout_bike_duration: 0,
      workout_strength_duration: 0,
      workout_activity_duration: 0,
      workout_swim_duration: 0,
      workout_run_distance: 0,
      workout_bike_distance: 0,
      workout_strength_distance: 0,
      workout_activity_distance: 0,
      workout_swim_distance: 0,

      selectedCalendarWorkout: undefined,
      isMenuOpen: false,
      recipes: [],
      loaded: false,

      ftpUpdated: false,
      workoutAnalysisBest: [],
      user: {},
    };
  },

  watch: {
    $route(newVal) {
      this.isMenuOpen = newVal.query?.isMenuOpen || false;

      if (!this.isMenuOpen) {
        this.setScrollLock(false);
      } else {
        this.setScrollLock(true);
      }
    },
  },

  mounted: async function () {
    const _self = this;

    this.updateData();
    this.getRandomRecipes();

    this.updateWeather();

    axios.get(this.$host + "/user/information").then(function (res) {
      _self.information = res.data.information;
    });

    let user = (await axios.get(this.$host + "/user/me")).data;

    try {
      _self.$moment.tz.setDefault(user.timezone || "Europe/Berlin");
    } catch (err) {
      console.log("Fehler beim setzen der TZ", user.timezone);
    }

    this.user = user;

    if (
      !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
      !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
    ) {
      this.warnPremiumExpiration = true;
    }

    if (!user.introduction_done) {
      this.$router.push({ name: "Introduction" });
    }

    axios
      .get(this.$host + "/workout/best")
      .then(function (res) {
        _self.workoutAnalysisBest = res.data;
      })
      .catch(function (err) {
        console.log("ERR", err);
      });

    const response7d = await axios.get(this.$host + "/food/7d");
    const entries7d = response7d.data;

    const response30d = await axios.get(this.$host + "/food/30d");
    const entries30d = response30d.data;

    const response365d = await axios.get(this.$host + "/food/365d");
    const entries365d = response365d.data;

    this.foodlogLength = entries365d.length;

    // chartjs pie chart for ratings
    let ctx = this.$refs.foodChart7d.getContext("2d");

    const good7d = entries7d.filter((entry) => entry.rating === "Good").length;
    const okay7d = entries7d.filter((entry) => entry.rating === "Okay").length;
    const bad7d = entries7d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good7d, okay7d, bad7d],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // 30 d

    // chartjs pie chart for ratings
    let ctx2 = this.$refs.foodChart30d.getContext("2d");

    const good2 = entries30d.filter((entry) => entry.rating === "Good").length;
    const okay2 = entries30d.filter((entry) => entry.rating === "Okay").length;
    const bad2 = entries30d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx2, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good2, okay2, bad2],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // 1yr
    // chartjs pie chart for ratings
    let ctx3 = this.$refs.foodChart365d.getContext("2d");

    const good3 = entries365d.filter((entry) => entry.rating === "Good").length;
    const okay3 = entries365d.filter((entry) => entry.rating === "Okay").length;
    const bad3 = entries365d.filter((entry) => entry.rating === "Bad").length;

    new Chart(ctx3, {
      type: "pie",
      data: {
        labels: ["Gut", "Okay", "Schlecht"],
        datasets: [
          {
            label: "# of Votes",
            data: [good3, okay3, bad3],
            backgroundColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderColor: ["#4CAF50", "#FFC107", "#F44336"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  },

  methods: {
    onRefresh: function () {
      const _self = this;

      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          _self.updateData();
          _self.getRandomRecipes();

          _self.updateWeather();
          axios
            .get(_self.$host + "/workout/best")
            .then(function (res) {
              _self.workoutAnalysisBest = res.data;
            })
            .catch(function (err) {
              console.log("ERR", err);
              reject();
            });
          resolve();
        }, 1000);
      });
    },

    updateWeather: async function () {
      const _self = this;
      axios.get(_self.$host + "/weather/user_location").then(function (res) {
        _self.weather_prediction = res.data.weather_days;
      });
    },

    video_introduction_done() {
      const _self = this;
      axios
        .post(this.$host + "/user/video_introduction_done")
        .then(function (res) {
          console.log("VIDEO INTRO DONE RES", res);
          _self.user = res.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setScrollLock(lockIt) {
      const el = window.document.body;

      if (lockIt) {
        el.classList.add("locked");
      } else {
        el.classList.remove("locked");
      }
    },
    findNewFtp() {
      const _self = this;
      return Math.max.apply(
        Math,
        this.previousWorkouts.map(function (o) {
          // todo datumseinschränkung testen
          if (_self.$moment(o.date).isAfter(_self.$moment().add(-14, "days"))) {
            return o.newFtp;
          } else {
            return 0;
          }
        })
      );
    },

    findNewFtpWorkout() {
      const _self = this;
      const ftp = Math.max.apply(
        Math,
        this.previousWorkouts.map(function (o) {
          // todo datumseinschränkung testen
          if (_self.$moment(o.date).isAfter(_self.$moment().add(-14, "days"))) {
            return o.newFtp;
          } else {
            return 0;
          }
        })
      );

      return this.previousWorkouts.find(function (o) {
        return o.newFtp == ftp;
      });
    },

    setNewFtp: function (ftp) {
      let req = {
        ftp: ftp,
        threshold_swim: this.thresholds.threshold_swim,
        threshold_run: this.thresholds.threshold_run,

        maxHr: this.thresholds.maxHr,
        restingHr: this.thresholds.restingHr,
        weight: this.thresholds.weight,
      };

      const _self = this;

      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.updateData();
          _self.ftpUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    unsetNewFtp: function (workout) {
      let req = {
        id: workout._id,
      };

      const _self = this;

      axios
        .post(this.$host + "/workout/thresholds/unsetftp", req)
        .then(function () {
          _self.updateData();
          _self.ftpUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    updateData: async function () {
      const _self = this;

      axios.get(this.$host + "/workout/nextworkouts/" + this.$moment().startOf("day").utc()).then(function (res) {
        _self.nextWorkouts = res.data.sort((a, b) => {
          return (
            new Date(_self.$moment(a.date).startOf("d")) - new Date(_self.$moment(b.date).startOf("d")) ||
            a.orderOfDay - b.orderOfDay
          );
        });
      });

      axios
        .get(this.$host + "/workout/competition_workouts/" + this.$moment().startOf("day").utc())
        .then(function (res) {
          _self.wettkampfWorkouts = res.data;
        });

      axios.get(this.$host + "/workout/previous").then(function (res) {
        _self.previousWorkouts = res.data;
      });

      this.loadWorkouts();

      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

      this.loaded = true;
    },

    getRandomRecipes() {
      const _self = this;
      axios
        .get(this.$host + "/rezept/random")
        .then(function (response) {
          _self.recipes = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    showOverlay(id) {
      this.setScrollLock(true);

      this.menuRoute();

      // TODO : If activityFile --> Direkt in Details
      // if(this.workouts.contains(workout).activityFile)
      this.selectedCalendarWorkout = this.nextWorkouts
        .concat(this.previousWorkouts)
        .concat(this.wettkampfWorkouts)
        .find((element) => element._id == id);
    },
    hideOverlay() {
      this.updateData();
      this.selectedCalendarWorkout = undefined;
      this.menuRouteClose();
    },
    menuRoute() {
      this.setScrollLock(true);

      this.isMenuOpen = true;
      this.$router.push({
        name: "Dashboard",
        query: {
          isMenuOpen: true,
        },
      });
    },
    menuRouteClose() {
      this.isMenuOpen = false;

      this.$router.push({
        name: "Dashboard",
        query: {
          isMenuOpen: false,
        },
      });

      this.setScrollLock(false);
    },
    async loadWorkouts() {
      const _self = this;

      let from = this.$moment().add(-14, "d").startOf("day");
      let till = this.$moment().endOf("day");

      axios.get(this.$host + "/workout/" + "from/" + from + "/until/" + till + "/done").then(function (res) {
        _self.workouts = res.data;

        _self.workout_run_count = 0;
        _self.workout_bike_count = 0;
        _self.workout_strength_count = 0;
        _self.workout_swim_count = 0;

        _self.workout_run_duration = 0;
        _self.workout_bike_duration = 0;
        _self.workout_strength_duration = 0;
        _self.workout_swim_duration = 0;

        _self.workout_run_distance = 0;
        _self.workout_bike_distance = 0;
        _self.workout_strength_distance = 0;
        _self.workout_swim_distance = 0;

        _self.workouts.forEach(function (workout) {
          if (workout.sport == "Laufen") {
            _self.workout_run_count++;
            _self.workout_run_duration += workout.durationIs;
            _self.workout_run_distance += workout.distance;
          } else if (workout.sport == "Radfahren") {
            _self.workout_bike_count++;
            _self.workout_bike_duration += workout.durationIs;
            _self.workout_bike_distance += workout.distance;
          } else if (workout.sport == "Kraft") {
            _self.workout_strength_count++;
            _self.workout_strength_duration += workout.durationIs;
          } else if (workout.sport == "Aktivität") {
            _self.workout_activity_count++;
            _self.workout_activity_duration += workout.durationIs;
            _self.workout_activity_distance += workout.distance;
          } else if (workout.sport == "Schwimmen") {
            _self.workout_swim_count++;
            _self.workout_swim_duration += workout.durationIs;
            _self.workout_swim_distance += workout.distance;
          }
        });
      });
      axios
        .get(this.$host + "/workout/" + "from/" + _self.$moment(from).add(-42, "d") + "/until/" + till + "/done")
        .then(function (res) {
          _self.workouts42d = res.data;
        });
    },
  },
};
</script>

<style scoped>
.dashboard-top {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .dashboard-top {
    margin-top: 15px;
  }
}

.threshold {
  padding: 10px;
  border-bottom: 2px solid #fff;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: calc(env(safe-area-inset-top) + 3%);

  bottom: 3%;

  z-index: 99999999;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    margin-left: 0;
  }
}

.library {
  background: #efefef;
  padding: 20px;
  border-radius: 5px;
}
</style>
