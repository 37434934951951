<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper" style="text-align: left">
        <h3 class="top-spacer-50">Häufig gestellte Fragen</h3>

        <h4 class="top-spacer-50">Was bedeutet LIT / MIT / HIT</h4>
        Das Training wird bei uns grob in drei Bereiche aufgeteilt:<br />
        <ul>
          <li><strong>LIT</strong> - Low Intensity Training</li>
          <li><strong>MIT</strong> - Medium Intensity Training</li>
          <li><strong>HIT</strong> - High Intensity Training</li>
        </ul>
        Wenn deine Workouts zu uns synchronisiert werden, berechnen wir soweit möglich automatisch diese
        <strong>Trainingsintensitätsverteilung</strong>. Du kannst die Verteilung jederzeit auch manuell anpassen, indem
        du das Workout im Kalender öffnest. <br /><br />
        <strong
          >Ein Großteil deines Trainings sollte sich im LIT-Bereich abspielen. Viele Sportler machen den Fehler, zu
          intensiv zu trainieren. Achte bei deinem Training darauf, viel Zeit im LIT-Bereich zu verbringen!</strong
        >

        <h4 class="top-spacer-50">Wie kann ich Einheiten verschieben?</h4>
        Am Computer einfach per Drag-und-Drop. In der App klickst du die Einheit einmal an und wählst im ersten Feld
        neben "Verschieben" ein neues Datum aus.

        <h4 class="top-spacer-50">Wie kann ich Wettkämpfe hinzufügen?</h4>
        Wähle am Kalender das "+" und unter dem Punkt Sport den Typ Wettkampf. Nach dem Speichern werden dir die
        nächsten Wettkämpfe im Dashboard angezeigt.

        <h4 class="top-spacer-50">Kann ich mich mit Zwift verbinden?</h4>

        Zwift bietet uns leider nicht die technische Möglichkeit zur Automatisierung - finden wir sehr schade, haben wir
        aber keinen Einfluss drauf. Du kannst aber sowohl unsere Workouts zu Zwift übertragen als auch von Zwift zu uns.
        <br /><br />
        <u>Workouts zu Zwift übertragen:</u><br />
        Einheit im Kalender anklicken und unter den Buttons das Zwift Workout File runterladen. Dann kannst du sie (je
        nach Betriebssystem können die Ordner etwas anders heißen) unter Dokumente/Zwift/Workouts/deine Zwift Id/
        ablegen.
        <br /><br />
        <u>Aktivitäten von Zwift zu uns:</u><br />
        Die .fit-Dateien auf deinem Computer unter /Dokumente/Zwift/Activities per Drag und Drop auf den Kalender ziehen
        oder unter "Profil"->".FIT Datei manuell hochladen" die Dateien aus dem Acitivites Ordner auswählen.<br />
        Alternativ kannst du dich mit Strava verbinden. Strava leitet das Zwift Workout zu uns weiter (Strava begrenzt
        allerdings die Aufrufe, die wir durchführen können).

        <h4 class="top-spacer-50">Meine Zwift Workouts werden nicht über Garmin weitergeleitet - woran liegt das?</h4>
        Leider leitet Garmin seit einiger Zeit keine Workouts von dritten Plattformen mehr weiter. Entweder musst du
        dich mit Strava verbinden oder die Workouts manuell übertragen.

        <h4 class="top-spacer-50">An welchem Tag sollte ich die Trainingspläne starten</h4>
        Die Pläne sind so ausgelegt, dass sie Montags beginnen und Sonntags enden. Sollte dein Zielwettkampf stattdessen
        auf einem Samstag liegen, so startet dein Plan entsprechend an einenem Sonntag.

        <h4 class="top-spacer-50">Kann ich Workouts auf mein Polar Gerät übertragen?</h4>
        Die von Polar bereitgestellten Schnittstellen ermöglichen dies bisher noch nicht. Wir werden die Funktionen
        bereitstellen, wenn Polar es ermöglicht.

        <h4 class="top-spacer-50">Können meine Radraum-Workouts auch zu Garmin übertragen werden?</h4>
        Leider ermöglicht Garmin seit knapp zwei Jahren keinen neuen Anwendungen mehr, durchgeführte Workouts zu Garmin
        Connect zu übertragen. Wenn sich daran etwas ändert, werden wir die Übertragung ermöglichen. Deine geplanten
        Einheiten werden aber zu Garmin und auf deine Geräte synchronisiert.

        <h4 class="top-spacer-50">Kann ich meine Workouts von der Apple Watch übertragen?</h4>
        Bisher noch nicht. Wir planen aber, dies zukünftig zu ermöglichen.

        <h4 class="top-spacer-50">Kann ich Workouts von Strava laden?</h4>
        Wir laden Workouts von Strava, aber können nicht garantieren, dass diese ankommen. Leider haben wir nur ein
        begrenztes Kontingent pro Tag. Wenn wir unser tägliches Limit ausgeschöpft haben, werden keine weiteren Workouts
        mehr übertragen.
        <br />

        <h4 class="top-spacer-50">Wie kann ich meine alten Workouts hochladen?</h4>
        Du kannst .FIT Dateien deiner Workouts am Computer direkt auf den Kalender ziehen. Viele Anbieter bieten einen
        Massenexport deiner Daten an, danach kannst du sie bei uns hochladen. <br />
        <br />

        <a
          style="color: blue; text-decoration: underline"
          target="_blank"
          href="https://support.strava.com/hc/de/articles/216918437-Datenexport-und-Massenexport#Bulk"
          >Zur Strava Anleitung</a
        ><br />
        Nach dem Export kannst du die Aktivitätsdaten (leider nur die .FIT Dateien) bei uns hochladen.
        <br />
        <br />
        <strong>Deine Zwift Workouts</strong> liegen je nach Betriebssystem ungefähr unter /Dokumente/Zwift/Activities.
        Du kannst bis zu 1000 .fit Dateien auf einmal per Drag und Drop auf den Kalender im Club hochladen.

        <h4 class="top-spacer-50">Wie lange ist die Testphase?</h4>
        Du kannst den Club zwei Wochen kostenlos und unverbindlich testen. Dir entstehen erst Kosten, wenn du auf deinem
        Smartphone ein Abo abschließt.

        <h4 class="top-spacer-50">Wie kann ich ein Abo abschließen?</h4>
        Danke, dass du unsere Arbeit unterstützt! Lade dir unsere iOS oder Android App herunter. Unter dem Menüpunkt
        Profil->Mitgliedschaft kannst du ein monatliches Abo abschließen.

        <h4 class="top-spacer-50">Wie kann ich mein Abo kündigen?</h4>
        Du kannst das Abo, wie bei jeder App, unter den Einstellungen auf deinem Smartphone kündigen.

        <h4 class="top-spacer-50">Welche Rollentrainer funktionieren mit dem Radraum?</h4>
        Es sollten mindestens alle Rollentrainer funktionieren, die FTMS oder FEC-over-BLE unterstützen. Ohne
        Gewährleistung sind das u.a.<br /><br />
        <ul>
          <li>Wahoo Kickr Core, Wahoo Kickr (vermutlich ab 2018, ggf. Firmware updaten)</li>
          <li>alle Tacx Smarttrainer (vermutlich ab 2020) <br /></li>
          <li>alle Elite Smarttrainer (vermutlich ab 2020)</li>
          <li>alle Saris Smarttrainer (vermutlich ab 2020)</li>
          <li>...</li>
        </ul>

        Wir empfehlen dir, deinen Rollentrainer in der kostenlosen Testphase auszuprobieren!

        <h4 class="top-spacer-50">Warum benötigt Android meine Geoposition?</h4>
        Bei Android ist die Genehmigung dafür Bluetooth zu verwenden an die Geopositionsgenehmigung gebunden. Darauf
        haben wir keinen Einfluss. Wir fragen diese Daten nicht ab und speichern sie auch nicht!
        <br />

        <h4 class="top-spacer-50">Die Bluetooth Verbindung funktioniert nicht!</h4>

        Bitte stelle in den Einstellungen deines Smartphones sicher, dass Bluetooth aktiviert ist und du unserer
        Anwendung die Verwendung von Bluetooth erlaubt hast. Bei Android ist gegebenfalls auch die Erlaubnis zu
        erteilen, die Positionsdaten abzufragen.
        <br />

        <h4 class="top-spacer-50">Ich kann mir die Mitgliedschaft zurzeit nicht leisten</h4>
        Schreibe uns bitte eine Mail an kontakt@pushinglimits.club mit dem Hinweis, dass du zurzeit finanziell nicht
        dazu in der Lage bist, eine Mitgliedschaft zu bezahlen. Wir schauen dann, was sich machen lässt.

        <h4 class="top-spacer-50">Noch eine andere Frage?</h4>
        Schreibe uns gerne per Mail an kontakt@pushinglimits.club!
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  props: {
    msg: String,
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>
