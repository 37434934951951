<template>
  <div>
    <div class="row top-spacer-100">
      <div class="col-lg-9">
        <div class="white_wrapper" style="text-align: left">
          <div v-if="trainingplans.length == 0">
            Keine Trainingspläne angelegt
          </div>

          <div v-for="trainingplan in trainingplans" :key="trainingplan._id" class="row colored-row"
            style="padding: 25px">
            <div class="col-lg-12">
              <h4>
                <span style="margin-right: 5px">
                  <span v-if="trainingplan.sport == 'Kraft'"><img src="@/assets/images/strength.png"
                      style="width: 22px" /></span>
                  <span v-if="trainingplan.sport == 'Laufen'"><img src="@/assets/images/run.png"
                      style="width: 22px" /></span>
                  <span v-if="trainingplan.sport == 'Schwimmen'"><img src="@/assets/images/swim.png"
                      style="width: 22px" /></span>
                  <span v-if="trainingplan.sport == 'Radfahren'"><img src="@/assets/images/bike.png"
                      style="width: 22px" /></span>
                  <span v-if="trainingplan.sport == 'Triathlon'"><img src="@/assets/images/tri.png"
                      style="width: 22px" /></span>
                </span>
                {{ trainingplan.title }}
              </h4>
            </div>

            <div class="col-lg-3">
              {{ trainingplan.sport }}
            </div>
            <div class="col-lg-3">{{ trainingplan.category }}</div>
            <div class="col-lg-2">
              {{ trainingplan.volume }}
            </div>
            <div class="col-lg-2">
              <div v-if="trainingplan.active" style="color: green">
                ✓ aktiviert
              </div>
              <div v-if="!trainingplan.active" style="color: red">
                × deaktiviert
              </div>
            </div>
            <div class="col-lg-2">
              <a v-bind:href="'/#/createtrainingsplan/' + trainingplan._id" class="a-button">Editieren</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="white_wrapper">
          <h4>Neuen Trainingsplan anlegen</h4>
          <hr />
          <h4>Bezeichnung</h4>
          <input v-model="title" />
          <hr />
          <h4>Beschreibung</h4>
          <textarea v-model="description"></textarea>
          <hr />
          <h5>Kategorie</h5>
          <select v-model="category">
            <option>Base</option>
            <option>Build</option>
            <option>Peak</option>
            <option>Block</option>
            <option>Trainingslager</option>
          </select>
          <hr />
          <h5>Sport</h5>
          <select v-model="sport">
            <option>Triathlon</option>
            <option>Radfahren</option>
            <option>Laufen</option>
            <option>Schwimmen</option>
            <!--<option>Hyrox</option>-->

            <option value="Kraft">Athletik</option>
          </select>
          <hr />
          <h5>Volume</h5>
          <select v-model="volume">
            <option>Anfänger</option>
            <option>Fortgeschritten</option>
            <option>Profi</option>
          </select>
          <hr />
          <button class="button-b" @click="save()">SPEICHERN</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTrainingsplan",

  data: function () {
    return {
      trainingplans: [],
      title: "",
      description: "",
      category: "Base",
      sport: "Triathlon",
      volume: "Beginner",
    };
  },

  methods: {
    save: async function () {
      const req = {
        title: this.title,
        description: this.description,
        category: this.category,
        sport: this.sport,
        volume: this.volume,
      };
      const result = await (
        await axios.post(this.$host + "/trainingsplan/create", req)
      ).data;
      console.log(result);

      this.updateData();
    },
    updateData: async function () {
      this.trainingplans = await (
        await axios.get(this.$host + "/trainingsplan/list")
      ).data;
    },
  },
  mounted: async function () {
    this.updateData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.loading {
  background: green;
}

.week {
  background: #efefef;
  margin-bottom: 30px;
  padding: 35px 0;
  padding-bottom: 15px;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

i {
  font-size: 16px;
  padding: 7px;
  color: #aaa;
}

.entry {
  background: #ddd;
  padding: 5px;
  border-radius: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: 3%;
  bottom: 3%;
  z-index: 99999999;
}

.overlay-box-library {
  position: fixed;
  left: 1%;
  right: 1%;
  top: 2%;
  bottom: 2%;
  background: #fff;
  padding: 0;
  overflow-x: hidden;
  z-index: 99999999;
}

.pointer {
  cursor: pointer;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .overlay-box {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-left: 0;
  }

  .overlay-box-library {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}
</style>
