<template>
  <div class="row" v-show="workouts.length >= 2">
    <div class="col-lg-12">
      <div style="height: 300px; padding-bottom: 98px" class="top-spacer-25 white_wrapper_tighter">
        <h4>{{ $t(`Schwellenschätzung Laufen`) }}</h4>

        <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
        <div class="top-spacer-5">
          <small>{{
            $t(
              `Die goldene Linie ist die zurzeit beste Schätzung. Die blaue Linie zeigt eine zweite Schätzung für jede Einheit, die schwarze Linie ist eine Glättung der blauen.`
            )
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="top-spacer-25 white_wrapper_tighter">
        <div>
          <h4>{{ $t(`Distanz-Leistungsschätzungen`) }}</h4>
        </div>

        <div class="row">
          <div class="col-4">
            <strong>{{ $t(`Distanz`) }}</strong>
          </div>

          <div class="col-4">
            <strong>{{ $t(`Schätzung`) }}</strong>
          </div>

          <div class="col-4">
            <strong>{{ $t(`Profil`) }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-4">5km</div>

          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(5 / schwelleKm, 1.06))
                .format("H:mm:ss")
            }}
          </div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(5 / schwelleKmProfil, 1.06))
                .format("H:mm:ss")
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">10km</div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(10 / schwelleKm, 1.06))
                .format("H:mm:ss")
            }}
          </div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(10 / schwelleKmProfil, 1.06))
                .format("H:mm:ss")
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{ $t(`Halbmarathon`) }}</div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(21.0975 / schwelleKm, 1.06))
                .format("H:mm:ss")
            }}
          </div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(21.0975 / schwelleKmProfil, 1.06))
                .format("H:mm:ss")
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">{{ $t(`Marathon`) }}</div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(42.195 / schwelleKm, 1.06))
                .format("H:mm:ss")
            }}
          </div>
          <div class="col-4">
            {{
              $moment()
                .startOf("day")
                .seconds(3600 * Math.pow(42.195 / schwelleKmProfil, 1.06))
                .format("H:mm:ss")
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";
import axios from "axios";

export default {
  props: ["done"],

  data: function () {
    return {
      workouts: [],
      runThreshold: 0,
      schwelleKm: 0,
      schwelleKmProfil: 0,

      ctx: undefined,

      chartdata: {
        labels: [],
        datasets: [
          {
            borderColor: "rgb(100, 149, 237)",
            backgroundColor: "rgb(100, 149, 237)",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
          {
            borderColor: "rgb(10, 14, 23)",
            backgroundColor: "rgb(10, 14, 23)",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
          {
            borderColor: "rgb(237, 177, 100)",
            backgroundColor: "rgb(237, 177, 100)",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            order: 10,
            tension: 0.4,
            //fill: true,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return moment.utc(parseInt(context.formattedValue) * 1000).format("mm:ss") + "m/km";
              },
            },
          },
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            grid: {
              display: true,
            },
            reverse: true,

            stacked: false,
            id: "stack",
            position: "right",
            display: true,

            ticks: {
              beginAtZero: true,
              maxTicksLimit: 6,
              callback: function (label) {
                return moment.utc(label * 1000).format("mm:ss") + "m/km";
              },
            },
          },
          xAxes: {
            grid: {
              display: true,
            },
            id: "x-axis-1",
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");
    this.ctx = ctx;

    this.workouts = this.done;

    this.computeChartData();

    const config = {
      type: "line",
      data: this.chartdata,
      options: this.options,
      plugins: [
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.y;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
        {
          afterDraw: (chart) => {
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales.yAxes;

              let ctx = chart.ctx;
              ctx.save();

              if (yAxis) {
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                ctx.stroke();
              }
            }
          },
        },
      ],
    };

    new Chart(this.ctx, config);

    this.$emit("chartRendered");
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },

    computeChartData: async function () {
      this.workouts = this.workouts.filter((wo) => {
        return wo.sport == "Laufen" && wo.best_prediction_run;
      });
      this.workouts = this.workouts.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      for (const [i, workout] of this.workouts.entries()) {
        let wo = this.workouts;
        let arr = wo.slice(0, i + 1).slice(-4);
        let sum = arr.reduce((a, b) => a + b.best_prediction_run, 0);
        const avg = sum / arr.length || 0;

        this.chartdata.labels.push(this.formatDate(moment(workout.date)));

        this.chartdata.datasets[0].data.push(3600 / workout.best_prediction_run);
        if (avg) {
          this.chartdata.datasets[1].data.push(3600 / avg);
        } else {
          this.chartdata.datasets[1].data.push(undefined);
        }
        if (workout.best_prediction_run_enhanced) {
          this.chartdata.datasets[2].data.push(3600 / workout.best_prediction_run_enhanced);
        } else {
          this.chartdata.datasets[2].data.push(undefined);
        }
      }

      // alte Schätzung
      this.schwelleKm = 3600 / this.chartdata.datasets[1].data[this.chartdata.datasets[1].data.length - 1];

      // neue Schätzung
      if (
        this.chartdata.datasets[2] &&
        this.chartdata.datasets[2].data &&
        this.chartdata.datasets[2].data[this.chartdata.datasets[2].data.length - 1]
      ) {
        this.schwelleKm = 3600 / this.chartdata.datasets[2].data[this.chartdata.datasets[2].data.length - 1];
      }

      const thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;

      this.runThreshold = thresholds[thresholds.length - 1].threshold_run;

      this.schwelleKmProfil = 3600 / this.runThreshold;

      return this.chartdata;
    },
  },
};
</script>
