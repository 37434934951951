

<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";
//import moment from 'moment-timezone'
import axios from "axios";

export default {
  props: ["done", "labels", "chart_watts_should", "adjustFactor"],
  // TODO: SCHWELLE LAUFEN + SCHWIMMEN auf 2. Y ACHSE. Höher ist besser
  data() {
    return {
      chartInterval: undefined,
      workoutDone: this.done,
      chartData: {
        labels: this.labels,
      },
      options: {
        scale: {
          ticks: {
            precision: 0,
          },
        },
        aspectRatio: 1,
        layout: {
          padding: 0,
          margin: 0,
        },
        animation: {
          duration: 0, // 8000 fuer smooth aber laggt dann mit zweitem dataset
          y: { duration: 0 },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0,
          },
        },
        tooltips: {
          enabled: false,
        },

        scales: {
          y: {
            position: "right",

            display: false,
            grid: {
              display: false,
            },

            ticks: {
              precision: 0,
            },
          },

          yAxes: {
            stack: "s1",
            display: true,

            grid: {
              display: true,
            },
            stackWeight: 2,
            position: "left",

            ticks: {
              precision: 0,

              display: true,
              beginAtZero: false,
            },
          },

          xAxes: {
            display: false,

            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  beforeDestroy() {
    clearInterval(this.chartInterval);
  },

  async mounted() {
    const _self = this;
    let ctx = this.$refs.graph.getContext("2d");

    this.chartData.labels = this.labels;

    const thresholds = await (
      await axios.get(this.$host + "/user/thresholds")
    ).data;
    const ftp = thresholds[thresholds.length - 1].ftp;

    const lit = 0.75;
    const hit = 1.03;

    const skipped = (ctx, value) =>
      ctx.p0.skip || ctx.p1.skip || ctx.p1.parsed.y == 0 ? value : undefined;
    const down = (ctx) => {
      if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 <= ftp * lit) {
        return "rgb(0, 180, 0) ";
      } else if ((ctx.p0.parsed.y + ctx.p1.parsed.y) / 2 > ftp * hit) {
        return "rgb(180, 0, 0) ";
      } else {
        return "orange";
      }
    };

    this.options.plugins = {
      legend: {
        display: false,
      },
    };

    this.chartData.datasets = [
      {
        data: this.workoutDone,

        borderWidth: 2.7,
        yAxisID: "yAxes",
        backgroundColor: "#f4f4f4",

        segment: {
          borderColor: (ctx) =>
            skipped(ctx, "rgb(0,0,0,1)") || down(ctx, "rgb(192,75,75)"),
          borderDash: (ctx) => skipped(ctx, [1, 1]),
        },
      },

      {
        data: this.chart_watts_should,
        fill: true,
        yAxisID: "yAxes",
      },
    ];

    const config = {
      type: "line",
      data: this.chartData,
      options: this.options,
    };

    _self.chartData.datasets = [
      {
        data: _self.workoutDone,
        fill: false,
        backgroundColor: "#000",
        borderWidth: 2.7,
        yAxisID: "yAxes",
        segment: {
          backgroundColor: (ctx) => skipped(ctx, "rgb(0,0,0,1)"),
          borderColor: (ctx) =>
            skipped(ctx, "rgb(0,0,0,1)") || down(ctx, "rgb(192,75,75)"),
          borderDash: (ctx) => skipped(ctx, [1, 1]),
        },
      },

      {
        data: _self.chart_watts_should,
        fill: true,
        yAxisID: "yAxes",
        backgroundColor: "#f2f2f2",
        borderColor: "#ccc",
        borderWidth: 1.5,
      },
    ];

    let chart = new Chart(ctx, config);

    let adjusted_charts_watt_should = [];

    this.chartInterval = setInterval(function () {
      adjusted_charts_watt_should = [];
      for (const watt of _self.chart_watts_should) {
        adjusted_charts_watt_should.push(watt * (_self.adjustFactor / 100));
      }

      _self.chartData.datasets[0].data = _self.workoutDone;
      _self.chartData.datasets[1].data = adjusted_charts_watt_should;
      chart.update();
    }, 1000);
  },
};
</script>