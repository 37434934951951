<template>
  <div class="row">
    <div class="col-lg-12 col-xl-12" style="margin-top: 0">
      <div class="row top-spacer-50">
        <div class="col-lg-12">
          <h3>Kraft, Athletik, Beweglichkeit & mehr</h3>
          <div v-if="!loaded">
            <div class="col-lg-12 top-spacer-25">
              <div class="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4" v-for="m in unsere" :key="m._id">
          <a v-bind:href="'/#/kraftraum/' + m._id" class="media_link">
            <div
              class="top-spacer-25 white_wrapper_tighter"
              style="text-align: center"
            >
              <div class="wrapper">
                <iframe
                  width="100%"
                  v-bind:src="m.youtube_link"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>

              <h4 class="top-spacer-15">
                <strong>{{ m.title }}</strong>
              </h4>
            </div>
          </a>
        </div>
        <div class="col-lg-12 top-spacer-25">
          <h4>Wir arbeiten zurzeit an weiteren Videos!</h4>
        </div>
      </div>
      <div class="row top-spacer-25">
        <div class="col-lg-12">
          <h3>Videos, die wir empfehlen</h3>
        </div>
        <div class="col-lg-4" v-for="m in andere" :key="m._id">
          <a v-bind:href="'/#/kraftraum/' + m._id" class="media_link">
            <div
              class="top-spacer-25 white_wrapper_tighter"
              style="text-align: center"
            >
              <div class="wrapper">
                <iframe
                  width="100%"
                  v-bind:src="m.youtube_link"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>

              <h4 class="top-spacer-15">
                <strong>{{ m.title }}</strong>
              </h4>
            </div>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <button class="pull-left" v-if="page > 0" @click="loadPreviousPage()">
            Seite zurück
          </button>
        </div>
        <div class="col-lg-6">
          <button
            v-if="data.length > 24"
            class="pull-right"
            @click="loadNextPage()"
          >
            Nächste Seite
          </button>
        </div>
      </div>

      <div v-if="user && user.isAdmin">
        <hr />
        Admin:
        <a href="/#/kraftraum/create">Neu anlegen</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    title: "Kraftraum",
  },
  name: "Video",
  components: {},
  props: {
    initPage: Number,
  },
  data: function () {
    return {
      data: [],
      page: 0,
      user: undefined,
      loaded: false,
      unsere: [],
      andere: [],
    };
  },
  async mounted() {
    this.loaded = false;
    if (this.initPage) {
      this.page = this.initPage;
    }
    this.loadPage();

    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  methods: {
    async loadPage() {
      this.loaded = false;
      this.data = (
        await axios.get(this.$host + "/video/page/" + this.page)
      ).data;

      this.unsere = this.data.filter((v) => v.main_category == "PLC");
      this.andere = this.data.filter((v) => v.main_category != "PLC");

      this.loaded = true;
    },
    loadNextPage() {
      if (this.data.length > 3) {
        this.page++;
        this.loadPage();
      }
    },
    loadPreviousPage() {
      if (this.page > 0) {
        this.page--;
        this.loadPage();
      }
    },
  },
};
</script>

<style scoped>
main {
  margin: auto;
  max-width: 800px;
}

.wrapper {
  height: 250px;
  position: relative;
  overflow: hidden;
}
iframe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 345px;
}

.media {
  font-size: 18px;
}

.media_link {
  color: #000;
}
.media_link:hover {
  color: blue;
  text-decoration: none;
}
</style>
