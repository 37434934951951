var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-x":"hidden","margin":"-15px","margin-top":"0"}},[_c('div',{staticClass:"desktop-border-radius",style:({
    height: '100%',
    width: '100%',
    minHeight: '650px',

    background:
      'url(https://assets.pushinglimits.club/swimdalle.webp) no-repeat ' +
      'top/cover ',
  })},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5 col-xl-4 col-md-7"},[_c('div',{staticClass:"white_wrapper light-shadow",staticStyle:{"margin":"25px"}},[_c('div',{staticClass:"centered"},[_c('img',{staticClass:"mainlogo",staticStyle:{"box-shadow":"none","width":"180px"},attrs:{"alt":"PL logo","src":_vm.$client.getClient() == 'pushinglimits'
                ? require('@/assets/images/PL-Club-Logo.png')
                : require('@/assets/images/' + _vm.$client.getClient() + '.jpg')}})]),(_vm.loginError)?_c('div',{staticClass:"top-spacer-25"},[_c('h5',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t("loginError")))]),_c('hr'),_c('small',[_vm._v(_vm._s(_vm.$t("passwordNote")))])])]):_vm._e(),_c('form',{staticClass:"login top-spacer-25",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('h2',[_vm._v(_vm._s(_vm.$t("loginHeading")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"email",staticClass:"top-spacer-15",attrs:{"required":"","name":"email","type":"email","placeholder":"deine@email.de"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password",staticClass:"top-spacer-10",attrs:{"required":"","name":"pw","type":"password","placeholder":"Passwort"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('button',{staticClass:"top-spacer-10",staticStyle:{"width":"300px"},attrs:{"type":"submit"}},[_vm._v(" Login ")]),_c('br'),_c('br'),_c('strong',[_c('a',{attrs:{"href":"/#/passwortreset"}},[_vm._v(_vm._s(_vm.$t("Passwort vergessen?")))]),_vm._v("  /  "),_c('a',{attrs:{"href":"/#/register"}},[_vm._v(_vm._s(_vm.$t("Noch keinen Account?")))])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 top-spacer-50"},[_c('h3',[_vm._v(_vm._s(_vm.$t("Noch keinen Account?")))]),_c('a',{attrs:{"href":"/#/register"}},[_c('button',{staticStyle:{"width":"350px"}},[_vm._v(" "+_vm._s(_vm.$t("Neuen Account anlegen!"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }