<template>
  <div class="row">
    <div class="submenu col-xl-2 col-lg-3 d-none d-lg-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">{{ $t("Dashboard") }}</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/vorlagen">
              <div class="menu-entry active_submenu" style="padding-left: 35px">
                {{ $t("Workout-Vorlagen") }}
              </div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">{{ $t("Kalender") }}</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry">{{ $t("Trainingspläne") }}</div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry">{{ $t("Wissen") }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-10 col-lg-9">
      <div v-if="selectedCalendarWorkout != undefined" class="overlay" @click="hideOverlay()"></div>
      <div v-if="selectedCalendarWorkout != undefined" class="overlay-box" @keydown.esc="hideOverlay()">
        <div class="row">
          <div class="col-lg-12" style="margin: 0; border-bottom: 0">
            <div class="clearfix" style="padding: 10px">
              <h3>Einheit bearbeiten</h3>
            </div>

            <div style="padding: 30px; padding-top: 10px">
              <new-workout-vorlage
                v-bind:workout="selectedCalendarWorkout"
                v-if="thresholds"
                v-bind:thresholds="thresholds"
                @closed="hideOverlay"
                @wasSaved="hideOverlay"
              ></new-workout-vorlage>
            </div>
          </div>
        </div>
      </div>
      <div class="white_wrapper light-shadow">
        <h3>Eigene Workout-Vorlagen</h3>
        Workout-Vorlagen können immer wieder verwendet werden, um eine spezifische Einheit in deinen Kalender zu laden,
        ohne sie jedes mal neu anlegen zu müssen. <br />
        Um eine Vorlage anzulegen, kannst du beim Anlegen einer Einheit in deinem Kalender (dabei muss ggf. der
        Workout-Builder verwendet werden) den Haken bei "Als Vorlage speichern" setzen.
        <div class="row top-spacer-100" v-if="workoutsListTimeFiltered.length == 0"></div>
        <div class="row top-spacer-50" v-if="workoutsListTimeFiltered.length > 0">
          <div class="col-lg-1 d-none d-lg-block"></div>
          <div class="col-lg-2">
            <button
              class="filter-button"
              v-on:click="showAlle()"
              v-bind:style="
                active_btn == 'Alle' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
              "
            >
              Alle
            </button>
          </div>
          <div class="col-lg-2">
            <button
              class="filter-button"
              v-on:click="showLaufen()"
              v-bind:style="
                active_btn == 'Laufen' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
              "
            >
              Laufen
            </button>
          </div>
          <div class="col-lg-2">
            <button
              class="filter-button"
              v-on:click="showRadfahren()"
              v-bind:style="
                active_btn == 'Radfahren'
                  ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
                  : ''
              "
            >
              Radfahren
            </button>
          </div>
          <div class="col-lg-2">
            <button
              class="filter-button"
              v-on:click="showSchwimmen()"
              v-bind:style="
                active_btn == 'Schwimmen'
                  ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)'
                  : ''
              "
            >
              Schwimmen
            </button>
          </div>
          <div class="col-lg-2">
            <button
              class="filter-button"
              v-on:click="showKraft()"
              v-bind:style="
                active_btn == 'Kraft' ? 'border:2px solid rgba(94, 110, 255);background:rgba(94, 110, 255, 0.97)' : ''
              "
            >
              Athletik
            </button>
          </div>
        </div>

        <div class="row top-spacer-25" v-if="workoutsListTimeFiltered.length > 0">
          <div class="col-lg-3"></div>
          <div class="col-lg-3">
            <div class="slidecontainer">
              Dauer mindestens {{ formattedTime(durationMin) }}
              <input
                type="range"
                v-bind:min="durationMinStart"
                v-bind:max="durationMaxStart"
                class="slider"
                id="durationMin"
                v-model.number="durationMin"
                v-on:change="durationChange()"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="slidecontainer">
              Dauer höchstens {{ formattedTime(durationMax) }}
              <input
                type="range"
                v-bind:min="durationMinStart"
                v-bind:max="durationMaxStart"
                class="slider"
                id="durationMax"
                v-model.number="durationMax"
                v-on:change="durationChange()"
              />
            </div>
          </div>
        </div>

        <div class="spinner-border text-primary top-spacer-50" role="status" v-if="loading"></div>

        <div class="row top-spacer-50">
          <div class="col-1 d-block d-md-none"></div>

          <div class="col-10 col-lg-12">
            <div class="row">
              <div class="col-lg-2" v-for="workout in workoutsListTimeFiltered" :key="workout._id">
                <div class="workout-box">
                  <div style="color: red" v-if="isInGlobalWorkouts(workout._id)">ADMIN RECHTE</div>
                  <div class="sport">
                    <span
                      class="sport-inner"
                      v-bind:style="workout.durationIs ? 'font-weight:bold;margin-right:-20px' : ''"
                      ><strong>{{ workout.name }}</strong>
                    </span>
                    <small style="float: right" class="numberCircle" v-if="workout.durationIs">{{
                      Math.round(
                        Math.sqrt((Math.E * 0.75) ** (workout.perceivedStress - 1)) * (workout.durationIs / 60) ** 0.7
                      )
                    }}</small>
                  </div>
                  <div
                    class="row work"
                    style="background: #ddd; padding: 7px 5px; height: 35px; display: flex; justify-content: center"
                    v-if="workout.workoutFile && workout.workoutFile.length != 0 && workout.sport == 'Schwimmen'"
                  >
                    <span
                      class="workoutBuilderBlock"
                      v-for="value in workout.workoutFile"
                      :key="value.id"
                      v-bind:height="35"
                    >
                      <svg
                        v-if="value.type == 'SteadyState'"
                        class="workoutBuilderBlock"
                        v-bind:width="(value.Distance || 100) / 40"
                        v-bind:height="35"
                      >
                        <rect
                          v-bind:id="value.id"
                          v-bind:width="(value.Distance || 100) / 40"
                          v-bind:height="28"
                          v-bind:style="'fill:' + colorForPower(value.Power)"
                        />
                      </svg>

                      <svg
                        v-if="value.type == 'Rest'"
                        class="workoutBuilderBlock"
                        v-bind:width="value.Duration / 20"
                        v-bind:height="35"
                      >
                        <rect
                          v-bind:id="value.id"
                          v-bind:width="value.Duration / 20"
                          v-bind:height="23"
                          style="fill: rgba(94, 110, 255, 0.5)"
                        />
                      </svg>

                      <span v-if="value.type == 'IntervalsRest'">
                        <span>
                          <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                            <svg
                              class="workoutBuilderBlock"
                              v-bind:width="(value.Distance || 100) / 40"
                              v-bind:height="35"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="(value.Distance || 100) / 40"
                                v-bind:height="35"
                                style="fill: darkred"
                              />
                            </svg>
                            <svg class="workoutBuilderBlock" v-bind:width="(value.Rest || 0) / 20" v-bind:height="35">
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="(value.Rest || 0) / 20"
                                v-bind:height="23"
                                v-bind:style="'fill: rgba(94, 110, 255, 0.67)'"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>

                  <div
                    class="row work"
                    style="background: #cacaca; padding: 7px 5px"
                    v-if="workout.sport != 'Schwimmen'"
                  >
                    <div class="col-lg-12">
                      <span v-if="!workout.workoutFile || workout.workoutFile.length == 0">
                        <svg class="workoutBuilderBlock" v-bind:width="150" v-bind:height="18">
                          <rect
                            v-bind:width="100 + '%'"
                            v-bind:height="50"
                            v-bind:style="'fill:rgba(94, 110, 255, 0.97);'"
                          />
                        </svg>
                      </span>
                      <span v-for="value in workout.workoutFile" :key="value.id">
                        <svg
                          v-if="value.type == 'SteadyState'"
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="100 + '%'"
                            v-bind:height="value.Power / 4"
                            v-bind:style="'fill:' + colorForPower(value.Power)"
                          />
                        </svg>

                        <svg
                          v-if="value.type == 'Ramp'"
                          class="workoutBuilderBlock"
                          v-bind:width="computeWidth(value.Duration, workout.workoutFile)"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-for="index in 20"
                            :key="index"
                            v-bind:id="value.id + '-' + index"
                            v-bind:width="100 / 20 + '%'"
                            v-bind:x="(100 / 20) * index - 5 + '%'"
                            v-bind:height="getRampRectHeight(20, value.PowerLow / 4, value.PowerHigh / 4, index)"
                            v-bind:style="
                              'fill:' + colorForPower(getRampRectHeight(20, value.PowerLow, value.PowerHigh, index))
                            "
                          />
                        </svg>

                        <svg
                          v-if="value.type == 'FreeRide'"
                          class="workoutBuilderBlock"
                          v-bind:width="value.Duration / 15"
                          v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                        >
                          <rect
                            v-bind:id="value.id"
                            v-bind:width="value.Duration / 15"
                            v-bind:height="80"
                            style="fill: rgba(94, 110, 255, 0.5)"
                          />
                        </svg>

                        <span v-if="value.type == 'IntervalsT'">
                          <span v-for="index in list.slice(0, value.Repeat)" :key="index">
                            <svg
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.OnDuration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="'100%'"
                                v-bind:height="value.OnPower / 4"
                                v-bind:style="'fill:' + colorForPower(value.OnPower)"
                              />
                            </svg>
                            <svg
                              class="workoutBuilderBlock"
                              v-bind:width="computeWidth(value.OffDuration, workout.workoutFile)"
                              v-bind:height="workoutBuilderHeight(workout.workoutFile)"
                            >
                              <rect
                                v-bind:id="value.id"
                                v-bind:width="'100%'"
                                v-bind:height="value.OffPower / 4"
                                v-bind:style="'fill:' + colorForPower(value.OffPower)"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <div v-bind:style="workout.durationIs ? 'font-weight:bold' : ''">
                    <div style="margin-top: 5px">
                      <span v-if="workout.sport == 'Kraft'"
                        ><img src="@/assets/images/strength.png" style="width: 23px"
                      /></span>
                      <span v-if="workout.sport == 'Laufen'"
                        ><img src="@/assets/images/run.png" style="width: 23px"
                      /></span>
                      <span v-if="workout.sport == 'Schwimmen'"
                        ><img src="@/assets/images/swim.png" style="width: 23px"
                      /></span>
                      <span v-if="workout.sport == 'Radfahren'"
                        ><img src="@/assets/images/bike.png" style="width: 23px"
                      /></span>
                      <span class="tooltip" v-if="workout.description">
                        <i class="fa fa-commenting-o" aria-hidden="true"></i>
                        <span class="tooltiptext">{{ workout.description }}</span>
                      </span>
                    </div>

                    <!--
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="!isInPast(workout.date)">{{formattedTime(totalWorkoutLength(workout.workoutFile))}}</span> 
          <span v-bind:style="{ color: colorForDuration(totalWorkoutLength(workout.workoutFile), workout.durationIs, workout.date ) }" v-if="isInPast(workout.date)"><strong>{{formattedTime(workout.durationIs || 0)}}</strong></span> |
            -->
                    <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length > 0"
                      >{{ formattedTime(totalWorkoutLength(workout.workoutFile, workout.durationShould)) }}
                    </span>
                    <span v-if="!workout.durationIs && workout.workoutFile && workout.workoutFile.length == 0">{{
                      formattedTime(workout.durationShould)
                    }}</span>

                    <span v-if="workout.durationIs">{{ formattedTime(workout.durationIs) }}</span>

                    <span v-if="workout.paceAvg && workout.sport == 'Laufen'">
                      @
                      {{
                        $moment()
                          .startOf("day")
                          .seconds(Math.round(workout.paceAvg * 60))
                          .format("mm:ss")
                      }}min/km
                    </span>
                    <span v-if="workout.paceAvg && workout.sport == 'Radfahren'">
                      @ {{ Math.round((60 / workout.paceAvg) * 10) / 10 }}km/h
                    </span>
                    <span v-if="workout.heartrateAvg">{{ workout.heartrateAvg }}bpm</span>
                    <span v-if="workout.distance"><br />{{ Math.round(workout.distance * 10) / 10 }}km</span>
                    <span v-if="workout.powerInWatts"> | {{ Math.round(workout.powerInWatts * 10) / 10 }}w</span>

                    <span v-if="workout.sport == 'Radfahren' || workout.sport == 'Laufen'">
                      <!-- todo swim run kraft-->

                      <span
                        v-if="
                          workout.sport == 'Radfahren' &&
                          !workout.durationIs &&
                          workout.workoutFile &&
                          workout.workoutFile.length > 0
                        "
                      >
                        Ø {{ computeAvgWatt(workout.workoutFile) }}w</span
                      >
                      <span
                        v-if="
                          workout.sport == 'Laufen' &&
                          !workout.durationIs &&
                          workout.workoutFile &&
                          workout.workoutFile.length > 0
                        "
                        >Ø {{ computeAvgRunSpeed(workout.workoutFile) }}m/km</span
                      >
                      <!-- <span v-if="workout.sport == 'Schwimmen'"> Ø {{computeAvgSwimSpeed(workout.workoutFile)}}m/km</span>--> </span
                    ><br />
                    <span v-if="workout.sport == 'Schwimmen'">{{ workout.distanceShould }}km</span>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-12">
                      <a @click="showOverlay(workout._id)" class="pointer">
                        <div>Bearbeiten</div>
                      </a>
                    </div>
                    <hr />

                    <div class="col-12">
                      <strong v-if="isInGlobalWorkouts(workout._id)">ACHTUNG GLOBAL<br /> </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-1 d-block d-md-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import calendar_utils from "@/js/calendar_utils";
import NewWorkoutVorlage from "@/components/workout/NewWorkoutVorlage";

export default {
  metaInfo: {
    title: "Vorlagen",
  },
  name: "Vorlagen",
  components: { NewWorkoutVorlage },
  data() {
    return {
      selectedCalendarWorkout: undefined,

      thresholds: {},
      durationMin: 0,
      durationMax: 0,
      durationMinStart: 0,
      durationMaxStart: 0,

      allWorkoutsListGlobal: [],
      allWorkoutsList: [],
      workoutsList: [],
      workoutsListTimeFiltered: [],
      active_btn: "Alle",
      loading: true,
      list: new Array(250),
      user: {},
    };
  },
  methods: {
    deleteVorlage(id) {
      console.log(id);
      const _self = this;
      axios
        .delete(this.$host + "/workout/blueprint/" + id)
        .then(function () {
          axios
            .get(this.$host + "/workout/blueprints2/v2")
            .then(function (response) {
              _self.allWorkoutsList = response.data.user;
              _self.allWorkoutsListGlobal = response.data.global;

              if (_self.user.isAdmin) {
                // Admins können alles bearbeiten
                _self.allWorkoutsList = _self.allWorkoutsList.concat(_self.allWorkoutsListGlobal);
              }

              _self.workoutsListTimeFiltered = _self.allWorkoutsList;
              _self.setMaxDuration();
              _self.setMinDuration();
              _self.loading = false;
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRampRectHeight(numberOfBlocks, startIntensity, endIntensity, index) {
      let res = startIntensity - ((startIntensity - endIntensity) / numberOfBlocks) * index;

      return res;
    },
    showOverlay(id) {
      const blueprint = this.allWorkoutsList.find((workout) => {
        return id == workout._id;
      });

      this.selectedCalendarWorkout = blueprint;

      console.log(this.selectedCalendarWorkout);
    },
    hideOverlay() {
      this.updateData();
      this.selectedCalendarWorkout = undefined;
    },
    showAlle() {
      this.active_btn = "Alle";

      this.workoutsList = this.allWorkoutsList;
      this.filterByDuration();
    },
    showLaufen() {
      this.active_btn = "Laufen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Laufen");
      this.filterByDuration();
    },
    showRadfahren() {
      this.active_btn = "Radfahren";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Radfahren");
      this.filterByDuration();
    },
    showSchwimmen() {
      this.active_btn = "Schwimmen";
      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Schwimmen");
      this.filterByDuration();
    },
    colorForPower(power) {
      if (power > 105) {
        return "darkred";
      } else if (power > 100) {
        return "brown";
      } else if (power > 95) {
        return "firebrick";
      } else if (power > 88) {
        return "darkgreen";
      } else if (power > 83) {
        return "green";
      } else {
        return "rgba(94, 110, 255, 0.97)";
      }
    },
    showKraft() {
      this.active_btn = "Kraft";

      this.workoutsList = this.allWorkoutsList.filter((workout) => workout.sport == "Kraft");
      this.filterByDuration();
    },
    durationChange() {
      this.filterByDuration();
    },

    //TODO FTP
    computeAvgWatt(workoutFile) {
      return calendar_utils.computeAvgPowerFromWorkoutFile(workoutFile, this.thresholds.ftp);
    },

    computeAvgRunSpeed(workoutFile) {
      return this.$moment()
        .startOf("day")
        .seconds(calendar_utils.computeSpeedFromWorkoutFile(workoutFile, this.thresholds.threshold_run))
        .format("mm:ss");
    },

    filterByDuration() {
      const _self = this;

      if (_self.durationMin > _self.durationMax) {
        _self.durationMax = _self.durationMin;
        console.log("yes");
      }
      console.log(typeof _self.durationMin + ">" + typeof _self.durationMax);
      this.workoutsListTimeFiltered = this.workoutsList.filter((workout) => {
        const workoutLength = _self.totalWorkoutLength(workout.workoutFile, workout.durationShould);
        return workoutLength >= _self.durationMin && workoutLength <= _self.durationMax;
      });
    },

    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H[h ]mm[min]");
    },

    computeWidth(val, workoutFile) {
      const width = Math.floor((val / this.totalWorkoutLength(workoutFile)) * 100) - 1;

      return width > 1 ? width + "%" : "1px";
    },

    setMaxDuration() {
      const _self = this;

      this.durationMax = Math.max.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout.workoutFile, workout.durationShould);
        })
      );

      this.durationMaxStart = this.durationMax;
    },

    setMinDuration() {
      const _self = this;
      this.durationMin = Math.min.apply(
        Math,
        this.workoutsList.map(function (workout) {
          return _self.totalWorkoutLength(workout.workoutFile, workout.durationShould);
        })
      );

      this.durationMinStart = this.durationMin;
    },

    workoutBuilderHeight(workoutFile) {
      let maxPower = Math.max.apply(
        Math,
        workoutFile.map(function (o) {
          let onPower = typeof o.OnPower == "number" ? o.OnPower : 0;
          let offPower = typeof o.OffPower == "number" ? o.OffPower : 0;
          let power = typeof o.Power == "number" ? o.Power : 0;
          let powerLow = typeof o.PowerLow == "number" ? o.PowerLow : 0;
          let powerHigh = typeof o.PowerHigh == "number" ? o.PowerHigh : 0;
          return Math.max(onPower, offPower, power, powerLow, powerHigh);
        })
      );
      return Math.min(maxPower / 4, 35);
    },
    totalWorkoutLength(workoutFile, defaultDuration = 0) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length || defaultDuration;
    },
    getDateForTimestamp() {
      return this.$moment(this.dayDate).toDate();
    },
    isInGlobalWorkouts(id) {
      return this.allWorkoutsListGlobal.some((el) => el._id == id);
    },
    updateData: async function () {
      const _self = this;
      this.thresholds = await (await axios.get(this.$host + "/user/threshold")).data;

      this.user = (await axios.get(this.$host + "/user/me")).data;

      axios
        .get(this.$host + "/workout/blueprints2/v2")
        .then(function (response) {
          _self.allWorkoutsList = response.data.user;
          _self.allWorkoutsListGlobal = response.data.global;

          if (_self.user.isAdmin) {
            // Admins können alles bearbeiten
            _self.allWorkoutsList = _self.allWorkoutsList.concat(_self.allWorkoutsListGlobal);
          }

          _self.workoutsList = _self.allWorkoutsList;
          _self.workoutsListTimeFiltered = _self.workoutsList.sort(function (a, b) {
            var durationA = _self.totalWorkoutLength(a.workoutFile) || a.durationShould;
            var durationB = _self.totalWorkoutLength(b.workoutFile) || b.durationShould;

            if (durationA < durationB) {
              return -1;
            }
            if (durationA > durationB) {
              return 1;
            }

            // Namen müssen gleich sein
            return 0;
          });
          _self.setMaxDuration();
          _self.setMinDuration();
          _self.loading = false;

          if (_self.active_btn != "Alle") {
            _self.workoutsList = _self.allWorkoutsList.filter((workout) => workout.sport == _self.active_btn);
          }
          _self.filterByDuration();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  mounted: async function () {
    this.updateData();
  },
  props: {
    dayDate: Object,
  },
};
</script>

<style scoped>
.workout-box {
  cursor: default;
}

.bg {
  background: #fff;
}

.filter-button {
  width: 100%;
}

.title {
  font-weight: bold;
  background: #ccc;
  color: #000;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #ddd;
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border: 0;
  border-radius: 30px;
}
svg {
  transform: scaleY(-1);
}

.workoutBuilderBlock {
  display: inline-block;
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(94, 110, 255, 0.97); /* Green background */
  border: 0;
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
  z-index: 66666;
  overflow: hidden;
}

.close-overlay i {
  cursor: pointer;
  font-size: 35px;
  float: right;
}

.overlay-box {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  background: #fff;
  padding: 0;
  overflow-y: auto !important;
  margin: auto;
  max-width: 750px;
  width: 91%;
  left: 50%;
  margin-left: -375px;
  overflow-x: hidden;
  top: calc(env(safe-area-inset-top) + 3%);
  bottom: 3%;

  z-index: 99999999;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .overlay-box {
    position: fixed;

    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    margin-left: 0;
  }
  .overlay-box-library {
    position: fixed;
    left: 20px;
    right: 20px;
    top: calc(env(safe-area-inset-top) + 5px);
    bottom: 20px;
    background: #fff;
    padding: 0;
    overflow-x: hidden;
    z-index: 99999999;
  }
}
</style>
