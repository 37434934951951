

<template>
  <div>
    <canvas ref="stressGraph" id="stressGraph" width="100%"></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: ["session", "upper_limit"],

  data: function () {
    return {
      workouts: [],

      chartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointRadius: [],
            trendlineLinear: {
              style: "rgba(0,0,0, .8)",
              lineStyle: "line",
              width: 2,
              projection: true,
            },
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        scales: {
          yAxes: {
            title: {
              display: true,
              text: "watt",
            },
          },
          xAxes: {
            title: {
              display: true,
              text: "bpm",
            },
          },
        },

        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            callbacks: {
              label: function (context) {
                var datasetLabel = context.parsed.y + "w";
                return datasetLabel;
              },
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        onClick: this.clickedWorkout,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressGraph.getContext("2d");

    const _self = this;

    _self.chartdata.datasets[0].pointRadius = [];
    _self.chartdata.datasets[0].data = [];
    _self.chartdata.datasets[0].pointBackgroundColor = [];
    _self.chartdata.datasets[0].pointBorderColor = [];

    const records = this.session.laps.flatMap((x) => x.records);
    const numberOfRecords = records.length;
    const bucket_size = 250;
    let buckets = [];
    const records_per_bucket = Math.round(numberOfRecords / bucket_size);
    let sumPower = 0;
    let sumHr = 0;

    let counter = 0;
    let maxPower = 0;
    let minPower = 99999;

    records.forEach(function (record) {
      counter++;
      if (record.enhanced_power) {
        record.power = record.enhanced_power || 0;
      }

      sumPower += record.power || 0;
      sumHr += record.heart_rate || 0;

      if (maxPower < record.power) {
        maxPower = record.power;
      }
      if (minPower > record.power) {
        minPower = record.power;
      }

      if (counter == records_per_bucket) {
        buckets.push({
          hr: sumHr / records_per_bucket,
          power: sumPower / records_per_bucket,
          time: record.elapsed_time,
        });

        sumPower = 0;
        sumHr = 0;
        counter = 0;
      }
    });

    buckets.sort((a, b) => a.hr - b.hr); // b - a for reverse sort

    if (buckets.length > 60) {
      buckets.splice(0, 5);
    }

    counter = 0;
    buckets.forEach(function (entry) {
      counter++;
      if (entry.hr > 90) {
        _self.chartdata.datasets[0].data.push({
          x: Math.round(entry.hr * 10) / 10,
          y: Math.round(entry.power * 10) / 10,
        });

        _self.chartdata.datasets[0].pointRadius.push(3);
        _self.chartdata.datasets[0].pointBackgroundColor.push(
          _self.getColorForPercentage(
            (entry.time / _self.session.total_timer_time) * 100
          )
        );
        _self.chartdata.datasets[0].pointBorderColor.push(
          _self.getColorForPercentage(
            (entry.time / _self.session.total_timer_time) * 100
          )
        );
      }
    });

    // _self.chartdata.datasets[0].regressions.sections[0].startIndex =
    //  this.chartdata.datasets[0].data[0].x;

    this.$emit("chartRendered");

    const config = {
      type: "scatter",
      data: this.chartdata,
      options: this.options,
    };

    new Chart(ctx, config);
  },

  methods: {
    getColorForPercentage: function (pct) {
      pct = Math.min(100, pct);
      var a = (94 - pct) / 100,
        b = 250 * a;

      return "hsl(" + b + ", 100%, 40%)";
    },
  },
};
</script>

