import { render, staticRenderFns } from "./Esszimmer.vue?vue&type=template&id=327eb958&scoped=true"
import script from "./Esszimmer.vue?vue&type=script&lang=js"
export * from "./Esszimmer.vue?vue&type=script&lang=js"
import style0 from "./Esszimmer.vue?vue&type=style&index=0&id=327eb958&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327eb958",
  null
  
)

export default component.exports