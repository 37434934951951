<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/training/dashboard">
              <div class="menu-entry">{{ $t("Dashboard") }}</div></a
            >
          </div>
          <div class="col-lg-12">
            <a href="/#/training/kalender">
              <div class="menu-entry">{{ $t("Kalender") }}</div></a
            >
          </div>

          <div class="col-lg-12">
            <a href="/#/training/liste">
              <div class="menu-entry active_submenu">
                {{ $t("Trainingspläne") }}
              </div>
            </a>
          </div>

          <div class="col-lg-12">
            <a href="/#/training/FragDenCoach">
              <div class="menu-entry">{{ $t("Wissen") }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10">
      <div class="row">
        <div class="col-12 top-spacer-10" v-if="warnPremiumExpiration" style="text-align: center; color: #fff">
          <div
            class="col-12"
            style="
              background-size: 200% auto;
              padding: 20px;
              background: #000;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
            "
          >
            <br />

            {{
              $t(
                "Hinweis: Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du über die App im Profil ein Abo abschließen."
              )
            }}

            <h2>
              {{ $t(`Die Trainingspläne stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.`) }}
            </h2>
          </div>
        </div>

        <div class="col-lg-12 d-none d-md-block top-spacer-10"></div>

        <div class="col-lg-12 top-spacer-15">
          <div class="row">
            <div class="col-lg-12">
              <!--
              <div>
                <div
                  class="subimg"
                  :style="{
                    height: '170px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    background:
                      'url(' +
                      require('@/assets/images/bike.webp') +
                      ') 30% 30%',
                  }"
                ></div>
              </div>-->
              <div class="white_wrapper top-spacer-15" style="padding-top: 30px">
                <!--
                <h3>
                  <span class="highlight">{{$t("Deine Trainingspläne")}}</span>
                </h3>
-->
                <div class="top-spacer-25 styled-table extra-styled-table" style="border: 1px solid #ddd">
                  <div v-if="activeTrainingplans.length != 0 || !loaded" class="white_wrapper" style="padding: 20px">
                    <h5 style="text-align: center" v-if="activeTrainingplans.length != 0">
                      {{ $t("Aktivierte Trainingspläne") }}
                    </h5>
                    <div class="row" v-if="!loaded">
                      <div class="col-lg-12 top-spacer-25 centered" style="margin-bottom: 25px">
                        <div class="spinner-border text-primary" role="status"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="activeTrainingplans.length == 0 && loaded"
                    class="white_wrapper"
                    style="padding-bottom: 15px; padding-top: 30px"
                  >
                    <h5>
                      {{ $t("Zurzeit verwendest du keinen Trainingsplan") }}
                    </h5>
                    <p style="font-size: 16px">
                      {{
                        $t(
                          `Du kannst über das Formular unten jederzeit einen oder mehrere Trainingspläne zu beliebigen Zeitpunkten verwenden. Alle Pläne sind in deiner Mitgliedschaft bereits inbegriffen.`
                        )
                      }}
                    </p>
                  </div>
                  <div class="row" v-for="plan in activeTrainingplans" :key="plan._id" style="text-align: center">
                    <div class="col-lg-4">
                      <h4 style="margin-top: 0.5rem">
                        <strong
                          ><a :href="'/#/training/vorschau/' + plan.trainingsplan._id">{{
                            plan.trainingsplan.title
                          }}</a></strong
                        >
                      </h4>
                    </div>
                    <div class="col-lg-2" style="margin-top: 0.5rem">
                      <h4>{{ plan.trainingsplan.volume }}</h4>
                    </div>
                    <div class="col-lg-3">
                      <h4 style="margin-top: 0.5rem">
                        {{ $moment(plan.start).format("DD.MM.YYYY") }} -
                        {{ $moment(plan.end).format("DD.MM.YYYY") }}
                      </h4>
                    </div>
                    <div class="col-lg-3">
                      <button @click="deactivatePlan(plan._id)">
                        {{ $t("Entfernen") }}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="top-spacer-50 styled-table extra-styled-table"
                  style="border: 1px solid #ddd; text-align: center"
                >
                  <div class="row">
                    <div class="col-lg-12 top-spacer-25">
                      <h4>
                        {{ $t("Für welchen Sport suchst du einen Trainingsplan?") }}
                      </h4>
                    </div>

                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Triathlon';
                        planFilter = '';
                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-tri2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Triathlon") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Radfahren';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-cycle2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Radfahren") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Laufen';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-run.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Laufen") }}
                      </h4>
                    </div>
                    <div
                      class="col-lg-3 col-6 top-spacer-25 pointer"
                      @click="
                        sportFilter = 'Schwimmen';
                        planFilter = '';

                        goToPlan();
                      "
                    >
                      <img
                        src="https://assets.pushinglimits.club/d-swim2.webp"
                        style="width: 100%; border-radius: 10px"
                      />
                      <h4 class="top-spacer-10">
                        {{ $t("Schwimmen") }}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="top-spacer-50" style="text-align: left">
                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_tp_anwenden = !show_tp_anwenden"
                  >
                    <div class="row">
                      <div class="col-10">
                        Trainingspläne richtig anwenden (Base, Build, Peak)
                        <span style="color: blue">(Neu)</span>
                      </div>

                      <div class="col-2">
                        <i v-if="show_tp_anwenden" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_tp_anwenden" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_tp_anwenden"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/8ggr0JBUrQk"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_niveau_video = !show_niveau_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie schätze ich mein Niveau richtig ein?</div>

                      <div class="col-2">
                        <i v-if="show_niveau_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_niveau_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_niveau_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/h-Cp2G0qj3M"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_angehen_video = !show_angehen_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie gehe ich einen Trainingsplan an?</div>

                      <div class="col-2">
                        <i v-if="show_angehen_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_angehen_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_angehen_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/HdFk_pDowM8"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_reihenfolge = !show_reihenfolge"
                  >
                    <div class="row">
                      <div class="col-10">In welcher Reihenfolge trainiere ich die Pläne?</div>

                      <div class="col-2">
                        <i v-if="show_reihenfolge" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_reihenfolge" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div v-if="show_reihenfolge" class="top-spacer-25">
                      Viele Pläne sind in drei Phasen aufgeteilt:
                      <strong>Base, Build und Peak</strong>. Optimalerweise trainierst du diese in der Reihenfolge ab.
                      Der Peakplan sollte mit dem Wettkampf enden und enhält dann bereits eine Tapering-Phase. Einige
                      Pläne (zum Beispiel der 12 Wochen Mitteldistanz Peak Plan) haben zwei Wettkämpfe enthalten (einen
                      nach 8 und einen nach 12 Wochen). Schaue gerne auch in die Beschreibung der Pläne hinein.
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_vorbereiten_video = !show_vorbereiten_video"
                  >
                    <div class="row">
                      <div class="col-10">Wie bereite ich ein Training vor und nach?</div>

                      <div class="col-2">
                        <i v-if="show_vorbereiten_video" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_vorbereiten_video" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_vorbereiten_video"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/DNEKHMAsmm8"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_mehr_plaene = !show_mehr_plaene"
                  >
                    <div class="row">
                      <div class="col-10">Kommen noch mehr Pläne und wann kommen diese?</div>

                      <div class="col-2">
                        <i v-if="show_mehr_plaene" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_mehr_plaene" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div class="top-spacer-25" v-if="show_mehr_plaene">
                      Ja, wir arbeiten kontinuierlich an neuen Plänen. :-)
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_wechseln = !show_wechseln"
                  >
                    <div class="row">
                      <div class="col-10">
                        Kann ich die Pläne auch wechseln (z.B. das Level von Anfänger auf Fortgeschritten)?
                      </div>

                      <div class="col-2">
                        <i v-if="show_wechseln" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_wechseln" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div class="top-spacer-25" v-if="show_wechseln">
                      Du kannst jederzeit zwischen allen Plänen wechseln und sogar mehrere Pläne gleichzeitig aktiveren.
                      Das soll dir ermöglichen, genau den richtigen Plan mit der richtigen Schwierigkeit zu finden oder
                      auch ganz andere Ziele (z.B. einen Herbstmarathon) anzugehen.
                    </div>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_trainingslager = !show_trainingslager"
                  >
                    <div class="row">
                      <div class="col-10">Wie plane ich ein Trainingslager?</div>

                      <div class="col-2">
                        <i v-if="show_trainingslager" class="fa fa-chevron-up pull-right" aria-hidden="true"></i>
                        <i v-if="!show_trainingslager" class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_trainingslager"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/YY1yx9IW36o"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>

                  <h4
                    class="pointer"
                    style="border: 1px solid #ddd; border-radius: 10px; padding: 15px"
                    @click="show_trainingslager_richtig = !show_trainingslager_richtig"
                  >
                    <div class="row">
                      <div class="col-10">
                        Trainingslager Pläne richtig anwenden!
                        <span style="color: blue">(Neu)</span>
                      </div>

                      <div class="col-2">
                        <i
                          v-if="show_trainingslager_richtig"
                          class="fa fa-chevron-up pull-right"
                          aria-hidden="true"
                        ></i>
                        <i
                          v-if="!show_trainingslager_richtig"
                          class="fa fa-chevron-down pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <iframe
                      v-if="show_trainingslager_richtig"
                      class="top-spacer-25"
                      width="100%"
                      height="500px"
                      src="https://www.youtube-nocookie.com/embed/lUrVEOOYtcA"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </h4>
                </div>

                <div class="col-lg-12 top-spacer-25">
                  <div v-if="sportFilter == 'Triathlon'">
                    <h4>Welches Ziel hast du?</h4>
                    <div class="row top-spacer-25">
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'olymp';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/sprint.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: top;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Olympische / Sprint") }}
                        </h4>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'mittel';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/md.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Halbdistanz") }}
                        </h4>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'lang';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/ld.webp"
                          style="
                            width: 100%;
                            height: 180px;
                            object-fit: cover;
                            object-position: top;
                            border-radius: 10px;
                          "
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Langdistanz") }}
                        </h4>
                        <div class="col-lg-12"><br /></div>
                      </div>
                      <div
                        class="col-lg-3 pointer col-6"
                        @click="
                          planFilter = 'trainingslager';
                          goToPlan();
                        "
                      >
                        <img
                          src="@/assets/images/trainingslager.webp"
                          style="width: 100%; height: 180px; object-fit: cover; border-radius: 10px"
                        />
                        <h4 class="top-spacer-10">
                          {{ $t("Trainingslager / Sonstige") }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row top-spacer-75" ref="plan">
                  <div class="col-lg-6 top-spacer-25" style="text-align: left">
                    Wir arbeiten zurzeit an weiteren Plänen. Stay tuned!
                  </div>
                  <div class="col-lg-3">
                    <select
                      class="form-select top-spacer-10"
                      style="width: 100%; overflow: hidden; text-overflow: ellipsis"
                      v-bind:value="sportFilter"
                      v-on:input="sportFilter = $event.target.value"
                    >
                      <option value="all">Alle Sportarten</option>
                      <option value="Triathlon">Triathlon</option>
                      <option value="Radfahren">Radfahren</option>
                      <option value="Laufen">Laufen</option>
                      <option value="Schwimmen">Schwimmen</option>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <select
                      class="form-select top-spacer-10"
                      style="width: 100%; overflow: hidden; text-overflow: ellipsis"
                      v-bind:value="levelFilter"
                      v-on:input="levelFilter = $event.target.value"
                    >
                      <option value="all">Alle Level</option>
                      <option value="Anfänger">Anfänger</option>
                      <option value="Fortgeschritten">Fortgeschritten</option>
                      <option value="Profi">Profi</option>
                    </select>
                  </div>
                </div>
                <div
                  class="styled-table extra-styled-table top-spacer-15 colored-row"
                  style="border: 1px solid #ddd; text-align: left"
                >
                  <div class="row" v-for="(trainingplan, index) in filteredTrainingplans" :key="trainingplan._id">
                    <div class="col-xl-2 col-lg-3 col-lg-3 col-12">
                      <h4 style="margin-top: 0.5rem">
                        <span style="margin-right: 5px">
                          <span v-if="trainingplan.sport == 'Kraft'"
                            ><img src="@/assets/images/strength.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Laufen'"
                            ><img src="@/assets/images/run.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Schwimmen'"
                            ><img src="@/assets/images/swim.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Radfahren'"
                            ><img src="@/assets/images/bike.png" style="width: 22px"
                          /></span>
                          <span v-if="trainingplan.sport == 'Triathlon'"
                            ><img src="@/assets/images/tri.png" style="width: 22px"
                          /></span>
                        </span>
                        {{ trainingplan.volume }}<br />
                      </h4>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-12">
                      <h4 style="margin-top: 0.5rem">
                        <strong
                          ><a :href="'/#/training/vorschau/' + trainingplan._id">{{ trainingplan.title }}</a></strong
                        ><br />
                        <small
                          >⌀
                          {{
                            $moment
                              .utc()
                              .startOf("day")
                              .add(trainingplan.averageWeeklyTrainingHours * 60, "minutes")
                              .format("HH[h]mm")
                          }}
                          pro Woche
                        </small>
                      </h4>
                    </div>
                    <!--                    <div class="col-xl-2 col-lg-3 col-12">
                      <h4 style="margin-top: 0.5rem">
                        {{ trainingplan.volume }}
                      </h4>
                    </div>-->
                    <div class="col-xl-2 col-lg-6 col-6">
                      <a :href="'/#/training/vorschau/' + trainingplan._id"
                        ><button style="width: 100%; min-width: 80px">Ansehen</button></a
                      >
                    </div>
                    <div class="col-xl-2 col-lg-6 col-6">
                      <button style="width: 100%; min-width: 80px" class="closer" @click="toggleShowApplyPlan(index)">
                        Verwenden
                      </button>
                    </div>
                    <div
                      class="col-lg-12 top-spacer-15"
                      style="background: #eee; padding-top: 15px"
                      v-if="showApplyPlan[index]"
                    >
                      <div class="row">
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <select style="width: 100%" v-model="applyPlanMode[index]">
                            <option selected value="begin">Beginn am</option>
                            <option value="end">Ende am</option>
                          </select>
                        </div>
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <input
                            style="width: 100%"
                            type="date"
                            value="TT.MM.JJJJ"
                            placeholder="TT.MM.JJJJ"
                            v-model="applyPlanDate[index]"
                          />
                        </div>
                        <div class="col-lg-4" style="margin-bottom: 10px">
                          <button
                            href="button-b"
                            @click="
                              insertPlan(trainingplan._id, index);
                              toggleShowApplyPlan(index);
                            "
                          >
                            Plan einfügen
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 d-block d-md-none top-spacer-25"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="top-spacer-50"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    title: "Trainingspläne",
  },
  name: "Trainingspläne",
  components: {},
  props: {
    msg: String,
  },
  data: function () {
    return {
      planFilter: "",
      show_vorbereiten_video: false,
      show_angehen_video: false,
      show_niveau_video: false,
      show_wechseln: false,
      show_mehr_plaene: false,
      show_trainingslager: false,
      show_trainingslager_richtig: false,
      show_tp_anwenden: false,
      show_reihenfolge: false,
      loaded: false,
      activeTrainingplans: [],
      showApplyPlan: {},
      applyPlanMode: {},
      applyPlanDate: {},
      sessionId: undefined,
      trainingplans: [],
      filteredTrainingplans: [],

      warnPremiumExpiration: false,
      sportFilter: "all",
      levelFilter: "all",
    };
  },
  methods: {
    toggleShowApplyPlan(index) {
      this.$set(this.showApplyPlan, index, !this.showApplyPlan[index]);
      this.$set(this.applyPlanDate, index, this.$moment().format("YYYY-MM-DD"));
    },

    async insertPlan(planId, index) {
      const date = this.$moment(this.applyPlanDate[index]);
      if (date.isValid) {
        const _self = this;
        const req = {
          id: planId,
          applyPlanMode: this.applyPlanMode[index],
          date: date,
        };
        axios
          .post(this.$host + "/trainingsplan/activate", req)
          .then(async function () {
            _self.loadActiveTrainingplan();
            _self.$scrollToTop();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        // ERR[index] = >sdjakda
      }
    },

    goToPlan() {
      var element = this.$refs["plan"];
      var top = element.offsetTop;

      window.scrollTo({ left: 0, top: top, behavior: "smooth" });
    },

    async deactivatePlan(planId) {
      const _self = this;

      axios
        .delete(this.$host + "/trainingsplan/deactivate/" + planId)
        .then(async function () {
          _self.loadActiveTrainingplan();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async loadActiveTrainingplan() {
      this.loaded = false;

      this.activeTrainingplans = await (await axios.get(this.$host + "/trainingsplan/list/user")).data;

      this.loaded = true;
    },
  },
  watch: {
    sportFilter() {
      const _self = this;
      this.filteredTrainingplans = this.trainingplans.filter((tp) => {
        return (
          (tp.sport == _self.sportFilter || _self.sportFilter == "all") &&
          (tp.volume == _self.levelFilter || _self.levelFilter == "all")
        );
      });
    },
    planFilter() {
      const _self = this;
      this.filteredTrainingplans = this.trainingplans.filter((tp) => {
        return (
          _self.planFilter.length == 0 ||
          (tp.title.toUpperCase().indexOf(_self.planFilter.toUpperCase()) !== -1 &&
            (tp.title.toUpperCase().indexOf("trainingslager".toUpperCase()) === -1 ||
              _self.planFilter == "trainingslager")) ||
          (_self.planFilter == "trainingslager" &&
            _self.sportFilter == tp.sport &&
            tp.title.toUpperCase().indexOf("olymp".toUpperCase()) === -1 &&
            tp.title.toUpperCase().indexOf("mittel".toUpperCase()) === -1 &&
            tp.title.toUpperCase().indexOf("lang".toUpperCase()) === -1)
        );
      });
    },
    levelFilter() {
      const _self = this;

      this.filteredTrainingplans = this.trainingplans.filter((tp) => {
        return (
          (tp.sport == _self.sportFilter || _self.sportFilter == "all") &&
          (tp.volume == _self.levelFilter || _self.levelFilter == "all")
        );
      });
    },
  },
  mounted: async function () {
    this.loaded = false;

    let user = (await axios.get(this.$host + "/user/me")).data;

    if (
      !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
      !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
    ) {
      this.warnPremiumExpiration = true;
    }

    this.trainingplans = await (await axios.get(this.$host + "/trainingsplan/list_active")).data;

    this.filteredTrainingplans = this.trainingplans;

    this.loadActiveTrainingplan();
  },

  created: async function () {
    // Für Trainingsplaene select auf Beginnt am stellen
    // ....wenn es mehr als 30 gibt erhöhen
    for (let i = 0; i < 30; i++) {
      this.applyPlanMode[i] = "begin";
    }
  },
  destroyed() {
    //window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.button-b {
  padding: 5px 15px;
  background: #000;
  color: #fff;
  border-radius: 5px;
}
</style>
