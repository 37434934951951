function convertToFitJson(workoutData = {}) {
  console.log("ConvertToFitJson sport", workoutData.sport);

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  function alignData(workoutData) {
    console.log("Align Data", workoutData.timestamps.length);
    if (workoutData.timestamps && workoutData.timestamps.length > 0) {
      const workoutStartTime = new Date(workoutData.timestamps[0]).getTime();
      let totalDistance = 0;
      return workoutData.timestamps.map((timestamp, index) => {
        const recordTime = new Date(timestamp).getTime();
        if (index > 0 && workoutData.route[index] && workoutData.route[index - 1]) {
          totalDistance += haversineDistance(
            workoutData.route[index - 1].latitude,
            workoutData.route[index - 1].longitude,
            workoutData.route[index].latitude,
            workoutData.route[index].longitude
          );
        }
        const timer = Math.round((recordTime - workoutStartTime) / 1000);
        return {
          timestamp: timestamp,
          timer_time: timer,
          elapsed_time: timer,

          distance: totalDistance, // Accumulated distance in km
          speed: workoutData.speed[index] || 0,
          heart_rate: workoutData.heartRate[index] || 0,
          power: workoutData.power[index] || 0,
          cadence: workoutData.cadence[index] || 0,
          position_lat: workoutData.route[index] ? workoutData.route[index].latitude : 0,
          position_long: workoutData.route[index] ? workoutData.route[index].longitude : 0,
          altitude: workoutData.route[index] ? workoutData.route[index].altitude : 0,
        };
      });
    }
  }

  function computeAverage(dataArray) {
    if (!dataArray.length) return 0;
    return dataArray.reduce((acc, curr) => acc + curr, 0) / dataArray.length;
  }

  function computeMax(dataArray) {
    if (dataArray.length === 0) return 0;

    let max = dataArray[0]; // Start with the first element as the max value
    for (let i = 1; i < dataArray.length; i++) {
      if (dataArray[i] > max) {
        max = dataArray[i]; // Update max if current element is greater
      }
    }
    return max;
  }
  function computeAscentAndDescent(route) {
    let totalAscent = 0,
      totalDescent = 0;
    route.forEach((point, index) => {
      if (index > 0) {
        let altitudeChange = point.altitude - route[index - 1].altitude;
        if (altitudeChange > 0) totalAscent += altitudeChange;
        if (altitudeChange < 0) totalDescent += -altitudeChange;
      }
    });
    return { totalAscent, totalDescent };
  }

  const { totalAscent, totalDescent } = computeAscentAndDescent(workoutData.route || []);

  const lap = {
    timestamp: new Date(workoutData.startTime).toISOString(),
    start_time: workoutData.startTime,
    avg_power: Math.round(computeAverage(workoutData.power)),
    max_power: computeMax(workoutData.power),
    total_calories: Math.round(computeAverage(workoutData.power) * 3.6 * (workoutData.duration / 3600)),
    total_timer_time: workoutData.duration,
    total_elapsed_time: workoutData.duration,
    start_position_lat: workoutData.route[0] ? workoutData.route[0].latitude : 0,
    start_position_long: workoutData.route[0] ? workoutData.route[0].longitude : 0,
    end_position_lat: workoutData.route[workoutData.route.length - 1]
      ? workoutData.route[workoutData.route.length - 1].latitude
      : 0,
    end_position_long: workoutData.route[workoutData.route.length - 1]
      ? workoutData.route[workoutData.route.length - 1].longitude
      : 0,
    total_distance: workoutData.distance / 1000,
    total_cycles: 0,
    total_fat_calories: 0,
    avg_speed: computeAverage(workoutData.speed),
    total_ascent: totalAscent,
    total_descent: totalDescent,
    event: "lap",
    event_type: "stop",
    avg_heart_rate: Math.round(computeAverage(workoutData.heartRate)),
    max_heart_rate: computeMax(workoutData.heartRate),
    avg_cadence: Math.round(computeAverage(workoutData.cadence)),
    max_cadence: computeMax(workoutData.cadence),
    intensity: "active",
    lap_trigger: "manual",
    event_group: 0,
    records: alignData(workoutData),
  };

  let session = {
    timestamp: new Date(workoutData.startTime).toISOString(),
    start_time: workoutData.startTime,
    total_elapsed_time: workoutData.duration,
    total_timer_time: workoutData.duration,
    total_distance: workoutData.distance / 1000,
    total_calories: workoutData.caloriesBurned,
    avg_speed: computeAverage(workoutData.speed),
    max_speed: computeMax(workoutData.speed),
    avg_heart_rate: computeAverage(workoutData.heartRate),
    max_heart_rate: computeMax(workoutData.heartRate),
    avg_cadence: computeAverage(workoutData.cadence),
    max_cadence: computeMax(workoutData.cadence),
    avg_power: computeAverage(workoutData.power),
    max_power: computeMax(workoutData.power),
    sport: workoutData.sport,
    sub_sport: "generic",
    laps: [lap],
  };

  return {
    file_id: {
      manufacturer: "Apple",
      product: "Apple Watch",
      time_created: new Date().toISOString(),
    },
    activity: {
      timestamp: new Date(workoutData.startTime).toISOString(),
      local_timestamp: new Date(workoutData.startTime).toISOString(),
      sessions: [session],
    },
  };
}

module.exports = convertToFitJson;
