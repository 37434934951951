<template>
  <div>
    <canvas ref="stressFormGraph" id="stressFormGraph" width="100%"></canvas>
    <div class="top-spacer-25" style="font-weight: bold" v-if="currentVal && showCurrentVal">
      <div v-if="currentVal < -2">
        {{ $t("In den letzten 10 Tagen war deine Belastung im Schnitt geringer als gewöhnlich") }}
      </div>
      <div v-if="currentVal >= -2 && currentVal <= 15">
        {{ $t("Du befindest dich im optimalen Bereich") }}
      </div>
      <div v-if="currentVal > 15 && currentVal <= 30">
        {{ $t("Du befindest dich in einer größeren Belastungssteigerung") }}
      </div>
      <div v-if="currentVal > 30">
        {{ $t("Achte darauf, dich nicht zu Überlasten und plane Erholungsphasen ein") }}
      </div>
      <div v-if="lastVal && lastVal > fitnessVal">
        {{ $t("Deine heutige Belastung war höher als gewöhnlich") }}
      </div>
      <div v-if="lastVal && lastVal < fitnessVal">
        {{ $t("Deine heutige Belastung war geringer als gewöhnlich") }}
      </div>
      <div v-if="lastVal && lastVal == fitnessVal">
        {{ $t("Deine heutige Belastung war wie üblich") }}
      </div>
      <div v-if="!lastVal">
        {{ $t("Heute noch kein Training absolviert") }}
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import moment from "moment-timezone";
import axios from "axios";

export default {
  props: ["fromDay", "until", "done", "showCurrentVal"],

  data: function () {
    return {
      // Gehe 42 Tage zurück, für 42 / 10 Tage Load
      from: moment(this.fromDay).add(-42, "d").startOf("d"),
      workouts: [],
      loadedWeeks: this.weeks,
      loadOnlydone: this.done,

      currentVal: false,
      fitnessVal: 0,

      lastVal: 0,
      smallSummaries: [],

      days: [1, 2, 3, 4, 5, 6, 7],

      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Belastung",

            data: [],
            yAxisID: "y",
            borderWidth: 3,
            fill: false,
            order: 1,
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },

        interaction: {
          intersect: false,
          mode: "index",
        },
        hover: {
          mode: "index",
          intersect: false,
          position: "nearest",
          point: {
            radius: 0,
          },
        },
        tooltips: {
          backgroundColor: "#fff",
          titleFontColor: "#000",
          bodyFontColor: "#000",
          mode: "index",
          intersect: false,
          enabled: true,
          position: "average",
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },

          annotation: {
            drawTime: "beforeDatasetsDraw",

            annotations: {
              eins: {
                id: "low-box-overtraining",
                type: "box",
                xScaleID: "xAxes",
                yScaleID: "y",
                yMax: 75,
                yMin: 30,
                backgroundColor: "#dc3545",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },
              zwei: {
                id: "low-box-optimal",
                type: "box",
                xScaleID: "xAxes",
                yScaleID: "y",
                yMin: 1,
                yMax: 30,
                backgroundColor: "#28a745",
                borderColor: "transparent",
                borderWidth: 0,
              },

              drei: {
                id: "low-box-recovery",
                type: "box",
                xScaleID: "xAxes",
                yScaleID: "y",
                yMin: -50,

                yMax: 1,
                backgroundColor: "lightgray",
                borderColor: "transparent",
                border: 0,
                borderWidth: 0,
              },

              /* {
            id: 'maxLine',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'stack',
            value: '25',
            borderColor: 'black',
            borderWidth: 2,
            label: {
              enabled: true,
              position: 'center',
              content: '' // 25...
            }
          }*/
            },
          },
        },

        scales: {
          y: {
            stacked: false,
            position: "right",
            display: false,

            ticks: {
              beginAtZero: false,
              maxTicksLimit: 40,
            },
            grid: {
              display: false,
            },
          },

          xAxes: {
            gridLines: {
              display: false,
            },
            grid: {
              display: false,
            },
            stacked: true,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
          },
        },

        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  async mounted() {
    let ctx = this.$refs.stressFormGraph.getContext("2d");
    const _self = this;

    this.smallSummaries = axios
      .get(
        this.$host +
          "/workout/" +
          "from/" +
          _self.$moment(_self.from) +
          "/until/" +
          _self.$moment(_self.until) +
          "/done"
      )
      .then(function (res) {
        _self.smallSummaries = res.data;
        _self.workouts = _self.done;

        _self.computeChartData();
        const config = {
          type: "line",
          data: _self.chartdata,
          options: _self.options,
          plugins: [
            {
              afterDraw: (chart) => {
                if (chart.tooltip?._active?.length) {
                  let x = chart.tooltip._active[0].element.x;
                  let yAxis = chart.scales.y;

                  let ctx = chart.ctx;
                  ctx.save();

                  if (yAxis) {
                    ctx.beginPath();
                    ctx.moveTo(x, yAxis.top);
                    ctx.lineTo(x, yAxis.bottom);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
                    ctx.stroke();
                  }
                }
              },
            },
          ],
        };

        new Chart(ctx, config);

        _self.$emit("chartRendered");
      })
      .catch(function (err) {
        console.log("ERR", err);
      });
  },

  methods: {
    formatDate: function (value) {
      return value.format("DD.MM");
    },
    getDayOfWeek: function (week, day) {
      return this.$moment()
        .startOf("isoWeek")
        .add(week, "w")
        .add(day - 1, "d");
    },

    totalWorkoutLength(workoutFile) {
      let length = workoutFile.reduce(function (prev, cur) {
        return (
          prev +
          (cur.Duration > 0 ? cur.Duration : 0) +
          (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0)
        );
      }, 0);
      return length;
    },

    computeAvg(arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      return sum / arr.length || 0;
    },

    computeWeightedAvg(arr) {
      const sum = arr.reduce((a, b, index) => a + b * (1 + index / arr.length), 0);
      return sum / arr.length || 0;
    },

    computeChartData: function () {
      // `this` points to the vm instance
      let smoothed = [];
      let smoothed42 = [];
      this.chartdata.datasets[0].borderColor = [];
      this.chartdata.datasets[0].tension = 0.4;

      this.chartdata.datasets[0].backgroundColor = [];
      while (this.from.isSameOrBefore(this.until, "day")) {
        let tomorrow = this.$moment(this.from).startOf("d");
        tomorrow.add(1, "d");

        let workoutsOfDate = this.smallSummaries.filter((workout) => {
          return (
            this.$moment(workout.date).isSameOrAfter(this.from) &&
            this.$moment(workout.date).isBefore(this.$moment(tomorrow))
          );
        });

        let durationSwim = 0;
        let durationBike = 0;
        let durationRun = 0;
        let durationKraft = 0;

        workoutsOfDate.forEach((workout) => {
          if (workout.sport == "Laufen") {
            durationRun += workout.pss;
          }
          if (workout.sport == "Radfahren") {
            durationBike += workout.pss;
          }
          if (workout.sport == "Schwimmen") {
            durationSwim += workout.pss;
          }
          if (workout.sport == "Kraft") {
            durationKraft += workout.pss;
          }
        });

        smoothed.push(durationRun + durationBike + durationSwim + durationKraft);
        smoothed42.push(durationRun + durationBike + durationSwim + durationKraft);

        if (smoothed.length > 10) {
          smoothed.shift();
        }
        if (smoothed42.length > 42) {
          smoothed42.shift();
        }
        // TODO: Dates in der Zukunft in eigenes dataset mit leich anderen Farben!
        // TODO: In der Vergangenheit nur duchgeführte Workouts

        if (this.from.isSameOrAfter(this.fromDay)) {
          this.chartdata.labels.push(this.formatDate(this.from));
          let rampValue = this.computeWeightedAvg(smoothed) - this.computeWeightedAvg(smoothed42);

          if (this.$moment(this.from).isSameOrBefore(this.$moment())) {
            this.chartdata.datasets[0].data.push(Math.round(rampValue));
            this.chartdata.datasets[0].borderColor.push("#000");
            this.chartdata.datasets[0].backgroundColor.push("#000");
          }

          this.fitnessVal = Math.round(this.computeWeightedAvg(smoothed42));
        }

        this.currentVal = false;

        this.lastVal = smoothed[smoothed.length - 1];

        this.currentVal = this.chartdata.datasets[0].data[this.chartdata.datasets[0].data.length - 1];

        this.from = tomorrow;
      }

      return this.chartdata;
    },
  },
};
</script>
