<template>
  <div>
    <div @click="$router.back()" v-if="$window.history.length" class="back-button" href="/#/esszimmer">
      <!-- href anpassen??? -->
      ➜
    </div>

    <div>
      <div class="row">
        <div class="submenu top-spacer-15 col-xl-2 d-none d-xl-block">
          <div class="top-spacer-25 sticky-top width-wrap">
            <div class="row">
              <div class="col-lg-12">
                <a href="/#/auswertung/workouts">
                  <div class="menu-entry active_submenu">
                    {{ $t("Einzelne Einheiten") }}
                  </div> </a
                ><br />
                <div
                  v-if="previousWorkout"
                  style="
                    text-align: left;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                  "
                >
                  <div v-for="wo in previousWorkout" :key="'b' + wo._id">
                    <a v-if="wo.workout" v-bind:href="'/#/auswertung/workout/details/' + wo.workout._id">
                      {{ $moment(wo.workout.date).format("DD.MM.YYYY") }}
                      {{ wo.workout.name }}
                    </a>
                  </div>
                </div>

                <div
                  style="
                    overflow: hidden;

                    color: #000;
                    font-weight: bold;
                    text-align: left;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    white-space: nowrap;
                  "
                >
                  {{ $moment(workout.date).format("DD.MM.YYYY") }}
                  {{ workout.name }}
                </div>
                <div
                  v-if="nextWorkout"
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: left;
                    text-overflow: ellipsis;
                    max-width: 100%;
                  "
                >
                  <div v-for="wo in nextWorkout" :key="'c' + wo._id">
                    <a v-if="wo.workout" v-bind:href="'/#/auswertung/workout/details/' + wo.workout._id">
                      {{ $moment(wo.workout.date).format("DD.MM.YYYY") }}
                      {{ wo.workout.name }}
                    </a>
                  </div>
                </div>
                <br />
                <br />
              </div>
              <div class="col-lg-12">
                <a href="/#/auswertung/uebersicht">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'uebersicht'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                        : ''
                    "
                  >
                    {{ $t("Übersicht") }}
                  </div>
                </a>
              </div>

              <div class="col-lg-12">
                <a href="/#/auswertung/belastung">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'belastung'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                        : ''
                    "
                  >
                    {{ $t("Belastung & Fitness") }}
                  </div></a
                >
              </div>
              <div class="col-lg-12">
                <a href="/#/auswertung/zonen">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'zonen'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                        : ''
                    "
                  >
                    {{ $t("Zeit in Zonen") }}
                  </div></a
                >
              </div>
              <div class="col-lg-12">
                <a :href="'/#/auswertung/substratverbrauch'">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'substratverbrauch'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#fff;border-radius:10px'
                        : ''
                    "
                  >
                    {{ $t("Substratverbrauch") }}
                  </div></a
                >
              </div>

              <div class="col-lg-12">
                <a href="/#/auswertung/vergleiche">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'vergleiche'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                        : ''
                    "
                  >
                    {{ $t("Leistungen/HR") }}
                  </div></a
                >
              </div>

              <div class="col-lg-12">
                <a href="/#/auswertung/leistungskurven">
                  <div
                    class="menu-entry"
                    :style="
                      active_menu == 'leistungskurven'
                        ? 'background:rgb(100, 149, 237) none repeat scroll 0% 0%;color:#000;border-radius:5px'
                        : ''
                    "
                  >
                    {{ $t("Leistungskurven") }}
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-10" id="auswertung">
          <div class="spinner-border text-primary top-spacer-75" role="status" v-if="!summary"></div>
          <div v-if="summary">
            <div class="row">
              <div class="col-md-1 col-2 top-spacer-50">
                <a
                  v-if="
                    previousWorkout &&
                    previousWorkout[previousWorkout.length - 1] &&
                    previousWorkout[previousWorkout.length - 1].workout
                  "
                  v-bind:href="
                    '/#/auswertung/workout/details/' + previousWorkout[previousWorkout.length - 1].workout._id
                  "
                >
                  <img src="@/assets/images/back.png" style="width: 40px; margin-top: 15px; cursor: pointer" />
                </a>
              </div>
              <div class="col-md-4 col-8">
                <div class="top-spacer-60"></div>

                <h4 style="text-align: center">
                  <strong>{{ workout.name }}</strong>
                </h4>

                <h4>
                  {{ workout.sport }} -
                  {{ $moment(workout.date).format("DD.MM.YYYY") }}
                </h4>
              </div>
              <div class="col-md-1 col-2 top-spacer-50">
                <a
                  v-if="nextWorkout && nextWorkout[0] && nextWorkout[0].workout"
                  v-bind:href="'/#/auswertung/workout/details/' + nextWorkout[0].workout._id"
                >
                  <img src="@/assets/images/next.png" style="width: 40px; margin-top: 15px; cursor: pointer" />
                </a>
              </div>

              <div class="col-md-3">
                <div class="top-spacer-50 d-none d-md-block"></div>

                <div class="row" style="text-align: center">
                  <div class="col-6 top-spacer-10">
                    <h4>
                      <small>{{ $t("Dauer") }}</small
                      ><br />{{ formattedTime(summary.total_timer_time) }}
                    </h4>
                  </div>
                  <div class="col-6 top-spacer-10" v-if="workout.pss">
                    <h4>
                      <small>{{ $t("Load") }}</small
                      ><br />{{ workout.pss }}
                    </h4>
                  </div>
                  <!--
                  <div class="col-4" v-if="summary && summary.tss">
                    <h4>
                      <small>cTSS</small><br />
                      <span>{{ Math.round(summary.tss) }}</span>
                    </h4>
                  </div>-->
                </div>
              </div>
              <div class="col-md-3">
                <div class="top-spacer-15 d-block d-md-none"></div>

                <div class="top-spacer-50 d-none d-md-block"></div>
                <h4>
                  <small>{{ $t("Intensitätsverteilung") }}</small>
                </h4>
                <div style="padding: 1px; border-radius: 5px">
                  <div class="progress" style="height: 14px">
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      v-bind:style="
                        'width:' +
                        timePercentage(workout.time_lit, workout.time_hit + workout.time_mit + workout.time_lit) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(workout.time_lit, workout.time_hit + workout.time_mit + workout.time_lit)
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ timePercentage(workout.time_lit, workout.time_hit + workout.time_mit + workout.time_lit) }}%
                    </div>
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      v-bind:style="
                        'background-color: rgb(232, 126, 4)!important;' +
                        'width:' +
                        timePercentage(workout.time_mit, workout.time_hit + workout.time_mit + workout.time_lit) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(workout.time_mit, workout.time_hit + workout.time_mit + workout.time_lit)
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ timePercentage(workout.time_mit, workout.time_hit + workout.time_mit + workout.time_lit) }}%
                    </div>
                    <div
                      class="progress-bar bg-danger"
                      role="progressbar"
                      v-bind:style="
                        'width:' +
                        timePercentage(workout.time_hit, workout.time_hit + workout.time_mit + workout.time_lit) +
                        '%'
                      "
                      v-bind:aria-valuenow="
                        timePercentage(workout.time_hit, workout.time_hit + workout.time_mit + workout.time_lit)
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {{ timePercentage(workout.time_hit, workout.time_hit + workout.time_mit + workout.time_lit) }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                details &&
                thresholds &&
                details.sessions &&
                details.sessions.length > 0 &&
                details.sessions[0].laps.length > 0
              "
              class="col-12 col-md-none top-spacer-25"
            ></div>
            <div
              class="spinner-border text-primary top-spacer-25"
              role="status"
              v-if="!(details && details.sessions) || loading"
            ></div>
            <div
              class="row"
              v-if="
                details &&
                thresholds &&
                details.sessions &&
                details.sessions.length > 0 &&
                details.sessions[0].laps.length > 0
              "
            >
              <div class="col-md-12 col-xs-12">
                <!--<workout-analysis-chart
                @hover-analysis-chart="updateChartPositionData"
                @click-analysis-chart="clickChartPositionData"
                :x1marker="
                  clicked_point_index_1 &&
                  (clicked_point_index_2 || clicked_point_hover)
                    ? clicked_point_index_1
                    : 0
                "
                :x2marker="
                  clicked_point_index_1 &&
                  (clicked_point_index_2 || clicked_point_hover)
                    ? clicked_point_index_2 || clicked_point_hover
                    : 0
                "
                class="top-spacer-25"
                v-if="
                  details.sessions &&
                  (details.sessions[summary.session_index || 0].avg_speed >
                    0 ||
                    details.sessions[summary.session_index || 0]
                      .enhanced_avg_speed > 0 ||
                    details.sessions[summary.session_index || 0]
                      .avg_heart_rate > 0 ||
                    details.sessions[summary.session_index || 0]
                      .avg_power > 0)
                "
                style="height: 200px; margin-left: -10px"
                v-bind:sessions="details.sessions"
                v-bind:sport="workout.sport"
                :key="
                  '1x' +
                  key +
                  details.length +
                  clicked_point_index_1 +
                  clicked_point_index_2
                "
              />-->

                <wo-analysis
                  v-if="
                    details &&
                    thresholds &&
                    workout.sport != 'Schwimmen' &&
                    thresholds.length > 0 &&
                    details.sessions &&
                    details.sessions.length > 0 &&
                    details.sessions[0].laps.length > 0
                  "
                  :x1marker="
                    clicked_point_index_1 && (clicked_point_index_2 || clicked_point_hover) ? clicked_point_index_1 : 0
                  "
                  :x2marker="
                    clicked_point_index_1 && (clicked_point_index_2 || clicked_point_hover)
                      ? clicked_point_index_2 || clicked_point_hover
                      : 0
                  "
                  @hover-analysis-chart="updateChartPositionData"
                  @click-analysis-chart="clickChartPositionData"
                  v-bind:session="details.sessions[summary.session_index || 0]"
                  v-bind:thresholds="thresholds"
                  v-bind:sport="workout.sport"
                  :key="'x1x' + details.length"
                />

                <swim-analysis
                  v-if="
                    workout.sport == 'Schwimmen' &&
                    workout.source != 'strava' &&
                    details &&
                    thresholds &&
                    details.sessions &&
                    details.sessions.length > 0 &&
                    details.sessions[0].laps.length > 0
                  "
                  v-bind:session="details.sessions[summary.session_index || 0]"
                  v-bind:thresholds="thresholds"
                  v-bind:sport="workout.sport"
                  :key="'x1Y2' + details.length"
                />

                <div class="row top-spacer-25" v-if="clicked_point_index_1 && clicked_point_index_2">
                  <div class="col-md-1"></div>
                  <div class="col-md-1"><h4>Segment</h4></div>

                  <div class="col-4 col-md-2">
                    <strong>{{ $t("Dauer") }}</strong
                    ><br />
                    {{ formattedTime(segment_time_end - segment_time_start) }}
                  </div>
                  <div class="col-4 col-md-2" v-if="workout.sport == 'Laufen' || workout.sport == 'Radfahren'">
                    <strong>{{ $t("Geschwindigkeit") }}</strong
                    ><br />
                    {{ segment_speed }}km/h
                    <div v-if="workout.sport == 'Laufen'">
                      {{ $moment.utc((3600 / segment_speed) * 1000).format("mm:ss") }}min/km
                    </div>
                  </div>

                  <div class="col-4 col-md-2">
                    <strong style="color: red">{{ $t("Herzfrequenz") }}</strong
                    ><br />
                    {{ segment_hr || 0 }}bpm
                  </div>
                  <div class="col-12 top-spacer-25 d-block d-md-none"></div>

                  <div class="col-4 col-md-2" v-if="workout.sport == 'Laufen' || workout.sport == 'Radfahren'">
                    <strong v-if="workout.sport != 'Schwimmen'" style="color: green">{{ $t("Kadenz") }}</strong>
                    <strong v-if="workout.sport == 'Schwimmen'" style="color: green">{{ $t("Züge") }}</strong>

                    <br />
                    <span> {{ segment_cadence || 0 }}</span>
                  </div>
                  <div class="col-4 col-md-2" v-if="workout.sport == 'Radfahren'">
                    <strong style="color: blue">{{ $t("Leistung") }}</strong
                    ><br />
                    {{ segment_power || 0 }}w
                  </div>
                  <br />
                </div>
                <br />
              </div>
              <!--
            <div class="col-lg-2">
              <div
                class="row"
                v-if="
                  positionChartData.time && positionChartData.time != '0:00:00'
                "
              >
                <div class="col-12 top-spacer-15">
                  <strong>{{$t("Zeit"}}</strong><br />
                  {{ positionChartData.time }}
                </div>
                <div
                  class="col-12 top-spacer-15"
                  v-if="
                    workout.sport == 'Laufen' || workout.sport == 'Radfahren'
                  "
                >
                  <strong>{{$t("Geschwindigkeit"}}</strong><br />
                  {{ positionChartData.speed || 0 }}km/h
                </div>

                <div class="col-12 top-spacer-15">
                  <strong style="color: red">{{$t("Herzfrequenz")}}</strong><br />
                  {{ positionChartData.hr || 0 }}bpm
                </div>
                <div class="col-12 top-spacer-25 d-block d-md-none"></div>

                <div
                  class="col-12 top-spacer-15"
                  v-if="
                    workout.sport == 'Laufen' || workout.sport == 'Radfahren'
                  "
                >
                  <strong v-if="workout.sport == 'Laufen'" style="color: green"
                    >{{$t("Schrittfrequenz")}}</strong
                  >
                  <strong
                    v-if="workout.sport == 'Schwimmen'"
                    style="color: green"
                    >{{$t("Züge") }}</strong
                  >
                  <strong
                    v-if="workout.sport == 'Radfahren'"
                    style="color: green"
                    >{{$t("Trittfrequenz")}}</strong
                  >
                  <strong
                    v-if="
                      !workout.sport == 'Radfahren' &&
                      !workout.sport != 'Laufen'
                    "
                    >{{$t("Frequenz")}}</strong
                  >

                  <br />

                  {{ positionChartData.cadence || 0 }}
                </div>
                <div
                  class="col-12 top-spacer-15"
                  v-if="workout.sport == 'Radfahren'"
                >
                  <strong style="color: blue">{{$t("Leistung")}}</strong><br />
                  {{ positionChartData.power || 0 }}w
                </div>
                <div class="col-xs-4 col-md-2">
                  <strong>{{$t("Intensität")}}</strong><br />
                  HIT/LIT?
               </div>
              </div>
            </div>-->
              <div class="col-lg-12 top-spacer-15">
                <div v-show="latlngs && latlngs.length > 0" class="light-shadow">
                  <LMap
                    @ready="mapLoaded"
                    style="height: 350px; border-radius: 10px"
                    scrollWheelZoom="disable"
                    :key="
                      'bm' +
                      latlngs[0] +
                      latlngs[1] +
                      latlngs[latlngs.length - 1] +
                      latlngs[latlngs.length - 2] +
                      segmentPath.length
                    "
                    ref="map"
                    :options="{ scrollWheelZoom: false }"
                    :center="latlngs[Math.ceil(latlngs.length / 4)]"
                  >
                    <LTileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution="&copy <a target='_blank' href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    />
                    <!-- Im Chart hover point auf karte markieren!  -->

                    <!-- <v-hotline :latlngs="latlngs" :min="0" :max="200"></v-hotline>-->
                    <LPolyline
                      :lat-lngs="latlngs"
                      :options="{
                        smoothFactor: 5,
                        color: 'blue',
                      }"
                    />

                    <LPolyline
                      :lat-lngs="segmentPath"
                      :options="{
                        smoothFactor: 5,
                        color: 'red',
                        weight: 5,
                      }"
                    />

                    <LCircle
                      :lat-lng="hoverLatLng"
                      :options="{
                        radius: 7,
                        color: 'black',
                        fill: true,
                        fillOpacity: 1,

                        weight: 7,
                      }"
                    />
                  </LMap>
                </div>
              </div>
              <div class="col-lg-1 hidden-xs"></div>
            </div>

            <div class="col-lg-12 top-spacer-25">
              <div v-if="summary.newBestRunningTimesYear.length > 0">
                <div class="row">
                  <div
                    class="col-lg-1 col-4"
                    v-for="bestTime in summary.newBestRunningTimesYear"
                    :key="'po' + bestTime + 'bestTime'"
                  >
                    <div style="padding: 15px">
                      <i class="fa fa-trophy" aria-hidden="true" style="font-size: 20px"></i><br />
                      {{ bestTime.split("_")[4] }}m <br />
                      {{ formattedTime(summary[bestTime]) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="summary.newBestPowersYear.length > 0">
                <div class="row">
                  <div
                    class="col-lg-1 col-4"
                    v-for="bestPower in summary.newBestPowersYear"
                    :key="'bpp' + bestPower + 'bestPower'"
                    style="float: left"
                  >
                    <div style="padding: 15px">
                      <i class="fa fa-trophy" aria-hidden="true" style="font-size: 20px"></i><br />
                      {{ formattedTime(bestPower.split("_")[1]) }} <br />
                      {{ Math.round(summary[bestPower]) }}w
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div
                class="top-spacer-25"
                v-if="
                  summary.estimated_ftp &&
                  summary.estimated_ftp <
                    summary.thresholds.threshold_run + 15 &&
                  summary.estimated_ftp > summary.thresholds.threshold_run - 15
                "
              >
                Aus der
                {{ summary.bucket_time_for_ftp_estimate / 60 }}-Minuten Leistung
                kann (wenn es eine Maximalleistung war) eine
                <strong
                  >FTP von {{ Math.round(summary.estimated_ftp) }}w</strong
                >
                geschätzt werden.
              </div>-->
            </div>

            <div class="row" v-if="summary">
              <div class="col-lg-7 top-spacer-50">
                <h4>Zusammenfassung</h4>

                <div>
                  <div>
                    <div class="styled-table extra-styled-table light-shadow" style="text-align: left">
                      <div class="row">
                        <div class="col-md-4 col-6">Dauer</div>
                        <div class="col-md-2 col-6">
                          {{ formattedTime(summary.total_timer_time) }}
                        </div>
                        <div class="col-md-4 col-6">Distanz</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.total_distance * 10) / 10 }}km</div>
                      </div>

                      <div class="row">
                        <div class="col-md-4 col-6">Geschwindigkeit (∅)</div>
                        <div class="col-md-2 col-6">
                          {{ Math.round(summary.avg_speed * 10) / 10 }}km/h
                          <div v-if="workout.sport == 'Laufen'">
                            {{ $moment.utc((1000 * 3600) / (summary.avg_speed || 1)).format("mm:ss") }}min/km
                          </div>
                        </div>
                        <div class="col-md-4 col-6" scope="row">Geschwindigkeit (max)</div>
                        <div class="col-md-2 col-6">
                          {{ Math.round(summary.max_speed * 10) / 10 }}km/h
                          <div v-if="workout.sport == 'Laufen'">
                            {{ $moment.utc((1000 * 3600) / (summary.max_speed || 1)).format("mm:ss") }}min/km
                          </div>
                        </div>
                      </div>

                      <div v-if="summary.enhanced_avg_speed">
                        <div class="col-md-4 col-6" scope="row">Geschwindigkeit (∅)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.enhanced_avg_speed * 10) / 10 }}km/h</div>
                        <div class="col-md-4 col-6" scope="row">Geschwindigkeit (max)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.enhanced_max_speed * 10) / 10 }}km/h</div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-6" scope="row">Herzfrequenz (∅)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.avg_heart_rate) }}bpm</div>
                        <div class="col-md-4 col-6" scope="row">Herzfrequenz (max)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.max_heart_rate * 10) / 10 }}bpm</div>
                      </div>
                      <div v-if="summary.avg_power" class="row">
                        <div class="col-md-4 col-6" scope="row">Leistung (∅)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.avg_power * 10) / 10 }}w</div>
                        <div class="col-md-4 col-6" scope="row">Leistung (max)</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.max_power * 10) / 10 }}w</div>
                      </div>
                      <div class="row">
                        <div class="col-md-4 col-6" scope="row">Kadenz (∅)</div>
                        <div class="col-md-2 col-6" v-if="summary.avg_cadence">
                          {{ Math.round(summary.avg_cadence * 10) / 10 }}
                        </div>
                        <div class="col-md-2 col-6" v-if="!summary.avg_cadence">/</div>
                        <div class="col-md-4 col-6" scope="row">Gewichtete Leistung</div>
                        <div class="col-md-2 col-6" v-if="summary.weighted_power">
                          {{ Math.round(summary.weighted_power * 10) / 10 }}w
                        </div>
                        <div class="col-md-2 col-6" v-if="!summary.weighted_power">/</div>
                      </div>
                      <div class="row" v-if="summary.tss && summary.intensitaets_faktor">
                        <div class="col-md-4 col-6" scope="row">Trainings-Stress</div>
                        <div class="col-md-2 col-6" v-if="summary.tss">
                          {{ Math.round(summary.tss) }}
                        </div>
                        <div class="col-md-2 col-6" v-if="!summary.tss">/</div>
                        <div class="col-md-4 col-6" scope="row">Intensitäts-Faktor</div>
                        <div class="col-md-2 col-6" v-if="summary.intensitaets_faktor">
                          {{ summary.intensitaets_faktor }}
                        </div>
                        <div class="col-md-2 col-6" v-if="!summary.intensitaets_faktor">/</div>
                      </div>
                      <div class="row" v-if="summary.ascent > 0 || summary.descent">
                        <div class="col-md-4 col-6" scope="row">Anstieg</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.ascent) }}m</div>
                        <div class="col-md-4 col-6" scope="row">Abstieg</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.descent) }}m</div>
                      </div>

                      <div class="row" v-if="summary.altitude_diff_first_half && summary.altitude_diff_second_half">
                        <div class="col-md-4 col-6" scope="row">Höhendiff. 1. Hälfte</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.altitude_diff_first_half) }}m</div>
                        <div class="col-md-4 col-6" scope="row">Höhendiff. 2. Hälfte</div>
                        <div class="col-md-2 col-6">{{ Math.round(summary.altitude_diff_second_half) }}m</div>
                      </div>
                      <!--
                      <div
                        class="row"
                        v-if="
                          summary.power_or_speed_to_hr_first_half > 0 ||
                          summary.power_or_speed_to_hr_second_half
                        "
                      >
                  
                        <div class="col-md-4 col-6" scope="row">
                          Leistung/HR 1/2
                        </div>
                        <div class="col-md-2 col-6">
                          {{
                            Math.round(
                              summary.power_or_speed_to_hr_first_half * 100
                            ) / 100
                          }}
                        </div>
                        <div class="col-md-4 col-6" scope="row">
                          Leistung/HR 2/2
                        </div>
                        <div class="col-md-2 col-6">
                          {{
                            Math.round(
                              summary.power_or_speed_to_hr_second_half * 100
                            ) / 100
                          }}
                        </div>
                      </div>-->

                      <div
                        class="row"
                        v-if="
                          summary.power_or_speed_to_hr_first_half > 0 && summary.power_or_speed_to_hr_second_half > 0
                        "
                      >
                        <div class="col-md-4 col-6" scope="row">2. Hälfte zu 1. Hälfte</div>
                        <div class="col-md-2 col-6">
                          {{
                            Math.round(
                              (summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half) * 100
                            )
                          }}% /

                          <span
                            v-if="
                              summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half < 0.98
                            "
                          >
                            <span style="font-weight: bold; color: red">⇣</span>
                          </span>

                          <span
                            v-if="
                              summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half >=
                                0.98 &&
                              summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half < 1.01
                            "
                          >
                            <span style="font-weight: bold">⇢</span>
                          </span>
                          <span
                            v-if="
                              summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half >= 1.01
                            "
                          >
                            <span style="font-weight: bold; color: green">⇡</span>
                          </span>

                          {{
                            Math.round(
                              (100 -
                                (summary.power_or_speed_to_hr_second_half / summary.power_or_speed_to_hr_first_half) *
                                  100) *
                                -1
                            )
                          }}%
                        </div>
                        <div class="col-md-4 col-6" scope="row">
                          {{ $t("Kalorien (kcal)") }}
                        </div>
                        <div class="col-md-2 col-6">
                          {{ summary.total_calories }}
                        </div>
                      </div>
                      <div class="row" v-if="workout.sport == 'Schwimmen'">
                        <div class="col-md-4 col-6">Pace (∅)</div>
                        <div class="col-md-2 col-6">
                          {{ kmhToSwimPace(summary.total_timer_time, summary.total_distance) }}/100m
                        </div>
                      </div>
                      <div class="row" v-if="workout.newFtp > 0">
                        <div class="col-lg-12" style="color: #fff; background: #000; text-align: center">
                          <strong>{{ $t("Neue FTP erkannt:") }} {{ workout.newFtp }}w</strong>
                        </div>
                      </div>

                      <div class="col-lg-12" style="margin-bottom: 15px" v-if="workout.description">
                        <hr />
                        <span style="white-space: pre-wrap">{{ workout.description }}</span>
                      </div>

                      <div class="col-lg-12 top-spacer-25" style="margin-bottom: 15px" v-if="workout.done_description">
                        <hr />
                        <span style="white-space: pre-wrap">{{ workout.done_description }}</span>
                      </div>
                    </div>
                    <div v-if="workout.backlink" class="top-spacer-10">
                      <a
                        target="_blank"
                        style="text-decoration: underline !important; color: #fc4c02"
                        :href="workout.backlink"
                        >{{ $t("Anzeigen auf Strava") }}</a
                      >
                    </div>
                    <!--
                    <div
                      v-if="summary.best_prediction_run"
                      class="centered top-spacer-15"
                      style="font-weight: bold"
                    >
                    {{$t("Geschätzte Laufschwelle:") }}
                      {{ summary.best_prediction_run }}km/h bzw.
                      {{
                        $moment
                          .utc(
                            Math.round(
                              (3600 / summary.best_prediction_run) * 1000
                            )
                          )
                          .format("mm:ss")
                      }}m/km
                    </div>

                    <div
                      v-if="
                        summary.best_prediction_bike &&
                        !workout.name.includes('FTP')
                      "
                      class="centered top-spacer-15"
                      style="font-weight: bold"
                    >
                    {{$t("Geschätzte Radschwelle / FTP aus der gesamten Einheit:") }}
                      {{ summary.best_prediction_bike }}w
                    </div>-->

                    <div
                      v-if="
                        summary.avg_heart_rate &&
                        summary.avg_speed &&
                        workout.sport == 'Laufen' &&
                        details.sessions &&
                        details.sessions[summary.session_index || 0]
                      "
                    >
                      <h4 class="top-spacer-50">
                        {{ $t("Leistungsentwicklung innerhalb der Einheit") }}
                      </h4>
                      <div class="styled-table light-shadow">
                        <div class="chart-wrapper-less centered">
                          <speed-hr-workout-scatter-chart
                            v-if="details.sessions"
                            style="height: 275px"
                            :key="'y' + details.sessions[summary.session_index || 0].total_timer_time"
                            v-bind:session="details.sessions[summary.session_index || 0]"
                            v-bind:upper_limit="
                              (60 /
                                (((summary.thresholds && summary.thresholds.threshold_run) ||
                                  thresholds[thresholds.length - 1].threshold_run) /
                                  60)) *
                              1.4
                            "
                          />

                          <time-bar
                            :from="'0km/h'"
                            :until="
                              Math.round(
                                (60 /
                                  (((summary.thresholds && summary.thresholds.threshold_run) ||
                                    thresholds[thresholds.length - 1].threshold_run) /
                                    60)) *
                                  1.4
                              ) + 'km/h'
                            "
                          />

                          <p class="centered top-spacer-15">
                            <small
                              >Höhere Punkte zeigen eine höhere Geschwindigkeit pro Herzschlag an. Die Farbe
                              visualisiert die erbrachte Geschwindigkeit unabhängig von der Herzfrequenz.
                              <!--Die Trendlinie
                              beschreibt, wie sich deine Geschwindigkeit zur
                              Herzfrequenz über die gesamte Einheit verhält.
                              Eine konstante Linie bedeutet, dass du die Einheit
                              ohne Überlastung gelaufen bist. Eine stärker
                              abfallende Linie deutet auf einen zu schnellen
                              Beginn bzw. eine (zu) hohe Belastung hin.--></small
                            >
                          </p>
                        </div>
                      </div>
                      <h4 class="top-spacer-50">
                        {{ $t("Leistungverteilung innerhalb der Einheit") }}
                      </h4>
                      <div class="styled-table light-shadow">
                        <div class="chart-wrapper-less centered">
                          <speed-hr-dist-workout-scatter-chart
                            v-if="details.sessions"
                            style="height: 275px"
                            :key="'y' + details.sessions[summary.session_index || 0].total_timer_time"
                            v-bind:session="details.sessions[summary.session_index || 0]"
                            v-bind:upper_limit="
                              (60 /
                                (((summary.thresholds && summary.thresholds.threshold_run) ||
                                  thresholds[thresholds.length - 1].threshold_run) /
                                  60)) *
                              1.4
                            "
                          />

                          <time-bar
                            :from="'Anfang'"
                            :until="
                              Math.round(details.sessions[summary.session_index || 0].total_timer_time / 60) + 'min'
                            "
                          />

                          <p class="centered top-spacer-15">
                            <small>{{ $t("Geschwindigkeit zu Herzfrequenz Verteilung") }}</small>
                          </p>
                        </div>
                      </div>

                      <span v-if="smallSummaries.length > 0">
                        <h4 class="top-spacer-50">Leistungsvergleich</h4>
                        <p>
                          {{ $t("Einheiten der letzten 12 Monate vor dieser Einheit.") }}
                        </p>
                        <div class="styled-table light-shadow">
                          <div class="chart-wrapper-less centered">
                            <div
                              class="spinner-border text-primary top-spacer-50"
                              role="status"
                              v-if="scatterSpeedChartLoading"
                            ></div>
                            <speed-hr-scatter-chart
                              v-bind:done="smallSummaries"
                              @chartRendered="scatterSpeedChartRendered()"
                              style="height: 275px"
                              v-bind:reducex="reduceToSimilar"
                              v-bind:wo="{
                                time_hit: workout.time_hit,
                                time_lit: workout.time_lit,
                                time_mit: workout.time_mit,
                                avg_heart_rate: summary.avg_heart_rate,
                                avg_speed: summary.avg_speed,
                              }"
                              v-bind:fromDay="$moment(workout.date).add(-365, 'd')"
                              v-bind:until="$moment(workout.date)"
                            />

                            <time-bar
                              :from="$moment(workout.date).add(-365, 'd').format('DD.MM.YYYY')"
                              :until="$moment(workout.date).format('DD.MM.YYYY')"
                            />
                            <!-- <div class="form-check form-check-inline top-spacer-15">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="reduceToSimilar"
                          />
                          <label class="form-check-label" for="inlineCheckbox1"
                            >Nur vergleichbare</label
                          >
                        </div>-->

                            <p class="centered top-spacer-15">
                              <small
                                >Je höher ein Datenpunkt ist, desto besser war die Leistung zu der Herzfrequenz. Sind
                                die roten Punkte also über den blauen, so hast du zur jeweiligen Herzfrequenz deine
                                Leistung über den Zeitraum gesteigert. Achtung: Einheiten mit vielen Höhenmetern oder
                                Intervallen führen tendenziell zu einer schwächeren Einschätzung in dieser Grafik.
                                <br />Je größer ein Punkt, desto länger war die Einheit. Klicke auf einen Punkt, um die
                                Einheit zu öffnen.</small
                              >
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div
                      v-if="
                        summary.avg_heart_rate &&
                        summary.avg_power &&
                        workout.sport == 'Radfahren' &&
                        details.sessions &&
                        details.sessions[summary.session_index || 0]
                      "
                    >
                      <h4 class="top-spacer-50">
                        {{ $t("Leistungsentwicklung innerhalb der Einheit") }}
                      </h4>

                      <div class="styled-table light-shadow">
                        <div class="chart-wrapper-less centered">
                          <power-hr-workout-scatter-chart
                            style="height: 275px"
                            :key="details.sessions[summary.session_index || 0].total_timer_time"
                            v-bind:session="details.sessions[summary.session_index || 0]"
                            v-bind:upper_limit="
                              ((summary.thresholds && summary.thresholds.ftp) ||
                                thresholds[thresholds.length - 1].ftp) * 1.2
                            "
                          />

                          <time-bar
                            :from="'0w'"
                            :until="
                              Math.round(
                                ((summary.thresholds && summary.thresholds.ftp) ||
                                  thresholds[thresholds.length - 1].ftp) * 1.2
                              ) + 'w+'
                            "
                          />

                          <p class="centered top-spacer-15">
                            <small
                              >Höhere Punkte zeigen eine höhere Leistung pro Herzschlag an. Die Farbe visualisiert die
                              erbrachte höhe der Leistung unabhängig von der Herzfrequenz.<!-- Die Trendlinie beschreibt, wie sich
                              deine Leistung zur Herzfrequenz über die gesamte
                              Einheit verhält. Eine konstante Linie bedeutet,
                              dass du die Einheit ohne Überlastung gefahren
                              bist. Eine stärker abfallende Linie deutet auf
                              einen zu schnellen Beginn hin.--></small
                            >
                          </p>
                        </div>
                      </div>

                      <h4 class="top-spacer-50">
                        {{ $t("Leistungverteilung innerhalb der Einheit") }}
                      </h4>

                      <div class="styled-table light-shadow">
                        <div class="chart-wrapper-less centered">
                          <power-hr-dist-workout-scatter-chart
                            style="height: 275px"
                            :key="details.sessions[summary.session_index || 0].total_timer_time"
                            v-bind:session="details.sessions[summary.session_index || 0]"
                            v-bind:upper_limit="
                              ((summary.thresholds && summary.thresholds.ftp) ||
                                thresholds[thresholds.length - 1].ftp) * 1.2
                            "
                          />

                          <time-bar
                            :from="'Beginn'"
                            :until="
                              Math.round(details.sessions[summary.session_index || 0].total_timer_time / 60) + 'min'
                            "
                          />

                          <p class="centered top-spacer-15">
                            <small>{{ $t("Leistung zu Herzfrequenz Verteilung") }}</small>
                          </p>
                        </div>
                      </div>

                      <span v-if="smallSummaries.length > 0">
                        <h4 class="top-spacer-50">Leistungsvergleich</h4>
                        <p>
                          {{ $t("Einheiten der letzten 12 Monate vor dieser Einheit.") }}
                        </p>
                        <div class="styled-table light-shadow">
                          <div class="chart-wrapper-less centered">
                            <div
                              class="spinner-border text-primary top-spacer-50"
                              role="status"
                              v-if="scatterPowerChartLoading"
                            ></div>
                            <power-hr-scatter-chart
                              @chartRendered="scatterPowerChartRendered()"
                              v-bind:reducex="reduceToSimilar"
                              style="height: 275px"
                              v-bind:wo="{
                                time_hit: workout.time_hit,
                                time_lit: workout.time_lit,
                                time_mit: workout.time_mit,
                                avg_heart_rate: summary.avg_heart_rate,
                                avg_power: summary.avg_power,
                                weighted_power: summary.weighted_power,
                              }"
                              v-bind:done="smallSummaries"
                              v-bind:fromDay="$moment(workout.date).add(-365, 'd')"
                              v-bind:until="$moment(workout.date)"
                            />
                            <time-bar
                              :from="$moment(workout.date).add(-365, 'd').format('DD.MM.YYYY')"
                              :until="$moment(workout.date).format('DD.MM.YYYY')"
                            />
                            <!--  <div class="form-check form-check-inline">
                          <input
                            class="form-check-input top-spacer-15"
                            type="checkbox"
                            v-model="reduceToSimilar"
                          />
                          <label class="form-check-label" for="inlineCheckbox1"
                            >Nur vergleichbare</label
                          >
                        </div>-->
                            <p class="centered">
                              <small
                                >Je höher ein Datenpunkt ist, desto besser war die Leistung zu der Herzfrequenz. Je
                                größer ein Punkt, desto länger war die Einheit. Klicke auf einen Punkt, um die Einheit
                                zu öffnen.</small
                              >
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                    <h4
                      class="top-spacer-50"
                      v-if="
                        records &&
                        records.length > 0 &&
                        summary &&
                        summary.hr_5 > 0 &&
                        details.sessions &&
                        pctHrMax &&
                        pctHrMax.length > 0
                      "
                    >
                      {{ $t("Substratverbrauch") }} ({{ summary.total_calories }}kcal)
                    </h4>
                    <div
                      class="styled-table light-shadow"
                      v-if="
                        details &&
                        details.sessions &&
                        details.sessions.length > 0 &&
                        pctHrMax &&
                        workout &&
                        records &&
                        records.length &&
                        records.length > 0 &&
                        summary.hr_5 > 0 &&
                        workout &&
                        workout.sport
                      "
                    >
                      <div class="chart-wrapper-less centered">
                        <fat-cho-utilization
                          v-if="details.sessions && pctHrMax.length > 0"
                          v-bind:pctHrMax="pctHrMax"
                          v-bind:records="records"
                          v-bind:sport="workout.sport"
                          :key="
                            'substratx' +
                            pctHrMax +
                            records.length +
                            details.sessions[summary.session_index || 0].total_timer_time
                          "
                          style="height: 275px"
                        />
                        <div class="row top-spacer-25">
                          <div class="col-lg-3 col-6">
                            <strong>Fett%</strong><br />
                            <h4>{{ summary.fatPercentage || 50 }}%</h4>
                          </div>
                          <div class="col-lg-3 col-6">
                            <strong>KHs%</strong><br />
                            <h4>{{ summary.choPercentage || 50 }}%</h4>
                          </div>
                          <div class="col-lg-3 col-6">
                            <strong>Fett</strong><br />
                            <h4>
                              {{ Math.round(((summary.fatPercentage || 50) / 100) * summary.total_calories) }}kcal /
                              {{ Math.round((((summary.fatPercentage || 50) / 100) * summary.total_calories) / 9) }}g
                            </h4>
                          </div>
                          <div class="col-lg-3 col-6">
                            <strong>KHs</strong><br />
                            <h4>
                              {{ Math.round(((summary.choPercentage || 50) / 100) * summary.total_calories) }}kcal /
                              {{ Math.round((((summary.choPercentage || 50) / 100) * summary.total_calories) / 4) }}g
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4
                      class="top-spacer-50"
                      v-if="summary.bp_5 > 0 || summary.speed_bike_5 > 0 || summary.speed_run_5 > 0 || summary.hr_5 > 0"
                    >
                      Leistungskurven
                    </h4>
                    <div
                      class="styled-table light-shadow"
                      v-if="summary.bp_5 > 0 || summary.speed_bike_5 > 0 || summary.speed_run_5 > 0 || summary.hr_5 > 0"
                    >
                      <div v-if="summary.bp_5 > 0 && alltimetotals.bp && fourtyTwoDaysPower.bp">
                        <div class="chart-wrapper-less">
                          <workout-power-analysis-chart
                            class="top-spacer-25"
                            style="height: 300px"
                            text="Leistung (w)"
                            alltime_color="orange"
                            fourtyTwoDays_color="rgb(100,150,100)"
                            v-bind:alltime="alltimetotals.bp"
                            v-bind:fourtyTwoDays="fourtyTwoDaysPower.bp"
                            color="black"
                            :key="'sas' + summary.bp_60 + summary.bp_240"
                            label="w"
                            v-bind:bp="[
                              summary.bp_5,
                              summary.bp_10,
                              summary.bp_15,
                              summary.bp_30,
                              summary.bp_60,
                              summary.bp_90,
                              summary.bp_120,
                              summary.bp_180,
                              summary.bp_240,
                              summary.bp_300,
                              summary.bp_360,
                              summary.bp_420,
                              summary.bp_480,
                              summary.bp_540,
                              summary.bp_600,
                              summary.bp_720,
                              summary.bp_840,
                              summary.bp_960,
                              summary.bp_1080,
                              summary.bp_1200,
                              summary.bp_1500,
                              summary.bp_1800,
                              summary.bp_2400,
                              summary.bp_3000,
                              summary.bp_3600,
                              summary.bp_5400,
                              summary.bp_7200,
                              summary.bp_10800,
                              summary.bp_14400,
                            ]"
                          />
                        </div>
                      </div>
                      <div v-if="summary.speed_bike_5 > 0 && alltimetotals.bikespeed && fourtyTwoDaysPower.bikespeed">
                        <div class="chart-wrapper-less">
                          <workout-power-analysis-chart
                            class="top-spacer-25"
                            style="height: 300px"
                            color="black"
                            label="km/h"
                            alltime_color="orange"
                            fourtyTwoDays_color="rgb(100,150,100)"
                            v-bind:alltime="alltimetotals.bikespeed"
                            v-bind:fourtyTwoDays="fourtyTwoDaysPower.bikespeed"
                            text="Geschwindigkeit (km/h)"
                            :key="'x' + summary.speed_bike_60 + summary.speed_bike_240"
                            v-bind:bp="[
                              summary.speed_bike_5,
                              summary.speed_bike_10,
                              summary.speed_bike_15,
                              summary.speed_bike_30,
                              summary.speed_bike_60,
                              summary.speed_bike_90,
                              summary.speed_bike_120,
                              summary.speed_bike_180,
                              summary.speed_bike_240,
                              summary.speed_bike_300,
                              summary.speed_bike_360,
                              summary.speed_bike_420,
                              summary.speed_bike_480,
                              summary.speed_bike_540,
                              summary.speed_bike_600,
                              summary.speed_bike_720,
                              summary.speed_bike_840,
                              summary.speed_bike_960,
                              summary.speed_bike_1080,
                              summary.speed_bike_1200,
                              summary.speed_bike_1500,
                              summary.speed_bike_1800,
                              summary.speed_bike_2400,
                              summary.speed_bike_3000,
                              summary.speed_bike_3600,
                              summary.speed_bike_5400,
                              summary.speed_bike_7200,
                              summary.speed_bike_10800,
                              summary.speed_bike_14400,
                            ]"
                          />
                        </div>
                      </div>

                      <div v-if="summary.speed_run_5 > 0 && alltimetotals.runspeed && fourtyTwoDaysPower.runspeed">
                        <div class="chart-wrapper-less">
                          <workout-power-analysis-chart
                            class="top-spacer-25"
                            style="height: 300px"
                            color="black"
                            label="km/h"
                            text="Geschwindigkeit (km/h)"
                            alltime_color="orange"
                            fourtyTwoDays_color="rgb(100,150,100)"
                            v-bind:alltime="alltimetotals.runspeed"
                            v-bind:fourtyTwoDays="fourtyTwoDaysPower.runspeed"
                            :key="'y' + key + summary.speed_run_60 + summary.speed_run_180"
                            v-bind:bp="[
                              summary.speed_run_5,
                              summary.speed_run_10,
                              summary.speed_run_15,
                              summary.speed_run_30,
                              summary.speed_run_60,
                              summary.speed_run_90,
                              summary.speed_run_120,
                              summary.speed_run_180,
                              summary.speed_run_240,
                              summary.speed_run_300,
                              summary.speed_run_360,
                              summary.speed_run_420,
                              summary.speed_run_480,
                              summary.speed_run_540,
                              summary.speed_run_600,
                              summary.speed_run_720,
                              summary.speed_run_840,
                              summary.speed_run_960,
                              summary.speed_run_1080,
                              summary.speed_run_1200,
                              summary.speed_run_1500,
                              summary.speed_run_1800,
                              summary.speed_run_2400,
                              summary.speed_run_3000,
                              summary.speed_run_3600,
                              summary.speed_run_5400,
                              summary.speed_run_7200,
                              summary.speed_run_10800,
                              summary.speed_run_14400,
                            ]"
                          />
                        </div>
                      </div>

                      <div v-if="summary.hr_5 > 0 && alltimetotals.hr">
                        <div class="chart-wrapper-less">
                          <workout-power-analysis-chart
                            class="top-spacer-25"
                            style="height: 300px"
                            color="hsl(356, 100%, 41%)"
                            label="bpm"
                            :key="'bpm' + summary.hr_90 + summary.hr_240"
                            v-bind:alltime="alltimetotals.hr"
                            v-bind:fourtyTwoDays="fourtyTwoDaysPower.hr"
                            alltime_color="orange"
                            fourtyTwoDays_color="rgb(100,150,100)"
                            text="Herzfrequenz (bpm)"
                            v-bind:bp="[
                              summary.hr_5,
                              summary.hr_10,
                              summary.hr_15,
                              summary.hr_30,
                              summary.hr_60,
                              summary.hr_90,
                              summary.hr_120,
                              summary.hr_180,
                              summary.hr_240,
                              summary.hr_300,
                              summary.hr_360,
                              summary.hr_420,
                              summary.hr_480,
                              summary.hr_540,
                              summary.hr_600,
                              summary.hr_720,
                              summary.hr_840,
                              summary.hr_960,
                              summary.hr_1080,
                              summary.hr_1200,
                              summary.hr_1500,
                              summary.hr_1800,
                              summary.hr_2400,
                              summary.hr_3000,
                              summary.hr_3600,

                              summary.hr_5400,
                              summary.hr_7200,
                              summary.hr_10800,
                              summary.hr_14400,
                            ]"
                          />
                        </div>
                      </div>
                    </div>

                    <!--
                    <table class="styled-table">
                    <thead><tr><th>Dauer</th><th>Leistung</th><th>&nbsp;</th><th>Dauer</th><th>Leistung</th></tr></thead>
                    <tbody>
                      <tr>
                        <td>1 min</td><td>{{Math.round(bp_1min)}}</td><td></td>
                        <td v-if="bp_2min">2 min</td><td>{{Math.round(bp_2min)}}</td>
                      </tr>
                      <tr v-if="bp_5min">
                        <td>5 min</td><td>{{Math.round(bp_5min)}}</td><td></td>
                        <td v-if="bp_10min">10 min</td><td>{{Math.round(bp_10min)}}</td>
                      </tr>
                      <tr v-if="bp_20min">
                        <td>20 min</td><td>{{Math.round(bp_20min)}}</td><td></td>
                        <td  v-if="bp_30min">30 min</td><td>{{Math.round(bp_30min)}}</td>
                      </tr>
                      <tr v-if="bp_45min">
                        <td>45 min</td><td>{{Math.round(bp_45min)}}</td><td></td>
                        <td v-if="bp_60min">60 min</td><td>{{Math.round(bp_60min)}}</td>
                      </tr>
                      <tr v-if="bp_90min">
                        <td>90 min</td><td>{{Math.round(bp_90min)}}</td><td></td>
                        <td v-if="bp_120min">120 min</td><td>{{Math.round(bp_120min)}}</td>
                      </tr>
                      <tr v-if="bp_180min"><td>180 min</td><td>{{Math.round(bp_180min)}}</td></tr>
                    </tbody>
                    </table>-->
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div class="row">
                  <div v-if="summary.time_zone_1 > 0" v-bind:class="colWidthZones(summary)">
                    <h4 class="top-spacer-50">Leistung</h4>
                    <div style="border-radius: 10px; background: #fff" class="light-shadow">
                      <br />
                      <table class="styled-table styled-table-no-bottom light-shadow">
                        <thead>
                          <tr class="d-flex">
                            <th class="col-3 col-md-2">Zone</th>
                            <th class="col-3 col-md-2">Zeit</th>
                            <th class="col-6 col-md-8">Verteilung</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 1</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.time_zone_1) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.time_zone_1, summary.total_timer_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.time_zone_1,
                                    summary.total_timer_time
                                  )
                                }}% </span
                              > -->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 2</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.time_zone_2) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.time_zone_2, summary.total_timer_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.time_zone_2,
                                    summary.total_timer_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 3</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.time_zone_3) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.time_zone_3, summary.total_timer_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.time_zone_3,
                                    summary.total_timer_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 4</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.time_zone_4) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.time_zone_4, summary.total_timer_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.time_zone_4,
                                    summary.total_timer_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 5</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.time_zone_5) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.time_zone_5, summary.total_timer_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.time_zone_5,
                                    summary.total_timer_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="col-lg-12">
                        <div class="row" style="border: 1px solid #eee">
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>LIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(summary.time_zone_1 + summary.time_zone_2, summary.time_total)
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>MIT</h4>
                              <h4>{{ Math.round(timePercentage(summary.time_zone_3, summary.time_total)) }}%</h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>HIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(summary.time_zone_4 + summary.time_zone_5, summary.time_total)
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-bind:class="colWidthZones(summary)" v-if="summary.run_total_time > 0">
                    <h4 class="top-spacer-50">Geschwindigkeit</h4>
                    <div style="border-radius: 10px; background: #fff" class="light-shadow">
                      <br />
                      <table class="styled-table styled-table-no-bottom light-shadow">
                        <thead>
                          <tr class="d-flex">
                            <th class="col-3 col-md-2">Zone</th>
                            <th class="col-3 col-md-2">Zeit</th>
                            <th class="col-6 col-md-82" colspan="2" style="text-align: left">Verteilung</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 1</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.speed_run_zone_1_sum) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.speed_run_zone_1_sum, summary.run_total_time) + '%'
                                "
                              ></span>
                              <!--
                              <span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.speed_run_zone_1_sum,
                                    summary.run_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 2</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.speed_run_zone_2_sum) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.speed_run_zone_2_sum, summary.run_total_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.speed_run_zone_2_sum,
                                    summary.run_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 3</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.speed_run_zone_3_sum) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.speed_run_zone_3_sum, summary.run_total_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.speed_run_zone_3_sum,
                                    summary.run_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 4</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.speed_run_zone_4_sum) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.speed_run_zone_4_sum, summary.run_total_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.speed_run_zone_4_sum,
                                    summary.run_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 5</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.speed_run_zone_5_sum) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="
                                  'width:' + timePercentage(summary.speed_run_zone_5_sum, summary.run_total_time) + '%'
                                "
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.speed_run_zone_5_sum,
                                    summary.run_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="col-lg-12">
                        <div class="row">
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>LIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(
                                      summary.speed_run_zone_1_sum + summary.speed_run_zone_2_sum,
                                      summary.run_total_time
                                    )
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>MIT</h4>
                              <h4>
                                {{ Math.round(timePercentage(summary.speed_run_zone_3_sum, summary.run_total_time)) }}%
                              </h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>HIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(
                                      summary.speed_run_zone_4_sum + summary.speed_run_zone_5_sum,
                                      summary.run_total_time
                                    )
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-bind:class="colWidthZones(summary)" v-if="summary.avg_heart_rate > 0">
                    <h4 class="top-spacer-50">Herzfrequenz</h4>

                    <div style="border-radius: 10px; background: #fff" class="light-shadow">
                      <br />
                      <table class="styled-table styled-table-no-bottom light-shadow">
                        <thead>
                          <tr class="d-flex">
                            <th class="col-3 col-md-2">Zone</th>
                            <th class="col-3 col-md-2">Zeit</th>
                            <th class="col-36 col-md-8" style="text-align: left">Verteilung</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 1</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.hr_zone_1) }}
                            </td>
                            <td class="col-63 col-md-8">
                              {{summary.hr_total_time }} 
                              <span
                                class="pull-left bar"
                                v-bind:style="'width:' + hrPercentage(summary.hr_zone_1, summary.hr_total_time) + '%'"
                                >&nbsp;</span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.hr_zone_1,
                                    summary.hr_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 2</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.hr_zone_2) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="'width:' + hrPercentage(summary.hr_zone_2, summary.hr_total_time) + '%'"
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.hr_zone_2,
                                    summary.hr_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 3</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.hr_zone_3) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="'width:' + hrPercentage(summary.hr_zone_3, summary.hr_total_time) + '%'"
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.hr_zone_3,
                                    summary.hr_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 4</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.hr_zone_4) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="'width:' + hrPercentage(summary.hr_zone_4, summary.hr_total_time) + '%'"
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.hr_zone_4,
                                    summary.hr_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                          <tr class="d-flex">
                            <td class="col-3 col-md-2">Zone 5</td>
                            <td class="col-3 col-md-2">
                              {{ formattedTime(summary.hr_zone_5) }}
                            </td>
                            <td class="col-6 col-md-8">
                              <span
                                class="pull-left bar"
                                v-bind:style="'width:' + hrPercentage(summary.hr_zone_5, summary.hr_total_time) + '%'"
                              ></span
                              ><!--<span class="pull-right"
                                >{{
                                  timePercentage(
                                    summary.hr_zone_5,
                                    summary.hr_total_time
                                  )
                                }}%</span
                              >-->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="col-lg-12">
                        <div class="row">
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>LIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(summary.hr_zone_1 + summary.hr_zone_2, summary.hr_total_time)
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>MIT</h4>
                              <h4>{{ Math.round(timePercentage(summary.hr_zone_3, summary.hr_total_time)) }}%</h4>
                            </div>
                          </div>
                          <div class="col-4">
                            <div style="padding: 15px">
                              <h4>HIT</h4>
                              <h4>
                                {{
                                  Math.round(
                                    timePercentage(summary.hr_zone_4 + summary.hr_zone_5, summary.hr_total_time)
                                  )
                                }}%
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    details.sessions &&
                    summary &&
                    details.sessions[summary.session_index || 0].avg_power &&
                    details.sessions[summary.session_index || 0].avg_power > 0
                  "
                >
                  <h4 class="top-spacer-50">Leistungsverteilung (Watt)</h4>

                  <div class="styled-table top-spacer-15 light-shadow">
                    <div class="chart-wrapper-less">
                      <workout-watt-analysis-chart
                        class="top-spacer-25"
                        style="height: 150px"
                        v-bind:sessions="details.sessions"
                        :key="'w' + details.length"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    details.sessions &&
                    summary &&
                    details.sessions[summary.session_index || 0].avg_heart_rate &&
                    details.sessions[summary.session_index || 0].avg_heart_rate > 0
                  "
                >
                  <h4 class="top-spacer-50">Herzfrequenzverteilung (bpm)</h4>

                  <div class="styled-table light-shadow">
                    <div class="chart-wrapper-less">
                      <workout-hr-analysis-chart
                        class="top-spacer-25"
                        style="height: 150px"
                        v-bind:sessions="details.sessions"
                        :key="'hr' + details.length"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    details.sessions &&
                    details.sessions.length > 0 &&
                    details.sessions[0].laps.length > 0 &&
                    (details.sessions[0].laps.length > 1 ||
                      details.sessions[0].laps[0].total_timer_time > 0 ||
                      details.sessions[0].laps[0].total_moving_time > 0)
                  "
                >
                  <h4 class="top-spacer-50">Runden</h4>

                  <div
                    v-for="(session, sIndex) in details.sessions"
                    :key="'k' + session.total_timer_time"
                    style="overflow-x: scroll; border-radius: 10px; border: 5px solid #fff"
                  >
                    <table
                      class="styled-table light-shadow"
                      v-if="sIndex == summary.session_index || !summary.session_index"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>Zeit</th>

                          <td v-if="session.sub_sport == 'lap_swimming'">Distanz</td>

                          <td v-if="session.sub_sport == 'lap_swimming'">Stil</td>

                          <th v-if="workout.sport != 'Schwimmen'">km/h</th>
                          <th v-if="workout.sport == 'Schwimmen'">min/100m</th>
                          <th v-if="workout.sport == 'Laufen'">min/km</th>
                          <th v-if="workout.sport != 'Schwimmen'">Distanz</th>

                          <th>HF</th>
                          <th>HFmax</th>

                          <th>
                            <span v-if="workout.sport == 'Laufen'">SF</span>
                            <span v-if="workout.sport == 'Radfahren'">TF</span>
                            <span v-if="workout.sport == 'Schwimmen'">Züge</span>
                          </th>
                          <th v-if="workout.sport == 'Radfahren'">W</th>
                          <th v-if="workout.sport == 'Radfahren'">Wmax</th>
                        </tr>
                      </thead>
                      <tbody class="colored-row">
                        <tr v-for="(lap, index) in session.laps" :key="'l' + lap.timer_time + index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            {{ formattedTime(lap.total_timer_time || lap.total_moving_time || 0) }}
                          </td>

                          <td v-if="session.sub_sport == 'lap_swimming'">
                            {{ session.pool_length * lap.num_lengths }}m
                          </td>

                          <td v-if="session.sub_sport == 'lap_swimming'">
                            <span v-if="lap.total_distance == 0">Pause</span>
                            <span v-if="lap.total_distance != 0">
                              <span v-if="lap.swim_stroke == 'freestyle'">Freistil</span>
                              <span v-if="lap.swim_stroke == 'breaststroke'">Brust</span>
                              <span v-if="lap.swim_stroke == 'backstroke'">Rücken</span>
                              <span v-if="lap.swim_stroke == 'mixed'">Mix</span>

                              <!-- TODO: Andere Schwimmstyle übersetzen -->
                              <span
                                v-if="
                                  lap.swim_stroke != 'freestyle' &&
                                  lap.swim_stroke != 'mixed' &&
                                  lap.swim_stroke != 'breaststroke' &&
                                  lap.swim_stroke != 'backstroke'
                                "
                                >{{ lap.swim_stroke }}</span
                              >
                            </span>
                          </td>

                          <td v-if="workout.sport == 'Schwimmen' && (lap.avg_speed || lap.enhanced_avg_speed)">
                            {{ kmhToSwimPace(lap.total_timer_time || lap.total_moving_time, lap.total_distance) }}
                          </td>

                          <td v-if="lap.avg_speed && workout.sport != 'Schwimmen'">
                            {{ Math.round((lap.avg_speed || 0) * 10) / 10 }}
                          </td>

                          <td v-if="lap.enhanced_avg_speed && workout.sport != 'Schwimmen'">
                            {{ Math.round((lap.enhanced_avg_speed || 0) * 10) / 10 }}
                          </td>
                          <td v-if="!lap.avg_speed && !lap.enhanced_avg_speed">/</td>

                          <td v-if="workout.sport == 'Laufen' && lap.avg_speed">
                            {{ formattedMinKmTime(Math.round((60 / lap.avg_speed) * 60)) }}
                          </td>

                          <td v-if="workout.sport == 'Laufen' && lap.enhanced_avg_speed">
                            {{ formattedMinKmTime(Math.round((60 / lap.enhanced_avg_speed) * 60)) }}
                          </td>
                          <td v-if="workout.sport == 'Laufen' && !lap.avg_speed && !lap.enhanced_avg_speed">/</td>
                          <td v-if="workout.sport != 'Schwimmen' && workout.sport != 'Radfahren'">
                            {{ (Math.round((lap.total_distance || 0) * 1000) / 1000) * 1000 }}m
                          </td>
                          <td v-if="workout.sport == 'Radfahren'">
                            {{ Math.round((lap.total_distance || 0) * 10) / 10 }}km
                          </td>

                          <td>{{ Math.round(lap.avg_heart_rate || 0) }}</td>
                          <td>{{ Math.round(lap.max_heart_rate || 0) }}</td>

                          <td v-if="lap.avg_cadence">
                            <span v-if="workout.sport == 'Schwimmen'">{{ Math.round(lap.avg_cadence) }}</span>
                            <span v-if="workout.sport == 'Laufen'">{{ Math.round(lap.avg_cadence * 2) }}</span>
                            <span v-if="workout.sport == 'Radfahren'">{{ Math.round(lap.avg_cadence) }}</span>
                          </td>

                          <td v-if="!lap.avg_cadence">/</td>

                          <td v-if="workout.sport == 'Radfahren' && lap.avg_power">
                            {{ Math.round(lap.avg_power) }}
                          </td>
                          <td v-if="workout.sport == 'Radfahren' && !lap.avg_power">/</td>

                          <td v-if="workout.sport == 'Radfahren' && lap.max_power">
                            {{ Math.round(lap.max_power) }}
                          </td>
                          <td v-if="workout.sport == 'Radfahren' && !lap.max_power">/</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!--
                <h4>Instagram Story Download</h4>
                <button @click="getSocialImage()">Download</button>
-->
                <h4 class="top-spacer-50">Einheit löschen</h4>

                <div class="white_wrapper" style="padding: 15px">
                  <button
                    style="margin: 0 5px"
                    v-if="!deleteWorkoutConfirmation"
                    type="submit"
                    class="delete"
                    @click="preDeleteWorkout()"
                  >
                    Löschen
                  </button>
                  <div class="warning" v-if="deleteWorkoutConfirmation">
                    Achtung: Beim Löschen gehen alle eingegeben Daten und Auswertungen der Einheit verloren.<br /><br />

                    <button type="submit" class="delete" @click="deleteWorkout()">
                      <span v-if="deleteWorkoutConfirmation">Wirklich löschen</span>
                    </button>
                  </div>
                </div>
                <!--
              <h3 class="top-spacer-50">Weiteres</h3>

              <p>
                Manche Einheiten (z.B. lange Dauer oder viele Höhenmeter), sind
                in den Entwicklungscharts Ausreißer und verändern damit die
                Trendlinie deutlich, ohne dass sich signifikantes an der
                Leistungsentwicklung getan hat. Diese kannst du aus den
                Auswertungs-Charts ausschließen.<br /><br />
              </p>
              <div
                v-if="
                  summary && summary.exclude_in_analysis
                "
              >
                <strong>Einheit ist ausgeschlossen</strong><br />
                <button @click="toggleExcludeInAnalysis()">
                  In Entwicklungs-Charts einschließen
                </button>
              </div>
              <div
                v-if="
                  summary && !summary.exclude_in_analysis
                "
              >
                <strong>Einheit ist nicht ausgeschlossen</strong><br />
                <button @click="toggleExcludeInAnalysis()">
                  Aus Entwicklungs-Charts ausschließen
                </button>
              </div>
              -->

                <div v-if="workout && workout.source == 'pushinglimitsRadraum'">
                  <p class="top-spacer-75">
                    Du kannst die Einheit als GPX exportieren und bei anderen Plattformen wieder hochladen
                  </p>
                  <button @click="tcxExport()">
                    <img style="width: 20px" src="@/assets/images/dl_white.png" />
                    TCX</button
                  ><br />
                  (z.B. für Garmin Connect, experimentell)
                  <br /><br />
                  <button @click="gpxExport()">
                    <img style="width: 20px" src="@/assets/images/dl_white.png" />
                    GPX</button
                  ><br />
                  (Experimentell)

                  <!--<button @click="fitExport()">FIT-Export</button><br />
                  (Experimentell)   -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FatChoUtilization from "@/components/chart/FatChoUtilization.vue";
import WorkoutHrAnalysisChart from "@/components/chart/WorkoutHrAnalysisChart.vue";
import SpeedHrScatterChart from "@/components/chart/SpeedHrScatterChart.vue";

import PowerHrScatterChart from "@/components/chart/PowerHrScatterChart.vue";
import PowerHrWorkoutScatterChart from "@/components/chart/PowerHrWorkoutScatterChart.vue";
import PowerHrDistWorkoutScatterChart from "@/components/chart/PowerHrDistWorkoutScatterChart.vue";

import SpeedHrWorkoutScatterChart from "@/components/chart/SpeedHrWorkoutScatterChart.vue";
import SpeedHrDistWorkoutScatterChart from "@/components/chart/SpeedHrDistWorkoutScatterChart.vue";

import TimeBar from "@/components/auswertung/TimeBar.vue";

import WorkoutWattAnalysisChart from "@/components/chart/WorkoutWattAnalysisChart.vue";
import WorkoutPowerAnalysisChart from "@/components/chart/WorkoutPowerAnalysisChart.vue";
import WoAnalysis from "@/components/auswertung/WoAnalysis.vue";
import SwimAnalysis from "@/components/chart/SwimAnalysis.vue";

import { Capacitor } from "@capacitor/core";

//import Vue2LeafletHotline from "vue2-leaflet-hotline";

export default {
  metaInfo() {
    return {
      title: (this.workout && this.workout.name) || "Workout Details",
    };
  },
  name: "WorkoutDetails",
  components: {
    // "v-hotline": Vue2LeafletHotline,
    TimeBar,
    WoAnalysis,
    SwimAnalysis,
    FatChoUtilization,
    SpeedHrScatterChart,
    PowerHrScatterChart,
    PowerHrWorkoutScatterChart,
    SpeedHrWorkoutScatterChart,
    // WorkoutAnalysisChart,
    //WorkoutAnalysisChartHM,
    WorkoutWattAnalysisChart,
    PowerHrDistWorkoutScatterChart,
    SpeedHrDistWorkoutScatterChart,
    WorkoutHrAnalysisChart,
    WorkoutPowerAnalysisChart,
  }, //WorkoutAnalysisPowerToHr // WorkoutAnalysisChart3, WorkoutAnalysisChart2,

  data: function () {
    return {
      summary: undefined,
      reduceToSimilar: false,
      showMap: true,
      latlngs: [],
      platform: Capacitor.getPlatform(),

      smallSummaries: [],

      active_menu: undefined,
      last_timer_time: 0,
      powerDataPoints: 0,
      loading: true,
      workout: {},
      lmapLoading: true,

      pctHrMax: [],
      maxHr: 1,

      details: [],
      time_total: 0,
      time_zone_1: 0,
      time_zone_2: 0,
      time_zone_3: 0,
      time_zone_4: 0,
      time_zone_5: 0,

      hr_zone_1: 0,
      hr_zone_2: 0,
      hr_zone_3: 0,
      hr_zone_4: 0,
      hr_zone_5: 0,
      hr_total_time: 0,

      bp_1min: 0,
      bp_2min: 0,
      bp_5min: 0,
      bp_10min: 0,
      bp_20min: 0,
      bp_30min: 0,
      bp_45min: 0,
      bp_60min: 0,
      bp_90min: 0,
      bp_120min: 0,
      bp_180min: 0,

      alltimetotals: {},
      fourtyTwoDaysPower: {},

      allMapBounds: [
        [0, 0],
        [0, 0],
      ],

      hoverLatLng: [0, 0],
      clicked_point_index_1: undefined,
      clicked_point_index_2: undefined,
      clicked_point_hover: undefined,
      clicked_segment_data: {},

      nextWorkout: undefined,
      previousWorkout: undefined,

      show: true,
      key: 0,

      positionChartData: {},
      time_records: [],

      segment_time_start: "",
      segment_time_end: "",

      segment_hr: 0,
      segment_speed: 0,
      segment_power: 0,
      segment_cadence: 0,
      segmentPath: [],
      records: [],
      scatterSpeedChartLoading: true,
      scatterPowerChartLoading: true,
      thresholds: {},
      deleteWorkoutConfirmation: false,
    };
  },
  props: {
    workout_id: String,
  },
  methods: {
    preDeleteWorkout() {
      this.deleteWorkoutConfirmation = true;
    },

    kmhToSwimPace(time, distance) {
      const sec100m = Math.round((time / 60 / (distance * 1000)) * 100 * 60);
      const min = Math.floor(sec100m / 60);
      const sec = sec100m % 60;

      return min + "m" + sec;
    },

    deleteWorkout() {
      const _self = this;
      if (this.workout && this.workout._id) {
        axios
          .delete(this.$host + "/workout/" + this.workout._id)
          .then(function () {
            // Event Namen anpassen?
            _self.$emit("wasSaved");
            _self.$router.push("/training/kalender");
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        _self.$emit("wasSaved");
      }
    },

    // function applies greyscale to every pixel in canvas
    greyscale: function (context) {
      var canvas = context.canvas;
      var width = canvas.width;
      var height = canvas.height;
      var imageData = context.getImageData(0, 0, width, height);
      var data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        var r = data[i];
        var g = data[i + 1];
        var b = data[i + 2];
        // CIE luminance for the RGB
        var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        // Show white color instead of black color while loading new tiles:
        if (v === 0.0) v = 255.0;
        data[i + 0] = v; // Red
        data[i + 1] = v; // Green
        data[i + 2] = v; // Blue
        data[i + 3] = 255; // Alpha
      }
      context.putImageData(imageData, 0, 0);
    },
    async toggleExcludeInAnalysis() {
      const _self = this;
      await axios.post(this.$host + "/workout/analysis/" + _self.summary._id + "/toggleexclude", {});
      axios.get(this.$host + "/workout/" + this.workout_id + "/details/summary").then(function (res) {
        // Event Namen anpassen?
        _self.summary = res.data[0];


        _self.loading = false;
      });
    },
    scatterSpeedChartRendered() {
      this.scatterSpeedChartLoading = false;
    },
    scatterPowerChartRendered() {
      this.scatterPowerChartLoading = false;
    },
    updateChartPositionData(data) {
      if (data) {
        this.positionChartData = data;

        if (this.latlngs) {
          this.hoverLatLng = this.latlngs[this.time_records.indexOf(data.elapsed_time)];
        } else {
          this.hoverLatLng = [0, 0];
        }
        this.clicked_point_hover = [this.time_records.indexOf(data.elapsed_time)];
      }
    },

    clickChartPositionData(data) {
      this.positionChartData = data;
      const _self = this;
      _self.segmentPath = [];

      if (this.clicked_point_index_1 != undefined && this.clicked_point_index_2 != undefined) {
        // Reset, da schon zwei Punkte gewählt
        this.clicked_point_index_1 = undefined;
        this.clicked_point_index_2 = undefined;
      }

      if (this.clicked_point_index_1 == undefined) {
        this.clicked_point_index_1 = this.time_records.indexOf(data.elapsed_time);
      } else {
        this.clicked_point_index_2 = this.time_records.indexOf(data.elapsed_time);

        if (this.clicked_point_index_2 < this.clicked_point_index_1) {
          const temp = this.clicked_point_index_1;
          this.clicked_point_index_1 = this.clicked_point_index_2;
          this.clicked_point_index_2 = temp;
        }
      }

      if (this.clicked_point_index_1 && this.clicked_point_index_2) {
        const segment = this.records.slice(
          this.clicked_point_index_1,
          this.clicked_point_index_2 + 1 // +1?
        );

        this.segment_time_start = segment[0].timer_time;
        this.segment_time_end = segment[segment.length - 1].timer_time;

        this.segment_hr = Math.round(
          segment.reduce(function (a, b) {
            return (parseInt(a) || 0) + (parseInt(b.heart_rate) || 0);
          }, 0) / segment.length
        );
        this.segment_speed =
          Math.round(
            (segment.reduce(function (a, b) {
              return (parseFloat(a) || 0) + (parseFloat(b.speed) || 0);
            }, 0) /
              segment.length) *
              10
          ) / 10;
        this.segment_power = Math.round(
          segment.reduce(function (a, b) {
            return (parseInt(a) || 0) + (parseInt(b.power) || 0);
          }, 0) / segment.length
        );
        this.segment_cadence = Math.round(
          segment.reduce(function (a, b) {
            return (parseInt(a) || 0) + (parseInt(b.cadence) || 0);
          }, 0) / segment.length
        );
        segment.forEach(function (record) {
          _self.segmentPath.push([record.position_lat, record.position_long]);
        });

        let leftmost = 99;
        let topmost = -99;

        let rightmost = -99;
        let bottommost = 999;

        _self.segmentPath.forEach(function (record) {
          // WENN 200 geändert wird auch im Workout Analysis CHart ändern
          if (record[0] && record[1]) {
            if (record[0] < leftmost) {
              leftmost = record[0];
            }
            if (record[0] > rightmost) {
              rightmost = record[0];
            }

            if (record[1] > topmost) {
              topmost = record[1];
            }
            if (record[1] < bottommost) {
              bottommost = record[1];
            }
          }
        });
      } else {
        setTimeout(function () {
          _self.$refs.map.mapObject.fitBounds(_self.allMapBounds);
        }, 1200);
      }
    },
    mapLoaded() {
      this.lmapLoading = false;
      this.zoom();
    },
    zoom() {
      if (this.$refs.map && this.$refs.map) {
        this.$refs.map.mapObject.fitBounds(this.allMapBounds);
      }
    },
    colWidthZones() {
      return "col-lg-12";
    },
    smooth(array, start, end) {
      const arr = array.slice(start, end + 1);
      return arr.reduce((a, b) => (parseInt(a) || 0) + (parseInt(b.power) || 0)) / arr.length;
    },
    detectIntervals(array) {
      // TODO... funktioniert nicht. Zeit, genauere Daten etc.
      let index = 3;
      let intervals = [];
      let smoothedDataPoints = 3;

      while (array[index - smoothedDataPoints] && array[index + smoothedDataPoints]) {
        const before = this.smooth(array, index - smoothedDataPoints, index);
        const after = this.smooth(array, index, index + smoothedDataPoints);
        const detectionFactor = 0.4;

        // Intervall mit steigender Leistung
        if (before + before * detectionFactor <= after) {
          console.log("Interval detected");
          //  Intervall
          let tmpIndex = index;
          let smoothedInner = undefined;
          while (tmpIndex < array.length + 10) {
            smoothedInner = this.smooth(array, tmpIndex, tmpIndex + smoothedDataPoints);
            if (before + before * detectionFactor <= after) {
              index = tmpIndex;
              break;
            }
            tmpIndex++;
          }
          intervals.push({
            start: index,
            end: tmpIndex,
            before: before,
            after: smoothedInner,
          });
        }

        index++;
      }
      return intervals;
    },
    async fitExport() {
      axios
        .get(this.$host + "/workout/" + this.workout_id + "/fitexport", {
          responseType: "blob",
        })
        .then(function (response) {
          const blob = new Blob([response.data], { type: response.data.type });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    async gpxExport() {
      const _self = this;
      axios
        .get(this.$host + "/workout/" + this.workout_id + "/gpxexport", {
          responseType: "blob",
        })
        .then(function (response) {
          console.log(response);
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = _self.workout_id + ".gpx";
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    async tcxExport() {
      const _self = this;
      axios
        .get(this.$host + "/workout/" + this.workout_id + "/tcxexport", {
          responseType: "blob",
        })
        .then(function (response) {
          console.log(response);
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = _self.workout_id + ".tcx";
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    async load() {
      const _self = this;
      this.lmapLoading = true;

      this.loading = true;
      this.records = [];
      this.time_records = [];
      this.latlngs = [];

      this.segmentPath = [];
      this.hoverLatLng = [0, 0];
      this.smallSummaries = [];

      axios.get(this.$host + "/workout/" + this.workout_id).then(function (res) {
        // Event Namen anpassen?

        _self.workout = res.data;

        axios
          .get(
            _self.$host +
              "/workout/analysis/summary/small/from/" +
              _self.$moment(_self.workout.date).add(-365, "d") / 1000 +
              "/till/" +
              _self.$moment(_self.workout.date) / 1000
          )
          .then(function (res) {
            _self.smallSummaries = res.data;
          })
          .catch(function (err) {
            console.log("ERR", err);
          });
      });

      axios.get(this.$host + "/workout/" + this.workout_id + "/details").then(async function (res) {
        if (res) {
          // Event Namen anpassen?
          _self.latlngs = [];
          _self.details = res.data;
          _self.pctHrMax = [];

          let leftmost = 99;
          let topmost = -99;

          let rightmost = -99;
          let bottommost = 999;
          _self.time_records = [];

          if (_self.details && _self.details.sessions && _self.summary) {
            const session = _self.details.sessions[_self.summary.session_index || 0];
            const records = session.laps.flatMap((x) => x.records);
            const length = records.length;

            records.forEach(function (record, index) {
              // WENN 500 geändert wird auch im Workout Analysis CHart ändern
              if (index % Math.max(1, Math.round(length / 750)) == 0) {
                if (record.position_lat && record.position_long) {
                  if (record.position_lat < leftmost) {
                    leftmost = record.position_lat;
                  }
                  if (record.position_lat > rightmost) {
                    rightmost = record.position_lat;
                  }

                  if (record.position_long > topmost) {
                    topmost = record.position_long;
                  }
                  if (record.position_long < bottommost) {
                    bottommost = record.position_long;
                  }
                  if (record.position_lat && record.position_long) {
                    _self.latlngs.push([record.position_lat, record.position_long, record.heart_rate || 100]);
                  }
                }
                _self.time_records.push(record.elapsed_time);
                _self.records.push(record);
                _self.pctHrMax.push((record.heart_rate / _self.maxHr) * 100);
              }
            });
          }
          if (_self.$refs.map && _self.$refs.map.layer) {
            setTimeout(function () {
              _self.$refs.map.layer.fitBounds([
                [leftmost, topmost],
                [rightmost, bottommost],
              ]);
            }, 500);
          }

          _self.allMapBounds = [
            [leftmost, topmost],
            [rightmost, bottommost],
          ];
        }
      });


      axios.get(this.$host + "/workout/" + this.workout_id + "/details/summary").then(async function (res) {
        if (res) {
          // Event Namen anpassen?
          _self.summary = res.data[0];

          // fix for old workouts without hr_total_time
          if (_self.summary.hr_zone_1 && !_self.summary.hr_total_time) {
            _self.summary.hr_total_time = _self.summary.hr_zone_1 + _self.summary.hr_zone_2 + _self.summary.hr_zone_3 + _self.summary.hr_zone_4 + _self.summary.hr_zone_5;
          } 
          
          _self.loading = false;
        }
      });

      axios.get(this.$host + "/workout/previous/id/" + this.workout_id).then(function (res) {
        // Event Namen anpassen?
        _self.previousWorkout = res.data.reverse();
      });

      axios.get(this.$host + "/workout/next/id/" + this.workout_id).then(function (res) {
        if (res) {
          // Event Namen anpassen?
          _self.nextWorkout = res.data;
        }
      });

      axios
        .get(this.$host + "/workout/analysis/power/year")
        .then(function (res) {
          _self.alltimetotals.bp = [];

          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.bp.push(value);
            }
          }
          _self.key++;
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/runspeed/year")
        .then(function (res) {
          _self.alltimetotals.runspeed = [];
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key != "_id") {
                _self.alltimetotals.runspeed.push(value);
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/bikespeed/year")
        .then(function (res) {
          if (res) {
            _self.alltimetotals.bikespeed = [];
            if (res.data) {
              for (const [key, value] of Object.entries(res.data)) {
                if (key != "_id") {
                  _self.alltimetotals.bikespeed.push(value);
                }
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/hr/year")
        .then(function (res) {
          _self.alltimetotals.hr = [];
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key != "_id") {
                _self.alltimetotals.hr.push(value);
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/power/fourtytwodays")
        .then(function (res) {
          _self.fourtyTwoDaysPower.bp = [];
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key != "_id") {
                _self.fourtyTwoDaysPower.bp.push(value);
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/runspeed/fourtytwodays")
        .then(function (res) {
          _self.fourtyTwoDaysPower.runspeed = [];
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key != "_id") {
                _self.fourtyTwoDaysPower.runspeed.push(value);
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });

      axios
        .get(this.$host + "/workout/analysis/bikespeed/fourtytwodays")
        .then(function (res) {
          _self.fourtyTwoDaysPower.bikespeed = [];
          if (res.data) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key != "_id") {
                _self.fourtyTwoDaysPower.bikespeed.push(value);
                _self.key++;
              }
            }
          }
        })
        .catch(function (err) {
          console.log("ERR", err);
        });
    },
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    formattedMinKmTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("mm:ss");
    },
    timePercentage(seconds, totalTime) {
      if (totalTime > 0) {
        return Math.round((100 / totalTime) * seconds);
      } else return 0;
    },
    hrPercentage(hr, totalTime) {
      if (totalTime > 0) {
        return (100 / totalTime) * hr;
      } else return 0;
    },
    getSocialImage() {
      window.location.href = this.$host + "/workout/" + this.workout_id + "/social";
    },
  },
  watch: {
    $route(to, from) {
      this.lmapLoading = true;
      this.loading = true;
      if (to !== from) {
        this.show = false;
        this.latLngs = [0, 0, 0];
        this.segmentPath = [];
        this.clicked_point_index_1 = undefined;
        this.clicked_point_index_2 = undefined;
        this.hoverLatLng = [0, 0];
        this.details.sessions = undefined;

        this.load();

        this.show = true;
      }
    },
  },

  created: async function () {
    this.lmapLoading = true;
    this.loading = true;
    const _self = this;
    const data = await axios.get(this.$host + "/user/thresholds");

    _self.thresholds = data.data;
    _self.maxHr = _self.thresholds[_self.thresholds.length - 1].maxHr;
    await this.load();
  },
};
</script>

<style>
.bar-parent {
  display: block;
}

a {
  color: #000;
}

.chart-wrapper {
  padding: 15px 40px;
}

.chart-wrapper-less {
  padding: 15px 25px;
}

.leaflet-tile,
.leaflet-tile-pane,
.leaflet-tile-loaded {
  -webkit-filter: saturate(110%) contrast(83%) !important;
  filter: saturate(110%) contrast(85%) !important;
}
</style>
