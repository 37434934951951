<template>
  <div class="row">
    <div class="submenu col-xl-2 d-none d-xl-block">
      <div class="width-wrap sticky-top">
        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <a href="/#/esszimmer/tracker"> <div class="menu-entry active_submenu">Tracker</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/esszimmer"> <div class="menu-entry">Rezepte</div></a>
          </div>
          <div class="col-lg-12">
            <a href="/#/esszimmer/plan">
              <div class="menu-entry">Vorschläge</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-10 top-spacer-10">
      <div
        class="row top-spacer-15"
        style="
          background: #f6c050;
          padding-top: 20px;
          padding-bottom: 12px;
          letter-spacing: 0.3rem;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        "
      >
        <div class="col-lg-12">
          <img
            src="https://assets.pushinglimits.club/wtf_trans.png"
            class="pull-left d-none d-md-block"
            style="box-shadow: none !important; max-width: 170px; width: 80%; margin-top: -7px"
            alt="where is the food?"
          /><strong>TRACKER</strong> /// MACH' DIR BEWUSST, WAS DU ISST
        </div>
      </div>

      <div
        class="row white_wrapper"
        id="new_entry"
        style="padding-top: 10px; border-top-left-radius: 0; border-top-right-radius: 0"
      >
        <div class="col-lg-12 centered top-spacer-15">
          <div v-if="!loadedGeneration">
            Bitte habe etwas Geduld. Wir analysieren deine Eingabe und schätzen die Nährwerte - dieser Prozess kann
            einige Sekunden dauern. Zukünftig kannst du den Eintrag in Sekundenschnelle aus der Schnellauswahl wählen.
          </div>
          <div v-if="!loaded" style="margin: 20px" class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="col-lg-12" v-if="photo" style="border-radius: 15px; background: #f3f3f3; padding: 20px">
          <div class="row">
            <div class="col-lg-6 centered">
              <img class="upload_img" :src="photo.dataUrl" />
            </div>

            <div class="col-lg-6 centered top-spacer-15">
              <h4>Wie passt das Essen zu deinen persönlichen Zielen?</h4>
              <div class="row">
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Good')" style="background: #28a745; border: 0">Gut</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Okay')" style="background: #ff9800; border: 0">Okay</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadPicture('Bad')" style="background: #dc3545; border: 0">Schlecht</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12" v-if="!photo">
          <div class="row">
            <div class="col-lg-6 centered">
              <h4 class="top-spacer-15">1. Wann hast du gegessen?</h4>

              <input type="date" style="width: 100%" v-model="modalDate" /><br /><br />
              <h4 class="top-spacer-15">2. Was hast du gegessen?</h4>
              <div class="row">
                <div class="col-lg-5">
                  <input type="text" style="width: 100%" placeholder="Haferflocken mit Blaubeeren" v-model="txt" />
                </div>
                <div class="col-lg-2 centered"><div class="top-spacer-10">- oder -</div></div>
                <div class="col-lg-5">
                  <button style="margin: 2px; width: 100%" @click="takePicture">Bild hochladen</button>
                </div>
              </div>
              <div v-if="filteredMeals && filteredMeals.length" class="top-spacer-25">
                <strong>Schnellauswahl</strong>
              </div>
              <div v-for="entry in filteredMeals" :key="entry._id">
                <div class="top-spacer-10 pointer" @click="selectMeal(entry._id)">
                  <span :style="{ color: getRatingColor(entry.rating) }"> {{ entry.name }}</span>
                </div>
              </div>
            </div>

            <div class="col-lg-6 centered top-spacer-15">
              <h4>3. Wie passt das Essen zu deinen persönlichen Zielen?</h4>
              <div class="row">
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Good')" style="background: #28a745; border: 0">Gut</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Okay')" style="background: #ff9800; border: 0">Okay</button>
                </div>
                <div class="col-12 centered top-spacer-15">
                  <button @click="uploadText('Bad')" style="background: #dc3545; border: 0">Schlecht</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 top-spacer-75">
          <br />

          <div class="white_wrapper_tighter">
            <calorie-chart
              style="height: 250px"
              v-if="entries && entries.length"
              :entries="entries"
              :key="calorieChartRenderKey"
            />
          </div>
          <br />
          <br />
          <div class="responsive-table">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Bewertung</th>
                  <th>Bild</th>
                  <th>Name</th>
                  <th>Kalorien</th>
                  <th>Kohlenhydrate</th>
                  <th>Fett</th>
                  <th>Protein</th>
                  <th>Ballaststoffe</th>
                  <th>Portionen</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="entry in entries">
                  <tr v-if="entry.name" :key="entry._id">
                    <td data-label="Datum">
                      <strong>{{ $moment(entry.createdAt).format("DD.MM.YYYY") }}</strong>
                    </td>
                    <td data-label="Bewertung">
                      <div class="dot" :style="{ background: getRatingColor(entry.rating), borderRadius: '5px' }"></div>
                    </td>
                    <td data-label="Bild">
                      <img
                        v-if="entry.image_link"
                        @click="showEditModal(entry._id)"
                        class="lazy pic_img"
                        style="cursor: pointer; min-width: 250px"
                        :data-src="'https://foodpictures.pushinglimits.club/' + entry.image_link"
                      />
                      <div v-if="!entry.image_link">Wird verarbeitet</div>
                    </td>
                    <td data-label="Name">
                      {{ entry.name }}
                      <hr />
                      <span v-for="ingredient of entry.ingredients" :key="ingredient.name">
                        <i>{{ ingredient.name }}</i>
                        <!--({{ ingredient.amount }}g)--><br />
                      </span>
                    </td>
                    <td data-label="Kalorien">
                      <i>Portion:</i><br />{{ Math.round(entry.calories) }}kcal<br /><br />
                      <i>Gesamt:</i><br />{{ Math.round(entry.calories * entry.servings) }}kcal
                    </td>
                    <td data-label="Kohlenhydrate">
                      <i>Portion:</i><br />{{ Math.round(entry.carbs) }}g<br /><br />
                      <i>Gesamt:</i><br />{{ Math.round(entry.carbs * entry.servings) }}g
                    </td>
                    <td data-label="Fett">
                      <i>Portion:</i><br />{{ Math.round(entry.fat) }}g<br /><br />
                      <i>Gesamt:</i><br />{{ Math.round(entry.fat * entry.servings) }}g
                    </td>
                    <td data-label="Protein">
                      <i>Portion:</i><br />{{ Math.round(entry.protein) }}g<br /><br />
                      <i>Gesamt:</i><br />{{ Math.round(entry.protein * entry.servings) }}g
                    </td>
                    <td data-label="Ballaststoffe">
                      <i>Portion:</i><br />{{ Math.round(entry.fiber) }}g<br /><br />
                      <i>Gesamt:</i><br />{{ Math.round(entry.fiber * entry.servings) }}g
                    </td>
                    <td data-label="Portionen" style="font-size: 14px">
                      verzehrt:
                      <br />
                      <span @click="adjustServings(entry._id, -0.25)" style="cursor: pointer">
                        <i class="fa fa-minus" aria-hidden="true"></i> </span
                      ><br />
                      {{ entry.servings }}<br />
                      <span @click="adjustServings(entry._id, 0.25)" style="cursor: pointer">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </span>
                    </td>
                    <td data-label="Aktionen">
                      <span class="pointer" @click="showEditModal(entry._id)"> ✎ </span>
                    </td>
                  </tr>
                  <tr v-if="isShowEditModal(entry._id)" :key="entry._id + '_modal'">
                    <td colspan="10">
                      <div class="card p-3 p-md-4">
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="date">Datum:</label>
                            <input type="date" class="form-control" id="date" v-model="modalDate" />
                          </div>
                          <div class="form-group col-12 col-md-6">
                            <label for="rating">Bewertung:</label>
                            <select class="form-control" id="rating" v-model="modalRating">
                              <option value="Bad">Schlecht</option>
                              <option value="Okay">Okay</option>
                              <option value="Good">Gut</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="calories">Kalorien (Portion):</label>
                            <input type="number" class="form-control" id="calories" v-model="modalCalories" />
                          </div>
                          <div class="form-group col-12 col-md-6">
                            <label for="carbs">Kohlenhydrate (Portion):</label>
                            <input type="number" class="form-control" id="carbs" v-model="modalCarbs" />
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="fat">Fett (Portion):</label>
                            <input type="number" class="form-control" id="fat" v-model="modalFat" />
                          </div>
                          <div class="form-group col-12 col-md-6">
                            <label for="protein">Protein (Portion):</label>
                            <input type="number" class="form-control" id="protein" v-model="modalProtein" />
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="form-group col-12 col-md-6">
                            <label for="fiber">Ballaststoffe (Portion):</label>
                            <input type="number" class="form-control" id="fiber" v-model="modalFiber" />
                          </div>
                          <div class="form-group col-12 col-md-6">
                            <label for="servings">Portionen:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="servings"
                              v-model="modalServings"
                              step="0.25"
                            />
                          </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mt-3 mt-md-4">
                          <button class="btn btn-primary" @click="updatePicture(entry._id)">Speichern</button>
                          <span class="text-danger pointer" @click="deletePicture(entry._id)" style="cursor: pointer">
                            <h4><i class="fa fa-trash"></i> Eintrag löschen</h4>
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Camera, CameraResultType } from "@capacitor/camera";
import CalorieChart from "./CalorieChart.vue";

export default {
  components: {
    CalorieChart,
  },
  data() {
    return {
      entries: null,
      photo: null,
      deleteModal: null,
      title: null,
      modalDate: new Date().toISOString().slice(0, 10),
      modalRating: null,
      modalCalories: null,
      modalCarbs: null,
      modalFat: null,
      modalProtein: null,
      modalFiber: null,
      modalServings: null,
      loaded: false,
      txt: null,
      showTxt: false,
      calorieChartRenderKey: 0,
      quickModeMessage: "",
      loadedGeneration: true,
    };
  },
  mounted: async function () {
    await this.refresh();
    window.addEventListener("scroll", this.lazyLoad);
    this.lazyLoad();
  },

  computed: {
    goodMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Good").length) || 0;
    },
    okayMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Okay").length) || 0;
    },
    badMeals: function () {
      return (this.entries && this.entries.filter((entry) => entry.rating === "Bad").length) || 0;
    },

    filteredMeals: function () {
      if (!this.txt) {
        return [];
      }
      const searchWords = this.txt.toLowerCase().split(" ");
      const seenNames = new Set();

      return this.entries.filter((entry) => {
        if (entry.name && searchWords.every((word) => entry.name.toLowerCase().includes(word))) {
          if (!seenNames.has(entry.name)) {
            seenNames.add(entry.name);
            return true;
          }
        }
        return false;
      });
    },
  },

  methods: {
    adjustServings: async function (id, amount) {
      const entry = this.entries.find((entry) => entry._id === id);
      const newServings = entry.servings + amount;

      await axios.post(this.$host + "/food/update/" + id, {
        servings: newServings,
      });

      await this.refresh(false);
    },
    getRatingColor(rating) {
      switch (rating) {
        case "Bad":
          return "#dc3545";
        case "Okay":
          return "#ff9800";
        case "Good":
          return "#28a745";
        default:
          return "grey";
      }
    },

    async selectMeal(id) {
      await axios.post(this.$host + "/food/select", {
        id: id,
        createdAt: this.modalDate || new Date().toISOString(),
      });

      this.txt = "";
      this.quickModeMessage = "Eintrag hinzugefügt!";

      await this.refresh();
    },

    newTxtEntry() {
      this.photo = null;
      this.showTxt = true;
      this.quickModeMessage = "";
    },

    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        promptLabelPicture: "Foto machen",
        promptLabelPhoto: "Aus Galerie wählen",
        promptLabelCancel: "Abbrechen",
        promptLabelHeader: "Foto analysieren",
      });

      this.photo = image;
      this.showTxt = false;
    },

    async refresh(showLoaded = true) {
      const _self = this;
      if (showLoaded === true) {
        this.loaded = false;
      }
      const response365d = await axios.get(this.$host + "/food/30d");
      const entries365d = response365d.data;

      this.entries = entries365d;
      this.loaded = true;
      this.loadedGeneration = true;
      // nexttick
      this.$nextTick(() => {
        _self.lazyLoad();
        _self.calorieChartRenderKey++;
      });
    },

    async deletePicture(id) {
      const index = this.entries.findIndex((entry) => entry._id === id);

      this.photo = undefined;
      await axios.delete(this.$host + "/food/" + id);

      await this.refresh();

      // Scroll to the next or previous entry in the list, or to the "Neuen Eintrag anlegen" section if there are no entries left
      if (this.entries.length > 0) {
        if (index < this.entries.length - 1) {
          const nextEntry = this.entries[index + 1];
          const nextEntryId = nextEntry._id;
          console.log("Scroll to next entry " + nextEntryId);

          const nextEntryElement = document.getElementById(nextEntryId);

          await this.refresh();

          if (nextEntryElement) {
            nextEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else if (index > 0) {
          console.log("Scroll to previous entry");
          const prevEntry = this.entries[index - 1];
          const prevEntryId = prevEntry._id;
          const prevEntryElement = document.getElementById(prevEntryId);

          await this.refresh();

          if (prevEntryElement) {
            prevEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        } else {
          const newEntryElement = document.getElementById("new_entry");
          if (newEntryElement) {
            newEntryElement.scrollIntoView({
              block: "start",
              inline: "nearest",
            });
          }
        }
      } else {
        console.log("Scroll to new_entry");
        await this.refresh();

        const newEntryElement = document.getElementById("new_entry");
        if (newEntryElement) {
          newEntryElement.scrollIntoView({
            block: "start",
            inline: "nearest",
          });
        }
      }

      // now account for fixed header
      var scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 80);
      }

      await this.refresh();
    },

    isShowEditModal(id) {
      return this.deleteModal === id;
    },

    showEditModal(id) {
      if (this.deleteModal != id) {
        this.deleteModal = id;

        const entry = this.entries.find((entry) => entry._id === id);

        this.modalDate = this.$moment(entry.createdAt).format("YYYY-MM-DD");
        this.modalRating = entry.rating;
        this.modalCalories = entry.calories;
        this.modalCarbs = entry.carbs;
        this.modalFat = entry.fat;
        this.modalProtein = entry.protein;
        this.modalFiber = entry.fiber;
        this.modalServings = entry.servings;
      } else {
        this.deleteModal = null;
      }
    },

    async updatePicture(id) {
      const modelDateWithTime = new Date(this.modalDate).setHours(new Date().getHours(), new Date().getMinutes());

      await axios.post(this.$host + "/food/update/" + id, {
        createdAt: modelDateWithTime,
        rating: this.modalRating,
        calories: this.modalCalories,
        carbs: this.modalCarbs,
        fat: this.modalFat,
        protein: this.modalProtein,
        fiber: this.modalFiber,
        servings: this.modalServings,
      });

      await this.refresh();
      this.deleteModal = null;

      // TODO: Refresh Auswertung
    },

    async uploadText(rating) {
      const modelDateWithTime = new Date(this.modalDate).setHours(new Date().getHours(), new Date().getMinutes());

      this.loadedGeneration = false;
      this.loaded = false;
      const _self = this;
      axios
        .post(this.$host + "/food/upload/text_ai", {
          text: this.txt,
          rating: rating,
          createdAt: modelDateWithTime,
        })
        .then(async (result) => {
          console.log(">>>> " + result);
          // add result.diaryEntry to this.entries at the beginning
          _self.entries.unshift(result.data.diaryEntry);

          _self.showTxt = false;

          _self.loaded = true;

          _self.loadedGeneration = true;
        });
    },

    async uploadPicture(rating) {
      this.loadedGeneration = false;

      // Convert DataUrl to Blob
      const response = await fetch(this.photo.dataUrl);
      const blob = await response.blob();
      const _self = this;
      this.loaded = false;

      // Create FormData
      const formData = new FormData();
      formData.append("file", blob, "photo.jpg");
      formData.append("rating", rating);

      // Send a POST request to your API
      try {
        await axios
          .post(this.$host + "/food/upload_ai", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async () => {
            _self.photo = undefined;
            _self.loaded = true;
            _self.loadedGeneration = true;

            await _self.refresh();
          });
      } catch (err) {
        console.error(err);
      }
    },
    lazyLoad: function () {
      let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
      let active = false;
      const _self = this;
      if (active === false) {
        active = true;
        setTimeout(() => {
          lazyImages.forEach(function (lazyImage) {
            if (
              lazyImage.getBoundingClientRect().top <= window.innerHeight + 100 &&
              lazyImage.getBoundingClientRect().bottom >= 0 &&
              getComputedStyle(lazyImage).display !== "none"
            ) {
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.classList.remove("lazy");
              lazyImages = lazyImages.filter(function (image) {
                return image !== lazyImage;
              });

              if (lazyImages.length === 0) {
                window.removeEventListener("scroll", _self.lazyLoad);
              }
            }
          });
          active = false;
        }, 30);
      }
    },
  },
};
</script>

<style scoped>
.pic_img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 250px;
  z-index: 50;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.upload_img {
  margin: 20px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.dot {
  height: 7px;
  width: 100%;
  display: inline-block;
}
</style>
