<template>
  <div>
    <canvas ref="graph" id="graph" width="100%"></canvas>
  </div>
</template>
<script>
import { Chart } from "chart.js";

export default {
  props: ["session", "upper_limit"],

  data: function () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            pointBackgroundColor: [],
            pointBorderColor: [],
            pointRadius: [],
            trendlineLinear: {
              style: "rgba(0,0,0, .8)",
              lineStyle: "line",
              width: 2,
            },
            /*regressions: {
              sections: [{ startIndex: 0, endIndex: 99999 }],

              extendPredictions: true,
              type: "linear",
              line: { color: "black", width: 2 },
            },*/
          },
        ],
      },
    };
  },

  mounted() {
    let ctx = this.$refs.graph.getContext("2d");

    const _self = this;

    const options = {
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              var datasetLabel = context.parsed.y + "(km/h)/bpm*100";
              return datasetLabel;
            },
          },
        },
      },
      scales: {
        yAxes: {
          title: {
            display: true,
            text: "(km/h)/bpm*100",
          },
        },
        xAxes: {
          title: {
            display: true,
            text: "min",
          },
        },
      },

      maintainAspectRatio: false,
      responsive: true,
      onClick: this.clickedWorkout,
    };

    _self.chartdata.datasets[0].pointRadius = [];
    _self.chartdata.datasets[0].data = [];
    _self.chartdata.datasets[0].pointBackgroundColor = [];
    _self.chartdata.datasets[0].pointBorderColor = [];

    const records = this.session.laps.flatMap((x) => x.records);
    const numberOfRecords = records.length;
    const bucket_size = 250;
    let buckets = [];
    const records_per_bucket = Math.round(numberOfRecords / bucket_size) || 1;
    let sumSpeed = 0;
    let sumHr = 0;

    let counter = 0;
    let maxSpeed = 0;
    let previousRecord = undefined;
    records.forEach(function (record) {
      counter++;

      if (record.enhanced_speed) {
        record.speed = record.enhanced_speed || 0;
      }

      // grade adjustment
      // das sind Werte fuer Laufen - was gilt beim Radfahren?
      // erstmal fuer beides so verwenden
      const UPHILL_ADJUSTMENT_PER_PERCENT = 0.033;
      const DOWNHILL_ADJUSTMENT_PER_PERCENT = 0.017;

      record.altitude = record.altitude || record.enhanced_altitude;

      if (record.distance && previousRecord && record.altitude) {
        const distance_diff = record.distance - previousRecord.distance;
        const rise = record.altitude - previousRecord.altitude;
        if (Math.abs(distance_diff) > 0.015) {
          const slope = (rise / (distance_diff || 1)) * 100;
          let adjusted_speed = record.speed || 0;
          if (slope < 0) {
            adjusted_speed =
              adjusted_speed +
              adjusted_speed * (DOWNHILL_ADJUSTMENT_PER_PERCENT * slope);
          } else if (slope > 0) {
            adjusted_speed =
              adjusted_speed +
              adjusted_speed * (UPHILL_ADJUSTMENT_PER_PERCENT * slope);
          }
          record.speed = adjusted_speed;
        }
      }

      previousRecord = record;
      if (record.speed > 0 && record.heart_rate > 0) {
        sumSpeed += record.speed || 0;
        sumHr += record.heart_rate || 0;
      } else {
        counter--;
      }

      if (maxSpeed < record.speed) {
        maxSpeed = record.speed;
      }

      if (counter == records_per_bucket) {
        buckets.push({
          hr: sumHr / records_per_bucket,
          speed: sumSpeed / records_per_bucket,
          time: record.elapsed_time,
        });

        sumSpeed = 0;
        sumHr = 0;
        counter = 0;
      }
    });

    counter = 0;
    buckets.forEach(function (entry) {
      counter++;
      _self.chartdata.datasets[0].data.push({
        x: Math.round((entry.time / 60) * 10) / 10,
        y: Math.round((entry.speed / entry.hr) * 10 * 100) / 10,
      });

      _self.chartdata.datasets[0].pointRadius.push(3);

      _self.chartdata.datasets[0].pointBackgroundColor.push(
        _self.getColorForPercentage((entry.speed / _self.upper_limit) * 100)
      );
      _self.chartdata.datasets[0].pointBorderColor.push(
        _self.getColorForPercentage((entry.speed / _self.upper_limit) * 100)
      );
    });

    // _self.chartdata.datasets[0].regressions.sections[0].startIndex =
    //   this.chartdata.datasets[0].data[0].x;

    //this.addPlugin(Regressions.ChartRegressions);

    const config = {
      type: "scatter",
      data: _self.chartdata,
      options: options,
    };
    this.$emit("chartRendered");

    new Chart(ctx, config);
  },

  methods: {
    getColorForPercentage: function (pct) {
      pct = Math.min(100, pct);

      var a = (94 - pct) / 100,
        b = 250 * a;

      return "hsl(" + b + ", 100%, 40%)";
    },
  },
};
</script>
